export const cn = {
  '仅剩N天，使用即将到期': '仅剩{{days}}天，使用即将到期',
  'promotion.desc': '立即订购，解锁全部新功能！',
  'subscribe.button': '立即订购',
  下次提醒: '下次提醒',
  slogon: '让每次接待都形成销售转化',
  ChatPlusAI: 'ChatPlusAI',
};
export const en = {
  '仅剩N天，使用即将到期': 'Only {{days}} left, it will expire soon',
  'promotion.desc': 'Order now. Unlock all new features.',
  'subscribe.button': 'Order now',
  下次提醒: 'Remind next time',
  slogon: 'Make Every Reception A Deal',
  ChatPlusAI: 'ChatPlusAI',
};

export const id = {
  '仅剩N天，使用即将到期': 'Hanya tersisa {{days}} hari, masa pemakaian akan segera habis',
  'promotion.desc': 'Berlangganan sekarang, aktifkan semua fitur terbaru!',
  'subscribe.button': 'Order sekarang',
  下次提醒: 'Ingatkan lain kali',
  slogon: 'Jadikan setiap layanan chat menjadi transaksi penjualan',
  ChatPlusAI: 'ChatPlusAI',
};
export const th = {
  '仅剩N天，使用即将到期': 'เหลืออีกเพียง {{days}} วัน การใช้งานจะหมดอายุ',
  'promotion.desc': 'สั่งซื้อตอนนี้เพื่อปลดล็อกฟีเจอร์ใหม่ทั้งหมด!',
  'subscribe.button': 'สั่งซื้อตอนนี้',
  下次提醒: 'เตือนในครั้งต่อไป',
  slogon: 'ทำให้ทุกการต้อนรับแปลงเป็นการขาย',
  ChatPlusAI: 'ChatPlusAI',
};
export const tl = {
  '仅剩N天，使用即将到期': 'Meron na lang {{days}} na natitira, mag-expire na ito.',
  'promotion.desc': 'Mag order na ngayon. At i-unlock lahat ng new features.',
  'subscribe.button': 'Mag-order na!',
  下次提醒: 'Ipaalala sa susunod',
  slogon: ' Make Every Reception A Deal',
  ChatPlusAI: 'ChatPlusAI',
};
export const es = {
  '仅剩N天，使用即将到期': 'Sólo quedan {{days}} días, el uso caducará pronto',
  'promotion.desc': '¡Pide ahora y desbloquea todas las nuevas funciones! ',
  'subscribe.button': 'Compra ahora',
  下次提醒: 'Recuerdame más tarde',
  slogon: 'Convierta cada recepción en una conversión de ventas',
  ChatPlusAI: 'ChatPlusAI',
};

export const vn = {
  '仅剩N天，使用即将到期': 'Chỉ còn {{days}} ngày sẽ hết hạn sử dụng',
  'promotion.desc': 'Đặt hàng ngay, mở khóa toàn bộ tính năng mới!',
  'subscribe.button': 'Đặt hàng ngay',
  下次提醒: 'Nhắc lại lần sau',
  slogon: 'Để mỗi lần tiếp đón là một lần bán hàng',
  ChatPlusAI: 'ChatPlusAI',
};

export const pt = {
  '仅剩N天，使用即将到期': 'Restam apenas {{days}} dias, o uso expirará em breve',
  'promotion.desc': 'Faça seu pedido agora para desbloquear as novas funções！',
  'subscribe.button': 'Comprar agora',
  下次提醒: 'Lembrar-me da próxima vez',
  slogon: 'Transforme cada recepção em uma conversão de vendas',
  ChatPlusAI: 'ChatPlusAI',
};
