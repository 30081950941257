import {
  cnCommonApiGetErr,
  enCommonApiGetErr,
  cnCommonApiPostErr,
  enCommonApiPostErr,
  idCommonApiGetErr,
  idCommonApiPostErr,
  thCommonApiGetErr,
  thCommonApiPostErr,
  tlCommonApiGetErr,
  tlCommonApiPostErr,
} from './util';

export const cn = {
  queryConversationListErr: cnCommonApiGetErr('会话列表'),
  getTalkInfo: cnCommonApiPostErr('单个会话'),
  queryReceptionBuyerStatErr: cnCommonApiGetErr('统计数据'),
  getStoreListErr: cnCommonApiGetErr('店铺列表'),
  assistantGroupOnlineStatusErr: cnCommonApiGetErr('客服组列表'),
  assistantListOnlineStatusStatusErr: cnCommonApiGetErr('客服列表'),
  transferConversationErr: cnCommonApiPostErr('转接会话'),
  acquireConversationControlErr: cnCommonApiPostErr('获取控制权'),
  updateConversationErr: cnCommonApiPostErr('会话更新'),
  orgConfigPrioritySyncMsgGet: cnCommonApiPostErr('消息同步开关获取'),
  orgConfigPrioritySyncMsgSet: cnCommonApiPostErr('消息同步开关设置'),
  searchMsg: cnCommonApiPostErr('搜索'),
  searchStoreBuyerNotExist: '订单号不存在',
  searchStoreBuyer: cnCommonApiPostErr('订单号搜索查询'),
  searchProduct: cnCommonApiPostErr('商品搜索查询'),
  'common.action.request': '请求',
  'common.action.save': '保存',
  'common.error.messages': '{{action}}失败，请稍后再试。',
  'common.success.messages': '{{action}}成功！',
  apiErrorWithCode: (api: string, msg: string) => `失败: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('失效帐号列表'),
};
export const en = {
  queryConversationListErr: enCommonApiGetErr('conversation list'),
  getTalkInfo: enCommonApiPostErr('single conversation'),
  queryReceptionBuyerStatErr: enCommonApiGetErr('buyer reception data'),
  getStoreListErr: enCommonApiGetErr('store list'),
  assistantGroupOnlineStatusErr: enCommonApiGetErr('assistant group list'),
  assistantListOnlineStatusStatusErr: enCommonApiGetErr('assistant list'),
  transferConversationErr: enCommonApiPostErr('Transfer conversation'),
  acquireConversationControlErr: cnCommonApiPostErr('Get Control'),
  updateConversationErr: enCommonApiPostErr('Conversation update'),
  orgConfigPrioritySyncMsgGet: enCommonApiPostErr('get mssages synchronization switch'),
  orgConfigPrioritySyncMsgSet: enCommonApiPostErr('set mssages synchronization switch'),
  searchMsg: enCommonApiPostErr('search'),
  searchStoreBuyerNotExist: 'order not found',
  searchStoreBuyer: enCommonApiPostErr('order number search query'),
  searchProduct: enCommonApiPostErr('prodcut search query'),
  'common.action.request': 'Query',
  'common.action.save': 'Save',
  'common.error.messages': 'Failed to {{action}}, please try again later.',
  'common.success.messages': '{{action}} success!',
  apiErrorWithCode: (api: string, msg: string) => `Failed: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('invalid account list'),
};
export const id = {
  queryConversationListErr: idCommonApiGetErr('List percakapan'),
  getTalkInfo: idCommonApiPostErr('Satu percakapan'),
  queryReceptionBuyerStatErr: idCommonApiGetErr('Data statistik'),
  getStoreListErr: idCommonApiGetErr('List toko'),
  assistantGroupOnlineStatusErr: idCommonApiGetErr('Daftar Grup Asisten'),
  assistantListOnlineStatusStatusErr: idCommonApiGetErr('Daftar Asisten'),
  transferConversationErr: idCommonApiPostErr('Teruskan percakapan'),
  acquireConversationControlErr: cnCommonApiPostErr('dapatkanKontrol'),
  updateConversationErr: idCommonApiPostErr('Perbarui percakapan'),
  orgConfigPrioritySyncMsgGet: idCommonApiPostErr('Dapatkan tombol sinkronisasi pesan'),
  orgConfigPrioritySyncMsgSet: idCommonApiPostErr('Pengaturan tombol sinkronisasi pesan'),
  searchMsg: idCommonApiPostErr('Cari'),
  searchStoreBuyerNotExist: 'Nomor pesanan tidak ada',
  searchStoreBuyer: idCommonApiPostErr('Pencarian nomor pesanan'),
  searchProduct: idCommonApiPostErr('Pencarian produk'),
  'common.action.request': 'Request',
  'common.action.save': 'Simpan',
  'common.error.messages': '{{action}}Gagal, silakan mencoba beberapa saat lagi.',
  'common.success.messages': '{{action}}Berhasil!',
  apiErrorWithCode: (api: string, msg: string) => `Failed: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('daftar akun tidak valid'),
};
export const th = {
  queryConversationListErr: thCommonApiGetErr('รายการสนทนา'),
  getTalkInfo: thCommonApiPostErr('การสนทนาเดี่ยว'),
  queryReceptionBuyerStatErr: thCommonApiGetErr('สถิติข้อมูล'),
  getStoreListErr: thCommonApiGetErr('รายชื่อร้านค้า'),
  assistantGroupOnlineStatusErr: thCommonApiGetErr('รายชื่อทีมงานฝ่ายบริการลูกค้า'),
  assistantListOnlineStatusStatusErr: thCommonApiGetErr('รายชื่อเจ้าหน้าที่บริการลูกค้า'),
  transferConversationErr: thCommonApiPostErr('ส่งต่อการสนทนา'),
  acquireConversationControlErr: cnCommonApiPostErr('รับการควบคุม'),
  updateConversationErr: thCommonApiPostErr('อัปเดตการสนทนา'),
  orgConfigPrioritySyncMsgGet: thCommonApiPostErr('รับปุ่มซิงค์ข้อความ'),
  orgConfigPrioritySyncMsgSet: thCommonApiPostErr('ตั้งค่าปุ่มซิงค์ข้อความ'),
  searchMsg: thCommonApiPostErr('ค้นหา'),
  searchStoreBuyerNotExist: 'ไม่มีหมายเลขคำสั่งซื้อนี้',
  searchStoreBuyer: thCommonApiPostErr('ค้นหาโดยหมายเลขคำสั่งซื้อ'),
  searchProduct: thCommonApiPostErr('ค้นหาสินค้า'),
  'common.action.request': 'คำขอ',
  'common.action.save': 'บันทึก',
  'common.error.messages': 'ล้มเหลว โปรดลองอีกครั้งในภายหลัง',
  'common.success.messages': '{{action}} สำเร็จ',
  apiErrorWithCode: (api: string, msg: string) => `Failed: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('รายการบัญชีที่ไม่ถูกต้อง'),
};

export const tl = {
  queryConversationListErr: tlCommonApiGetErr('conversation list'),
  getTalkInfo: tlCommonApiPostErr('single conversation'),
  queryReceptionBuyerStatErr: tlCommonApiGetErr('buyer reception data'),
  getStoreListErr: tlCommonApiGetErr('store list'),
  assistantGroupOnlineStatusErr: tlCommonApiGetErr('assistant group list'),
  assistantListOnlineStatusStatusErr: tlCommonApiGetErr('assistant list'),
  transferConversationErr: tlCommonApiPostErr('Transfer conversation'),
  acquireConversationControlErr: cnCommonApiPostErr('kumuha ng Control'),
  updateConversationErr: tlCommonApiPostErr('Conversation update'),
  orgConfigPrioritySyncMsgGet: tlCommonApiPostErr('get mssages synchronization switch'),
  orgConfigPrioritySyncMsgSet: tlCommonApiPostErr('set mssages synchronization switch'),
  searchMsg: tlCommonApiPostErr('search'),
  searchStoreBuyerNotExist: 'order not found',
  searchStoreBuyer: tlCommonApiPostErr('order number search query'),
  searchProduct: tlCommonApiPostErr('prodcut search query'),
  'common.action.request': 'Query',
  'common.action.save': 'Save',
  'common.error.messages': 'Failed to {{action}}, please try again later.',
  'common.success.messages': '{{action}} success!',
  apiErrorWithCode: (api: string, msg: string) => `Failed: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('di-wastong listahan ng account'),
};

export const es = {
  queryConversationListErr: cnCommonApiGetErr('lista de conversaciones'),
  getTalkInfo: cnCommonApiPostErr('conversación única'),
  queryReceptionBuyerStatErr: cnCommonApiGetErr('datos de recepción del comprador'),
  getStoreListErr: cnCommonApiGetErr('lista de tiendas'),
  assistantGroupOnlineStatusErr: cnCommonApiGetErr('lista de grupos de asistentes'),
  assistantListOnlineStatusStatusErr: cnCommonApiGetErr('lista de asistentes'),
  transferConversationErr: cnCommonApiPostErr('compartir conversación'),
  acquireConversationControlErr: cnCommonApiPostErr('Obtener control'),
  updateConversationErr: cnCommonApiPostErr('actualización de la conversación'),
  orgConfigPrioritySyncMsgGet: cnCommonApiPostErr('obtener cambio de sincronización de mssages'),
  orgConfigPrioritySyncMsgSet: cnCommonApiPostErr(
    'adquirir el interruptor de sincronización de mensajes',
  ),
  searchMsg: cnCommonApiPostErr('buscar'),
  searchStoreBuyerNotExist: 'el número de pedido no existe',
  searchStoreBuyer: cnCommonApiPostErr('consulta de búsqueda del número de pedido'),
  searchProduct: cnCommonApiPostErr('consulta de búsqueda de productos'),
  'common.action.request': 'solicitar',
  'common.action.save': 'guardar',
  'common.error.messages': '{{action}} falló, por favor inténtelo más tarde.',
  'common.success.messages': '{{action}} con éxito!',
  apiErrorWithCode: (api: string, msg: string) => `fracasó: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('lista de cuentas no válidas'),
};

export const vn = {
  queryConversationListErr: cnCommonApiGetErr('Danh sách trò chuyện'),
  getTalkInfo: cnCommonApiPostErr('Một cuộc trò chuyện'),
  queryReceptionBuyerStatErr: cnCommonApiGetErr('Số liệu thống kê'),
  getStoreListErr: cnCommonApiGetErr('Danh sách cửa hàng'),
  assistantGroupOnlineStatusErr: cnCommonApiGetErr('Danh sách nhóm chăm sóc khách hàng'),
  assistantListOnlineStatusStatusErr: cnCommonApiGetErr('Danh sách chăm sóc khách hàng'),
  transferConversationErr: cnCommonApiPostErr('Chuyển tiếp cuộc trò chuyện'),
  acquireConversationControlErr: cnCommonApiPostErr('Xin quyền kiểm soát'),
  updateConversationErr: cnCommonApiPostErr('Làm mới trò chuyện'),
  orgConfigPrioritySyncMsgGet: cnCommonApiPostErr('Nhận quyền đồng bộ tin nhắn'),
  orgConfigPrioritySyncMsgSet: cnCommonApiPostErr('Cài đặt đồng bộ tin nhắn'),
  searchMsg: cnCommonApiPostErr('Tìm kiếm'),
  searchStoreBuyerNotExist: 'Mã đơn hàng không tồn tại',
  searchStoreBuyer: cnCommonApiPostErr('Tìm kiếm mã đơn hàng'),
  searchProduct: cnCommonApiPostErr('Tìm kiếm sản phẩm'),
  'common.action.request': 'Yêu cầu',
  'common.action.save': 'Lưu',
  'common.error.messages': '{{action}} thất bại, vui lòng thử lại sau.',
  'common.success.messages': '{{action}} thành công!',
  apiErrorWithCode: (api: string, msg: string) => `Thất bại: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('Danh sách tài khoản không hợp lệ'),
};

export const pt = {
  queryConversationListErr: cnCommonApiGetErr('Lista de conversas'),
  getTalkInfo: cnCommonApiPostErr('Conversa ]unica'),
  queryReceptionBuyerStatErr: cnCommonApiGetErr('Dados estatísticos'),
  getStoreListErr: cnCommonApiGetErr('Lista de lojas'),
  assistantGroupOnlineStatusErr: cnCommonApiGetErr('Lista de grupos de assistentes'),
  assistantListOnlineStatusStatusErr: cnCommonApiGetErr('Lista de assistentes'),
  transferConversationErr: cnCommonApiPostErr('Encaminhar a conversa'),
  acquireConversationControlErr: cnCommonApiPostErr('Obter o controlo'),
  updateConversationErr: cnCommonApiPostErr('Atualização da conversa'),
  orgConfigPrioritySyncMsgGet: cnCommonApiPostErr('Obter alteração de sincronização de mensagens'),
  orgConfigPrioritySyncMsgSet: cnCommonApiPostErr(
    'Definir interruptor de sincronização de mensagens',
  ),
  searchMsg: cnCommonApiPostErr('Buscar'),
  searchStoreBuyerNotExist: 'número do pedido não existe',
  searchStoreBuyer: cnCommonApiPostErr('Consultar a busca do número do pedido'),
  searchProduct: cnCommonApiPostErr('Consultar a busca de produtos'),
  'common.action.request': 'Solicitar',
  'common.action.save': 'Guardar',
  'common.error.messages': '{{action}}falhou, tente novamente mais tarde.',
  'common.success.messages': '{{action}}Com êxito！',
  apiErrorWithCode: (api: string, msg: string) => `Falhou: ${api}: ${msg}`,
  invalidAccount: cnCommonApiPostErr('Lista de contas inválidas'),
};
