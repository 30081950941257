const anyOther = {
  常用表情: {
    'zh-CN': '常用表情',
    'en-US': 'Recent',
    es: 'Recent',
    id: 'Recent',
    pt: 'Recent',
    tl: 'Recent',
    vi: 'Recent',
    th: 'Recent',
  },
  回复: {
    'zh-CN': '回复',
    'en-US': 'Reply',
    es: 'Reply',
    id: 'Reply',
    pt: 'Reply',
    tl: 'Reply',
    vi: 'Reply',
    th: 'Reply',
  },
  暂时无法访问: {
    'zh-CN': '暂时无法访问',
    'en-US': 'Temporarily unavailable',
    es: 'Temporarily unavailable',
    id: 'Tidak dapat diakses untuk sementara waktu',
    pt: 'Temporarily unavailable',
    tl: 'Temporarily unavailable',
    vi: 'Tạm thời không thể truy cập',
    th: 'Temporarily unavailable',
  },
  当前IP无法访问platform: {
    'zh-CN': '当前IP无法访问{{platform}}',
    'en-US': 'The current IP cannot access {{platform}}',
    es: 'The current IP cannot access {{platform}}',
    id: 'IP ini tidak dapat mengakses {{platform}}',
    pt: 'The current IP cannot access {{platform}}',
    tl: 'The current IP cannot access {{platform}}',
    vi: 'IP hiện tại không thể truy cập {{platform}}',
    th: 'The current IP cannot access {{platform}}',
  },
  暂无选择店铺: {
    'zh-CN': '暂无选择店铺',
    'en-US': 'No store selected',
    es: 'No store selected',
    id: 'Tidak ada toko yang dipilih saat ini',
    pt: 'No store selected',
    tl: 'No store selected',
    vi: 'Chưa có cửa hàng nào được chọn',
    th: 'No store selected',
  },
  旗舰店: {
    'zh-CN': '旗舰店',
    'en-US': 'Official Store',
    es: 'Official Store',
    id: 'Official Store',
    pt: 'Official Store',
    tl: 'Official Store',
    vi: 'Official Store',
    th: 'Official Store',
  },
  会员店: {
    'zh-CN': '会员店',
    'en-US': 'Power Merchant',
    es: 'Power Merchant',
    id: 'Power Merchant',
    pt: 'Power Merchant',
    tl: 'Power Merchant',
    vi: 'Power Merchant',
    th: 'Power Merchant',
  },
  商店域名: {
    'zh-CN': '商店域名',
    'en-US': 'Domain',
    es: 'Domain',
    id: 'Domain',
    pt: 'Domain',
    tl: 'Domain',
    vi: 'Domain',
    th: 'Domain',
  },
};

export default anyOther;
