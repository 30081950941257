export const cn = {
  tw: '台湾虾皮',
  my: '马来西亚',
  id: '印度尼西亚',
  th: '泰国',
  ph: '菲律宾',
  sg: '新加坡',
  vn: '越南',
  br: '巴西',
  cl: '智利',
  co: '哥伦比亚',
  mx: '墨西哥',
  pl: '波兰',
  es: '西班牙',
  fr: '法国',
  gb: '全球',
};
export const en = {
  tw: 'Taiwan,China',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Philippines',
  sg: 'Singapore',
  vn: 'Vietnam',
  br: 'Brazil',
  cl: 'Chile',
  co: 'Colombia',
  mx: 'Mexico',
  pl: 'Poland',
  es: 'Spain',
  fr: 'France',
  gb: 'Global',
};
export const id = {
  tw: 'Taiwan,China',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Filipina',
  sg: 'Singapura',
  vn: 'Vietnam',
  br: 'Brazil',
  cl: 'Chili',
  co: 'Kolumbia',
  mx: 'Meksiko',
  pl: 'Polandia',
  es: 'Spanyol',
  fr: 'Perancis',
  gb: 'Global',
};
export const th = {
  tw: 'ไต้หวัน จีน',
  my: 'มาเลเซีย',
  id: 'อินโดนีเซีย',
  th: 'ไทย',
  ph: 'ฟิลิปปินส์',
  sg: 'สิงคโปร์',
  vn: 'เวียดนาม',
  br: 'บราซิล',
  cl: 'ชิลี',
  co: 'โคลอมเบีย',
  mx: 'เม็กซิโก',
  pl: 'โปแลนด์',
  es: 'สเปน',
  fr: 'ฝรั่งเศส',
  gb: 'ทั่วโลก',
};

export const tl = {
  tw: 'Taiwan,China',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Philippines',
  sg: 'Singapore',
  vn: 'Vietnam',
  br: 'Brazil',
  cl: 'Chile',
  co: 'Colombia',
  mx: 'Mexico',
  pl: 'Poland',
  es: 'Spain',
  fr: 'France',
  gb: 'Global',
};

export const es = {
  tw: 'Taiwán',
  my: 'Malasia',
  id: 'Indonesia',
  th: 'Tailandia',
  ph: 'Filipinas',
  sg: 'Singapur',
  vn: 'Vietnam',
  br: 'Brasil',
  cl: 'Chile',
  co: 'Colombia',
  mx: 'México',
  pl: 'Polonia',
  es: 'España',
  fr: 'Francia',
  gb: 'Global',
};

export const vn = {
  tw: 'Đài Loan',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thái Lan',
  ph: 'Philippines',
  sg: 'Singapore',
  vn: 'Việt Nam',
  br: 'Brazil',
  cl: 'Chile',
  co: 'Colombia',
  mx: 'Mexico',
  pl: 'Ba Lan',
  es: 'Tây Ban Nha',
  fr: 'Pháp',
  gb: 'Global',
};

export const pt = {
  tw: 'Taiwan',
  my: 'Malásia',
  id: 'Indonésia',
  th: 'Tailândia',
  ph: 'Filipinas',
  sg: 'Singapura',
  vn: 'Vietnã',
  br: 'Brasil',
  cl: 'Chile',
  co: 'Colômbia',
  mx: 'México',
  pl: 'Polónia',
  es: 'Espanha',
  fr: 'França',
  gb: 'Global',
};
