const otherAppI18n = {
  微信: {
    'zh-CN': '微信',
    'en-US': 'Wechat',
    es: 'Wechat',
    id: 'Wechat',
    pt: 'Wechat',
    tl: 'Wechat',
    vi: 'Wechat',
    th: 'Wechat',
  },
  airwallex: {
    'zh-CN': 'Airwallex',
    'en-US': 'Airwallex',
    es: 'Airwallex',
    id: 'Airwallex',
    pt: 'Airwallex',
    tl: 'Airwallex',
    vi: 'Airwallex',
    th: 'Airwallex',
  },
  支付宝: {
    'zh-CN': '支付宝',
    'en-US': 'Alipay',
    es: 'Alipay',
    id: 'Alipay',
    pt: 'Alipay',
    tl: 'Alipay',
    vi: 'Alipay',
    th: 'Alipay',
  },
};

export default otherAppI18n;
