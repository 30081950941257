export const cn = {
  设置成功: '设置成功',
};
export const en = {
  设置成功: 'Set successfully',
};
export const id = {
  设置成功: 'Berhasil diatur',
};
export const th = {
  设置成功: 'ตั้งค่าสำเร็จ',
};
export const tl = {
  设置成功: 'Set successfully',
};
export const es = {
  设置成功: 'Establecido con éxito',
};

export const vn = {
  设置成功: 'Cài đặt thành công',
};

export const pt = {
  设置成功: 'Configurou com sucesso',
};