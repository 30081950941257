const StartupNewText = {
  cn: {
    'startup.container.slider1.title': 'Shopee、Lazada\n多店智能客服管理软件',
    'startup.container.slider1.aetitle': '全球电商AI\n智能客服专家',
    'startup.container.slider2.title': '智能跟单\n守护您的每笔订单',

    'startup.container.slider2.points.tittle.基础客服接待': '基础客服接待',

    'startup.container.slider2.points.tittle.AI智能接待': 'AI智能接待',

    'startup.container.slider2.points.tittle.智能催下单': '智能催下单',

    'startup.container.slider2.points.tittle.智能催付款': '智能催付款',

    'startup.container.slider2.points.tittle.智能老客召回': '智能老客召回',

    'startup.container.slider2.points.tittle.智能评价管理': '智能评价管理',

    'startup.container.slider2.points.tittle.智能退单挽回': '智能退单挽回',

    'startup.container.slider2.points.tittle.智能物流呵护': '智能物流呵护',
  },
  en: {
    'startup.container.slider1.title':
      'Multi-store Intelligent Customer Service Software For Shopee, Lazada',
    'startup.container.slider1.aetitle':
      'Global e-commerce AI\nIntelligent customer service expert',
    'startup.container.slider2.title': 'Automation Helps Every Order Fulfillment',

    'startup.container.slider2.points.tittle.基础客服接待': 'Basic Reception',

    'startup.container.slider2.points.tittle.AI智能接待': 'AI Receptionist Reception',

    'startup.container.slider2.points.tittle.智能催下单': 'Order Reminder',

    'startup.container.slider2.points.tittle.智能催付款': 'Payment Reminder',

    'startup.container.slider2.points.tittle.智能老客召回': 'Regular Customer Recall',

    'startup.container.slider2.points.tittle.智能评价管理': 'Review Booster',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Chargeback Recovery',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Logistics Care',
  },
  id: {
    'startup.container.slider1.title':
      'App Manajemen Multi-toko Customer Service Cerdas Shopee & Lazada',
    'startup.container.slider1.aetitle': 'Global e-commerce AI\nSpesialis Customer Service Cerdas',
    'startup.container.slider2.title': 'Automation mengawal pesanan Anda',

    'startup.container.slider2.points.tittle.基础客服接待': 'Layanan dasar',

    'startup.container.slider2.points.tittle.AI智能接待': 'Layanan cerdas AI',

    'startup.container.slider2.points.tittle.智能催下单': 'Pengingat checkout',

    'startup.container.slider2.points.tittle.智能催付款': 'Pengingat pembayaran',

    'startup.container.slider2.points.tittle.智能老客召回': 'Recall customer lama',

    'startup.container.slider2.points.tittle.智能评价管理': 'Manajemen penilaian',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Chargeback Recovery',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Logistics care',
  },
  th: {
    'startup.container.slider1.title':
      'ซอฟต์แวร์บริการลูกค้าอัจฉริยะแบบหลายร้านค้าสำหรับ Shopee, Lazada',
    'startup.container.slider1.aetitle':
      'AI ผู้ประกอบธุรกิจไฟฟ้าทั่วโลก\nผู้เชี่ยวชาญด้านการบริการลูกค้าอัจฉริยะ',
    'startup.container.slider2.title': 'Automation ปกป้องทุกคำสั่งซื้อของคุณ',

    'startup.container.slider2.points.tittle.基础客服接待': 'การต้อนรับพื้นฐาน',

    'startup.container.slider2.points.tittle.AI智能接待': 'AI สมาร์ทเซอร์วิส',

    'startup.container.slider2.points.tittle.智能催下单': 'การเร่งสั่งซื้ออัจฉริยะ',

    'startup.container.slider2.points.tittle.智能催付款': 'การเร่งจ่ายเงินอัจฉริยะ',

    'startup.container.slider2.points.tittle.智能老客召回': 'การเรียกคืนลูกค้าประจำอัจฉริยะ',

    'startup.container.slider2.points.tittle.智能评价管理': 'การจัดการรีวิวอัจฉริยะ',

    'startup.container.slider2.points.tittle.智能退单挽回': 'ยับยั้งการยกเลิก/คืนอัจฉริยะ',

    'startup.container.slider2.points.tittle.智能物流呵护': 'การดูแลด้านลอจิสติกส์อัจฉริยะ',
  },
  tl: {
    'startup.container.slider1.title':
      'Multi-store Intelligent Customer Service Software For Shopee, Lazada',
    'startup.container.slider1.aetitle':
      'Global e-commerce AI\nIntelligent customer service expert',
    'startup.container.slider2.title': 'Automation Helps Every Order Fulfillment',

    'startup.container.slider2.points.tittle.基础客服接待': 'Basic Reception',

    'startup.container.slider2.points.tittle.AI智能接待': 'AI Receptionist',

    'startup.container.slider2.points.tittle.智能催下单': 'Order Reminder',

    'startup.container.slider2.points.tittle.智能催付款': 'Payment Reminder',

    'startup.container.slider2.points.tittle.智能老客召回': 'Regular Customer Recall',

    'startup.container.slider2.points.tittle.智能评价管理': 'Review Booster',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Chargeback Recovery',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Logistics Care',
  },
  vn: {
    'startup.container.slider1.title':
      'Phần mềm quản lý dịch vụ khách hàng thông minh Shopee, Lazada',
    'startup.container.slider1.aetitle':
      'AI thương mại điện tử toàn cầu\nChuyên gia CSKH thông minh',
    'startup.container.slider2.title': 'Tự động theo dõi đơn, bảo vệ từng đơn hàng của bạn',

    'startup.container.slider2.points.tittle.基础客服接待': 'CSKH cơ bản',

    'startup.container.slider2.points.tittle.AI智能接待': 'CSKH AI',

    'startup.container.slider2.points.tittle.智能催下单': 'Giục đặt hàng tự động',

    'startup.container.slider2.points.tittle.智能催付款': 'Giục thanh toán tự động',

    'startup.container.slider2.points.tittle.智能老客召回': 'Tự động thu hút khách quen',

    'startup.container.slider2.points.tittle.智能评价管理': 'Quản lý đánh giá tự động',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Cứu vãn hoàn đơn tự động',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Theo dõi vận chuyển tự động',
  },
  es: {
    'startup.container.slider1.title':
      'Software inteligente de gestión del servicio al cliente para Shopee, Lazada y demás tiendas.',
    'startup.container.slider1.aetitle':
      'Global e-commerce AI\nIntelligent customer service expert',
    'startup.container.slider2.title': 'Automation ayuda a cumplir cada uno de los pedidos',

    'startup.container.slider2.points.tittle.基础客服接待': 'Recepción básica',

    'startup.container.slider2.points.tittle.AI智能接待': 'Recepción Inteligente AI',

    'startup.container.slider2.points.tittle.智能催下单': 'Recordatorio de pedidos',

    'startup.container.slider2.points.tittle.智能催付款': 'Recordatorio de pagos',

    'startup.container.slider2.points.tittle.智能老客召回': 'Recuperación de clientes habituales',

    'startup.container.slider2.points.tittle.智能评价管理': 'Administración de reseñas',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Recuperación de devoluciones',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Cuidado logístico',
  },
  pt: {
    'startup.container.slider1.title':
      'Software inteligente de gerenciamento de atendimento ao cliente para Shopee, Lazada e outras lojas.',
    'startup.container.slider1.aetitle':
      'Global e-commerce AI\nIntelligent customer service expert',
    'startup.container.slider2.title': 'Automation ajuda a cumprir todos os pedidos',

    'startup.container.slider2.points.tittle.基础客服接待': 'Recepção Básica',

    'startup.container.slider2.points.tittle.AI智能接待': 'Recepção Inteligente AI',

    'startup.container.slider2.points.tittle.智能催下单': 'Lembrete de Pedido',

    'startup.container.slider2.points.tittle.智能催付款': 'Lembrete de Pagamento',

    'startup.container.slider2.points.tittle.智能老客召回': 'Atrair clientes regulares',

    'startup.container.slider2.points.tittle.智能评价管理': 'Gerenciamento de Avaliações',

    'startup.container.slider2.points.tittle.智能退单挽回': 'Recuperação do chargeback',

    'startup.container.slider2.points.tittle.智能物流呵护': 'Cuidados Logísticos',
  },
};

export const cn = {
  ...StartupNewText.cn,
  'download.client.tips': '已有帐号，下载ChatPlusAI ChatPlusAI 客户端',
  'reg.tips': '请先进行注册',
  'btn.for.reg': '去注册帐号',
  'btn.for.windows': 'Windows 版本下载',
  'btn.for.mac': 'macOS 版本下载',
  'has.download.tips': '我已下载客户端，',
  'has.download.btn': '打开客户端',
  required: '必填',
  'login.title': '欢迎登录ChatPlusAI',
  'login.title1': '帐号密码登录',
  'login.form': '登录',
  'login.form.typeAccountOrEmail': '帐号/邮箱',
  'login.form.typeMobile': '手机号',
  'login.form.findPassword': '忘记密码？',
  'login.form.go.register': '注册帐号',
  'login.form.submit': '登录',
  'login.form.item.account.label': '帐号',
  'login.form.item.accountEmail.placeholder': '请输入帐号/邮箱',
  'login.form.item.mobile.placeholder': '请输入手机号码',
  'login.form.item.password.label': '密码',
  'login.form.item.password.placeholder': '请输入密码',
  'login.form.item.remember.label': '记住密码',
  'login.form.platform.account.placeholder': '请输入平台帐号',
  'login.form.platform.radio.erp': '马六甲 ERP 帐号',
  'login.form.platform.radio.chat': 'ChatPlusAI客服帐号',
  'login.form.platform.account.erp': '请输入马六甲 ERP 主帐号',
  'login.form.platform.account.chat': '请输入ChatPlusAI客服帐号',
  'login.form.platform.error': '帐号或密码错误，客服子帐号请选择ChatPlusAI客服帐号登陆。',
  'login.form.success': '登录成功',
  'registry.form.item.account.label': '手机号码',
  'registry.form.item.account.placeholder': '请输入手机号码',
  'registry.form.item.account.validator.error': '请输入有效的手机号码',
  'registry.form.item.password.label': '密码',
  'registry.form.item.find.password.label': '设置密码',
  'registry.form.item.confirm.password.label': '确认密码',
  'registry.form.item.password.placeholder': '请输入密码',
  'registry.form.item.password.format.tip':
    '密码最少8位字符,须包含1位大/小写字母、1位数字、1位特殊字符(如*、.等)',
  'registry.form.item.company.format.tip':
    '若您为个体户，可自定义输入，作为您在ChatPlusAI的身份主体标识',
  'registry.form.item.company.admin.tip': '自定义输入帐号名称，作为后续登录ChatPlusAI的用户名',
  'registry.form.item.password.validator.error.required':
    '请输入8-20位密码,密码中须包含1位大/小写字母、1位数字、1位特殊字符(如*、.等)',
  'registry.form.item.password.validator.error.length': '请输入8-20位密码',
  'registry.form.item.password.validator.error.invalid.charaters':
    '密码不能包含数字、字母以外的字符',
  'registry.form.item.password.validator.error.no.number': '密码中至少包含一位数字',
  'registry.form.item.password.validator.error.no.alphabet': '密码中至少包含一位大/小写字母',
  'registry.form.item.password.validator.error.no.special.characters':
    '密码中至少包含一位特殊字符(如*、.等)',
  'registry.form.item.password.validator.error.include.username': '密码中不可包含用户名',
  'startups.form.item.captcha.label': '验证码',
  'startups.form.item.captcha.send': '获取验证码',
  'startups.form.item.captcha.send.again': '再次发送',
  'startups.form.item.captcha.validator.error': '验证码不正确或已过期',
  'startups.form.item.captcha.send.error': '获取验证码失败，请稍后再试',
  'startups.form.item.captcha.send.cooldown': '秒后可重发',
  'registry.form.item.phone.occupied.error': '该手机已经注册过。',
  'registry.form.has.account': '已有帐号，去登录',
  'registry.form.has.account.download': '已有帐号，下载客户端',
  register: '注册',
  'registry.form.register.success': '注册成功!',
  'registry.form.register.failed': '注册失败,请稍后再试',
  'registry.form.register.step.1': '注册帐号',
  'registry.form.register.step.2': '完善信息',
  'registry.form.register.step.3': '完成注册',
  'registry.form.register.step.next': '下一步',
  'registry.form.perfect.info': '完善信息',
  'registry.finish.registered': '注册成功',
  'registry.finish.registered.subTitle': '接下来开启ChatPlusAI功能使用吧',
  'registry.finish.registered.subTitle2': '请先下载ChatPlusAI客户端',
  'registry.finish.go.login': '去登录',
  'registry.finish.has.downloaded.client': '如已安装ChatPlusAI客户端，可直接开启客户端使用',
  'registry.finish.download.win': 'Windows 下载',
  'registry.finish.download.mac': 'macOS 下载',
  'registry.finish.download.has.client': '我已下载客户端，',
  'registry.finish.download.open.client': '打开客户端',
  'registry.info.form.item.company.label': '公司名称',
  'registry.info.form.item.company.placeholder': '请输入公司名称，如“xxx有限公司”',
  'registry.info.form.item.company.validator.error': '请输入公司名称',
  'registry.info.form.item.email.label': '邮箱',
  'registry.info.form.item.email.placeholder': '请输入邮箱地址，如“xxxx@qq.com”',
  'registry.info.form.item.email.validator.error': '请输入正确的邮箱地址',
  'registry.info.form.item.prefix.label': '帐号前缀',
  'registry.info.form.item.prefix.placeholder': '请输入',
  'registry.info.form.item.prefix.validator.error': '请输入4-10位字母',
  'registry.info.form.item.admin.label': '登录帐号',
  'registry.info.form.item.admin.placeholder': '请输入登录帐号名6-16位，如“xiaoming”',
  'registry.info.form.item.admin.validator.error.format':
    '登录帐号必须包含英文字母，且不可含有符号“@”',
  'registry.info.form.item.admin.validator.error.required': '登录帐号必填',
  'registry.info.form.item.admin.validator.error.length': '登录帐号长度应在1~20位',
  'registry.info.form.error.admin.1020': '该帐号名称已被使用，请您修改后提交',
  'registry.info.form.error.email.1019': '该邮箱已被使用，请您修改后提交',
  'registry.info.form.error.company.1017': '该公司名字已被使用，请您修改后提交',
  'registry.info.form.agreement.label': '勾选视为都同意',
  'registry.info.form.invite.label': '邀请码',
  'registry.info.form.invite.placeholder': '请输入邀请码，选填',
  '、': '、',
  和: '和',
  'Service.Agreement': '服务协议',
  'Privacy.Policy': '隐私政策',
  'Software.License': '软件许可协议',
  'reset.title': '找回密码',
  'reset.back.to.login': '返回登录',
  'reset.back': '返回',
  'reset.prev.step': '上一步',
  'reset.message.success': '重置成功',
  'reset.password.confirm.placeholder': '请再次输入密码',
  'reset.form.password.placeholder': '请输入8-20位，必须包含大小写字母数字特殊符号',
  'reset.form.password.validate.diff': '两次输入的密码不一致',
  'reset.form.submit.btn': '提交',
  'reset.unbound.text': '未绑定手机号码',
  'reset.unbound.tips':
    '仅支持已绑定手机号的帐号的密码找回；若子帐号未绑定手机号，请联系管理员修改密码或绑定手机号码',
  发送中: '发送中',
  // 2022年3月12日
  关于ChatPlusAI: '关于ChatPlusAI',
  欢迎使用ChatPlusAI: '欢迎使用ChatPlusAI',
  登录中: '登录中',
  'register.ChatPlusAI': '注册ChatPlusAI',
  'register.form.become.admin.tips': '你将默认成为超级管理员，并拥有全部超级管理员权限',
  密码登录: '密码登录',
  验证码登录: '验证码登录',
  '服务器开小差了，请稍后再试': '服务器开小差了，请稍后再试',
  '登录失败，验证码错误': '登录失败，验证码错误',
  '密码太久未更新，请联系管理员更新密码': '密码太久未更新，请联系管理员更新密码',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `${x}帐号`,
    请输入x帐号: (x: string) => `请输入${x}帐号`,
    '登录失败，帐号或密码不正确': '登录失败，帐号或密码不正确',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `${x} 的注册帐号，如无 ${x} 帐号，请使用ChatPlusAI帐号登录`,
  },
  验证码错误: '验证码错误',
};

export const en: typeof cn = {
  ...StartupNewText.en,
  'download.client.tips': 'Please download the ChatPlusAI client.',
  'reg.tips': 'Please register',
  'btn.for.reg': 'Register Account',
  'btn.for.windows': 'Windows Download',
  'btn.for.mac': 'macOS Download',
  'has.download.tips': 'I have downloaded the client, ',
  'has.download.btn': 'open the client',
  required: 'Required',
  'login.title': 'Welcome to ChatPlusAI',
  'login.title1': 'Account password login',
  'login.form': 'Login',
  'login.form.typeAccountOrEmail': 'Account/Email',
  'login.form.typeMobile': 'Phone',
  'login.form.findPassword': 'Forget password',
  'login.form.go.register': 'Register Account',
  'login.form.submit': 'Login',
  'login.form.item.account.label': 'Account',
  'login.form.item.accountEmail.placeholder': 'Please enter account/email',
  'login.form.item.mobile.placeholder': 'Please enter mobile phone number',
  'login.form.item.password.label': 'Password',
  'registry.form.item.confirm.password.label': 'Confirm password',
  'login.form.item.password.placeholder': 'Please enter password',
  'login.form.item.remember.label': 'Remember me',
  'login.form.platform.account.placeholder': 'Please enter the platform account',
  'login.form.platform.radio.erp': '马六甲 ERP account',
  'login.form.platform.radio.chat': 'ChatPlusAI account',
  'login.form.platform.account.erp': 'Please enter your 马六甲 ERP main account',
  'login.form.platform.account.chat': 'Please enter your ChatPlusAI account',
  'login.form.platform.error':
    'The account or password is incorrect. Please select the ChatPlusAI account to log in to the customer service sub-account.',
  'login.form.success': 'Login successful',
  'registry.form.item.account.label': 'Phone',
  'registry.form.item.account.placeholder': 'Please enter mobile phone number',
  'registry.form.item.account.validator.error': 'Please enter valid mobile phone number ',
  'registry.form.item.password.label': 'Password',
  'registry.form.item.find.password.label': 'Password',
  'registry.form.item.password.format.tip':
    'Your password must at least 8 characters and contain at least one special、uppercase/lowercase、number letter',
  'registry.form.item.company.format.tip':
    'If you are a self-employed person, you can customize the input as your identity subject identifier in ChatPlusAI.',
  'registry.form.item.company.admin.tip':
    'Custom input account name, as the user name for subsequent login to ChatPlusAI',
  'registry.form.item.password.placeholder': 'Please enter password',
  'registry.form.item.password.validator.error.required':
    'Your password must be 8-20 character and contain at least one special、uppercase/lowercase、number letter(!, *, _, etc.)',
  'registry.form.item.password.validator.error.length': 'Your password must be 8-20 characters',
  'registry.form.item.password.validator.error.invalid.charaters':
    'Only number and letters are allowed in the password',
  'registry.form.item.password.validator.error.no.special.characters':
    'Your password must contain at least one special character (!, *, _, etc.)',
  'registry.form.item.password.validator.error.include.username':
    'Your password must not contain your ChatPlusAI ID',
  'registry.form.item.password.validator.error.no.number': 'Your password must contain a number.',
  'registry.form.item.password.validator.error.no.alphabet':
    'Your password must contain at least one uppercase letter or one lowercase letter',
  'startups.form.item.captcha.label': 'Captcha',
  'registry.form.perfect.info': '完善信息',
  'startups.form.item.captcha.send': 'Get',
  'startups.form.item.captcha.send.again': 'Send again',
  'startups.form.item.captcha.send.cooldown': ' seconds',
  'startups.form.item.captcha.validator.error': 'The captha is invalid or expried.',
  'startups.form.item.captcha.send.error': 'Failed to get captcha, please try again later',
  'registry.form.item.phone.occupied.error': 'The Phone has been registed before',
  'registry.form.has.account': 'Already have an account, go to log in',
  'registry.form.has.account.download': 'Already have an account, download ChatPlusAI',
  register: 'Register',
  'registry.form.register.success': 'Registration Success',
  'registry.form.register.failed': 'Registration failed, please try again later',
  'registry.form.register.step.1': 'Register Account',
  'registry.form.register.step.2': 'Perfect Information ',
  'registry.form.register.step.3': 'Complete Registration',
  'registry.form.register.step.next': 'Next',
  'registry.finish.go.login': 'Go To Login',
  'registry.finish.registered': ' Complete Registration',
  'registry.finish.registered.subTitle': 'Open ChatPlusAI and use it',
  'registry.finish.registered.subTitle2': 'Please download the ChatPlusAI client.',
  'registry.finish.has.downloaded.client':
    'If you have downloaded the ChatPlusAI application,please use the Chatt++ application to login.',
  'registry.finish.download.win': 'Windows Download',
  'registry.finish.download.mac': 'macOS Download',
  'registry.finish.download.has.client': 'I have downloaded the client, ',
  'registry.finish.download.open.client': 'open the client',
  'registry.info.form.item.company.label': 'Profile',
  'registry.info.form.item.company.placeholder': 'Please enter the company name.',
  'registry.info.form.item.company.validator.error': 'Please enter company name',
  'registry.info.form.item.email.label': 'Email',
  'registry.info.form.item.email.placeholder': 'Please enter email',
  'registry.info.form.item.email.validator.error': 'Please enter correct email',
  'registry.info.form.item.prefix.label': 'Account Prefix',
  'registry.info.form.item.prefix.placeholder': 'Please enter',
  'registry.info.form.item.prefix.validator.error': 'Please enter 4-10 letters',
  'registry.info.form.item.admin.label': 'Account',
  'registry.info.form.item.admin.placeholder': 'Please enter 6-16 characters, such as "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'English letters must be included, and you cannot use "@"',
  'registry.info.form.item.admin.validator.error.required': 'The Admin Name Is Required',
  'registry.info.form.item.admin.validator.error.length':
    'The Admin Name allowed from 1~20 characters',
  'registry.info.form.error.admin.1020':
    'The account name you submitted has been used, please change and submit again',
  'registry.info.form.error.email.1019':
    'The email you submitted has been used, please change and submit again',
  'registry.info.form.error.company.1017':
    'The company name you submitted has been used, please change and submit again',
  'registry.info.form.agreement.label': 'I have read and agree to accept the',
  'registry.info.form.invite.label': 'Invitation code',
  'registry.info.form.invite.placeholder': 'Please enter the invitation code(optional)',
  '、': ',',
  和: 'and',
  'Service.Agreement': '"Service Agreement"',
  'Privacy.Policy': '"Privacy Policy"',
  'Software.License': '"Software License"',
  'reset.title': 'Find password',
  'reset.back.to.login': 'Back to login',
  'reset.back': 'Back',
  'reset.prev.step': 'Back',
  'reset.message.success': 'Reset success',
  'reset.password.confirm.placeholder': 'Please enter the password again',
  'reset.form.password.placeholder':
    'Your password must be 8-20 character and contain at least one special、uppercase/lowercase、number letter',
  'reset.form.password.validate.diff': 'The two passwords entered are inconsistent',
  'reset.form.submit.btn': 'Submit',
  'reset.unbound.text': 'Unbound mobile phone number',
  'reset.unbound.tips':
    'You can only retrieve the password of the account that has been bound to the mobile phone number; if the sub-account is not bound to the mobile phone number, please contact the administrator to change the password or bind the phone number.',
  发送中: 'Sending',
  // 2022年3月12日

  关于ChatPlusAI: 'About ChatPlusAI',
  欢迎使用ChatPlusAI: 'Welcome to ChatPlusAI',
  'register.ChatPlusAI': 'Register ChatPlusAI',
  'register.form.become.admin.tips':
    'You will be a super administrator by default and have all super administrator privileges.',

  验证码登录: 'Login with captcha',
  密码登录: 'Login with password',
  登录中: 'logging in',
  '服务器开小差了，请稍后再试': 'Server busy, please try again later.',
  '登录失败，验证码错误': 'Login failed, captcha is wrong.',
  '密码太久未更新，请联系管理员更新密码':
    'The password has not been updated for a long time, please contact the administrator to update.',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `${x} account`,
    请输入x帐号: (x: string) => `Please enter ${x} account number`,
    '登录失败，帐号或密码不正确': 'Login failed, account or password is incorrect',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `${x} registered account, if you don't have an ${x} account, please log in with your ChatPlusAI account`,
  },
  验证码错误: 'Captcha error',
};

export const id: typeof cn = {
  ...StartupNewText.id,
  'download.client.tips': 'Sudah memiliki akun, unduh ChatPlusAI Client Version',
  'reg.tips': 'Silakan register terlebih dahulu',
  'btn.for.reg': 'Register akun',
  'btn.for.windows': 'Unduh versi Windows',
  'btn.for.mac': 'Unduh versi macOS',
  'has.download.tips': 'Saya sudah mengunduh Client Version,',
  'has.download.btn': 'Buka Client Version',
  required: 'Wajib diisi',
  'login.title': 'Selamat datang di ChatPlusAI',
  'login.title1': 'Login password',
  'login.form': 'Login',
  'login.form.typeAccountOrEmail': 'Akun/Email',
  'login.form.typeMobile': 'No. HP',
  'login.form.findPassword': 'Lupa password?',
  'login.form.go.register': 'Register akun',
  'login.form.submit': 'Login',
  'login.form.item.account.label': 'Akun',
  'login.form.item.accountEmail.placeholder': 'Masukkan akun/email',
  'login.form.item.mobile.placeholder': 'Masukkan No. HP',
  'login.form.item.password.label': 'Password',
  'login.form.item.password.placeholder': 'Masukkan password',
  'login.form.item.remember.label': 'Ingat password',
  'login.form.platform.account.placeholder': 'Masukkan akun platform',
  'login.form.platform.radio.erp': 'Akun EMALACCA ERP',
  'login.form.platform.radio.chat': 'Akun CS ChatPlusAI',
  'login.form.platform.account.erp': 'Masukkan akun utama EMALACCA ERP',
  'login.form.platform.account.chat': 'Masukkan akun CS ChatPlusAI',
  'login.form.platform.error':
    'Akun atau password salah, silakan pilih akun CS ChatPlusAI untuk login ke sub-akun CS.',
  'login.form.success': 'Login berhasil',
  'registry.form.item.account.label': 'No. HP',
  'registry.form.item.account.placeholder': 'Masukkan No. HP',
  'registry.form.item.account.validator.error': 'Harap masukkan No. HP yang valid',
  'registry.form.item.password.label': 'Atur password',
  'registry.form.item.find.password.label': 'Atur password',
  'registry.form.item.confirm.password.label': 'Konfirmasi password',
  'registry.form.item.password.placeholder': 'Masukkan password',
  'registry.form.item.password.format.tip':
    'Password minimal 8 karakter dan harus mengandung 1 huruf besar/kecil, 1 angka, dan 1 karakter khusus (seperti *, ., dll.)',
  'registry.form.item.company.format.tip':
    'Jika Anda wiraswasta, Anda dapat customize nama sebagai identitas Anda di ChatPlusAI',
  'registry.form.item.company.admin.tip':
    'Masukkan nama akun customize yang digunakan sebagai username untuk login ke ChatPlusAI',
  'registry.form.item.password.validator.error.required':
    'Harap masukkan password 8-20 karakter dan harus mengandung 1 huruf besar/kecil, 1 angka, dan 1 karakter khusus (seperti *, ., dll.)',
  'registry.form.item.password.validator.error.length': 'Harap masukkan password 8-20 karakter',
  'registry.form.item.password.validator.error.invalid.charaters':
    'Password tidak boleh mengandung karakter selain angka dan huruf',
  'registry.form.item.password.validator.error.no.number':
    'Password setidaknya mengandung satu angka',
  'registry.form.item.password.validator.error.no.alphabet':
    'Password setidaknya mengandung satu huruf besar/kecil',
  'registry.form.item.password.validator.error.no.special.characters':
    'Password setidaknya mengandung satu karakter khusus (seperti *, ., dll.)',
  'registry.form.item.password.validator.error.include.username':
    'Password tidak boleh mengandung username',
  'startups.form.item.captcha.label': 'Kode verifikasi',
  'startups.form.item.captcha.send': 'Dapatkan kode',
  'startups.form.item.captcha.send.again': 'Kirim ulang',
  'startups.form.item.captcha.validator.error': 'Kode verifikasi salah atau telah kedaluwarsa',
  'startups.form.item.captcha.send.error': 'Gagal mendapatkan kode verifikasi, silakan coba lagi',
  'startups.form.item.captcha.send.cooldown': 'detik lagi untuk kirim ulang',
  'registry.form.item.phone.occupied.error': 'No. Hp ini sudah terdaftar.',
  'registry.form.has.account': 'Sudah memiliki akun, pergi login',
  'registry.form.has.account.download': 'Sudah memiliki akun, unduh Client Version',
  register: 'Register',
  'registry.form.register.success': 'Register berhasil!',
  'registry.form.register.failed': 'Register gagal, silakan coba lagi',
  'registry.form.register.step.1': 'Register akun',
  'registry.form.register.step.2': 'Lengkapi data',
  'registry.form.register.step.3': 'Selesaikan register',
  'registry.form.register.step.next': 'Selanjutnya',
  'registry.form.perfect.info': 'Lengkapi data',
  'registry.finish.registered': 'Register berhasil',
  'registry.finish.registered.subTitle':
    'Selanjutnya, ayo aktifkan fitur ChatPlusAI dan mulai gunakan',
  'registry.finish.registered.subTitle2': 'Harap unduh ChatPlusAI Client Version terlebih dahulu',
  'registry.finish.go.login': 'Login',
  'registry.finish.has.downloaded.client':
    'Jika Anda telah menginstal ChatPlusAI Client Version, Anda dapat langsung buka dan menggunakannya',
  'registry.finish.download.win': 'Unduh Windows',
  'registry.finish.download.mac': 'Unduh macOS',
  'registry.finish.download.has.client': 'Saya telah mengunduh Client Version,',
  'registry.finish.download.open.client': 'Buka Client Version',
  'registry.info.form.item.company.label': 'Nama perusahaan',
  'registry.info.form.item.company.placeholder': 'Masukkan nama perusahaan, seperti "PT xxx"',
  'registry.info.form.item.company.validator.error': 'Harap masukkan nama perusahaan',
  'registry.info.form.item.email.label': 'Kontak Email',
  'registry.info.form.item.email.placeholder': 'Masukkan alamat email, seperti "xxxx@qq.com"',
  'registry.info.form.item.email.validator.error': 'Harap masukkan alamat email yang benar',
  'registry.info.form.item.prefix.label': 'Prefix akun',
  'registry.info.form.item.prefix.placeholder': 'Harap masukkan',
  'registry.info.form.item.prefix.validator.error': 'Harap masukkan 4-10 huruf',
  'registry.info.form.item.admin.label': 'Akun login',
  'registry.info.form.item.admin.placeholder':
    'Masukkan nama akun login 6-16 karakter, seperti "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'Akun login harus mengandung huruf alfabet dan tidak boleh mengandung simbol "@"',
  'registry.info.form.item.admin.validator.error.required': 'Akun login wajib diisi',
  'registry.info.form.item.admin.validator.error.length':
    'Jumlah karakter akun login harus 1-20 karakter',
  'registry.info.form.error.admin.1020':
    'Nama akun ini telah digunakan, harap ubah dan kirim ulang',
  'registry.info.form.error.email.1019': 'Email ini telah digunakan, harap ubah dan kirim ulang',
  'registry.info.form.error.company.1017':
    'Nama perusahaan telah digunakan, harap ubah dan kirim ulang',
  'registry.info.form.agreement.label': 'Saya menyetujui ',
  'registry.info.form.invite.label': 'Kode undangan',
  'registry.info.form.invite.placeholder': 'Silakan masukkan kode undangan (opsional)',
  '、': ', ',
  和: ' dan ',
  'Service.Agreement': 'Perjanjian Layanan',
  'Privacy.Policy': 'Kebijakan Privasi',
  'Software.License': 'Perjanjian Lisensi Software',
  'reset.title': 'Cari password',
  'reset.back.to.login': 'Kembali login',
  'reset.back': 'Kembali',
  'reset.prev.step': 'Sebelumnya',
  'reset.message.success': 'Reset berhasil',
  'reset.password.confirm.placeholder': 'Silakan masukkan password lagi',
  'reset.form.password.placeholder':
    'Masukkan 8-20 karakter, harus mengandung huruf besar, huruf kecil, angka, dan karakter khusus',
  'reset.form.password.validate.diff': 'Kedua password yang dimasukkan tidak sama',
  'reset.form.submit.btn': 'Submit',
  'reset.unbound.text': 'Belum terhubung ke No. HP',
  'reset.unbound.tips':
    'Hanya mendukung pemulihan password pada akun yang sudah terhubung dengan No. HP; Jika sub-akun belum terhubung dengan nomor HP, silakan hubungi administrator untuk mengubah password atau hubungkan No. HP',
  发送中: 'Sedang mengirim',

  // 2022年3月12日
  关于ChatPlusAI: 'Tentang ChatPlusAI',
  欢迎使用ChatPlusAI: 'Selamat menggunakan ChatPlusAI',
  'register.ChatPlusAI': 'Register ChatPlusAI',
  'register.form.become.admin.tips':
    'Anda akan menjadi admin super secara default dan memiliki semua hak admin super',
  密码登录: 'Log in melalui password',
  验证码登录: 'Login OTP',
  登录中: 'Sedang log in',
  '服务器开小差了，请稍后再试': 'Server sedang sibuk, silakan coba beberapa saat lagi',
  '登录失败，验证码错误': 'Gagal log in, kode OTP salah',
  '密码太久未更新，请联系管理员更新密码':
    'Password terlalu lama tidak diperbarui, silakan hubungi admin untuk perbarui password',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `Akun ${x}`,
    请输入x帐号: (x: string) => `Harap masukkan akun ${x}`,
    '登录失败，帐号或密码不正确': 'Gagal login, akun atau password salah',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `Akun ${x} yang terdaftar, jika Anda tidak memiliki akun ${x}, silakan login dengan akun ChatPlusAI`,
  },
  验证码错误: 'Kesalahan kode verifikasi',
};

export const th: typeof cn = {
  ...StartupNewText.th,
  'download.client.tips': 'มีบัญชีอยู่แล้ว ดาวน์โหลด ChatPlusAI ไคลเอนต์',
  'reg.tips': 'กรุณาลงทะเบียนก่อน',
  'btn.for.reg': 'ลงทะเบียนบัญชี',
  'btn.for.windows': 'ดาวน์โหลดเวอร์ชั่น Windows',
  'btn.for.mac': 'ดาวน์โหลดเวอร์ชั่น macOS ',
  'has.download.tips': 'ฉันดาวน์โหลดไคลเอนต์แล้ว ',
  'has.download.btn': 'เปิดไคลเอนต์',
  required: 'จำเป็นต้องกรอก',
  'login.title': 'ยินดีต้อนรับสู่ ChatPlusAI',
  'login.title1': 'เข้าสู่ระบบด้วยบัญชีและรหัสผ่าน',
  'login.form': 'เข้าสู่ระบบ',
  'login.form.typeAccountOrEmail': 'บัญชี / อีเมล์',
  'login.form.typeMobile': 'หมายเลขโทรศัพท์',
  'login.form.findPassword': 'ลืมรหัสผ่าน?',
  'login.form.go.register': 'ลงทะเบียนบัญชี',
  'login.form.submit': 'เข้าสู่ระบบ',
  'login.form.item.account.label': 'บัญชี',
  'login.form.item.accountEmail.placeholder': 'กรุณากรอกบัญชี/อีเมล์',
  'login.form.item.mobile.placeholder': 'กรุณากรอกหมายเลขโทรศัพท์',
  'login.form.item.password.label': 'รหัสผ่าน',
  'login.form.item.password.placeholder': 'กรุณากรอกรหัสผ่าน',
  'login.form.item.remember.label': 'จดจำรหัสผ่าน',
  'login.form.platform.account.placeholder': 'กรุณากรอกบัญชีแพลตฟอร์ม',
  'login.form.platform.radio.erp': 'บัญชี EMALACCA ERP',
  'login.form.platform.radio.chat': 'บัญชีฝ่ายบริการลูกค้า ChatPlusAI',
  'login.form.platform.account.erp': 'กรุณากรอกบัญชีหลักของ EMALACCA ERP',
  'login.form.platform.account.chat': 'กรุณากรอกบัญชีฝ่ายบริการลูกค้า ChatPlusAI',
  'login.form.platform.error':
    'บัญชีหรือรหัสผ่านไม่ถูกต้อง สำหรับฝ่ายบริการลูกค้าบัญชีย่อย โปรดเลือกบัญชีฝ่ายบริการลูกค้า ChatPlusAI ในการเข้าสู่ระบบ',
  'login.form.success': 'เข้าสู่ระบบสำเร็จ',
  'registry.form.item.account.label': 'หมายเลขโทรศัพท์',
  'registry.form.item.account.placeholder': 'กรุณากรอกหมายเลขโทรศัพท์',
  'registry.form.item.account.validator.error': 'กรุณากรอกหมายเลขโทรศัพท์ที่ใช้งานได้',
  'registry.form.item.password.label': 'ตั้งค่ารหัสผ่าน',
  'registry.form.item.find.password.label': 'ตั้งค่ารหัสผ่าน',
  'registry.form.item.confirm.password.label': 'ยืนยันรหัสผ่าน',
  'registry.form.item.password.placeholder': 'กรุณากรอกรหัสผ่าน',
  'registry.form.item.password.format.tip':
    'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร รหัสผ่านต้องมีอักษรตัวพิมพ์ใหญ่/ตัวพิมพ์เล็ก 1 ตัว ตัวเลข 1 ตัว และเครื่องหมายพิเศษ 1 ตัว (เช่น *, ., เป็นต้น)',
  'registry.form.item.company.format.tip':
    'หากคุณเป็นบัญชีส่วนตัว คุณสามารถตั้งชื่อเองได้ เพื่อเป็นไอดีประจำตัวคุณใน ChatPlusAI',
  'registry.form.item.company.admin.tip':
    'ตั้งชื่อบัญชีเอง เพื่อเป็นชื่อผู้ใช้สำหรับเข้าสู่ระบบ ChatPlusAI ในครั้งต่อไป',
  'registry.form.item.password.validator.error.required':
    'กรุณากรอกรหัสผ่าน 8-20 หลัก รหัสผ่านต้องมีอักษรตัวพิมพ์ใหญ่/ตัวพิมพ์เล็ก 1 ตัว ตัวเลข 1 ตัว และเครื่องหมายพิเศษ 1 ตัว (เช่น *, ., เป็นต้น)',
  'registry.form.item.password.validator.error.length': 'กรุณากรอกรหัสผ่าน 8-20 หลัก',
  'registry.form.item.password.validator.error.invalid.charaters':
    'รหัสผ่านต้องไม่มีเครื่องหมายอื่นที่ไม่ใช่ตัวเลขและตัวอักษร',
  'registry.form.item.password.validator.error.no.number':
    'รหัสผ่านต้องประกอบด้วยตัวเลขอย่างน้อยหนึ่งหลัก',
  'registry.form.item.password.validator.error.no.alphabet':
    'รหัสผ่านต้องประกอบด้วยอักษรตัวพิมพ์ใหญ่/ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว',
  'registry.form.item.password.validator.error.no.special.characters':
    'รหัสผ่านต้องประกอบด้วยเครื่องหมายพิเศษอย่างน้อยหนึ่งตัว (เช่น *, ., เป็นต้น)',
  'registry.form.item.password.validator.error.include.username':
    'รหัสผ่านไม่สามารถประกอบด้วยชื่อผู้ใช้',
  'startups.form.item.captcha.label': 'รหัสยืนยัน',
  'startups.form.item.captcha.send': 'รับรหัสยืนยัน',
  'startups.form.item.captcha.send.again': 'ส่งอีกครั้ง',
  'startups.form.item.captcha.validator.error': 'รหัสยืนยันไม่ถูกต้องหรือหมดอายุแล้ว',
  'startups.form.item.captcha.send.error': 'รับรหัสยืนยันไม่สำเร็จ โปรดลองอีกครั้งในภายหลัง',
  'startups.form.item.captcha.send.cooldown': 'ส่งใหม่หลังจากวินาที',
  'registry.form.item.phone.occupied.error': 'โทรศัพท์นี้เคยลงทะเบียนแล้ว。',
  'registry.form.has.account': 'มีบัญชีอยู่แล้ว เข้าสู่ระบบ',
  'registry.form.has.account.download': 'มีบัญชีอยู่แล้ว ดาวน์โหลดไคลเอนต์',
  register: 'ลงทะเบียน',
  'registry.form.register.success': 'ลงทะเบียนสำเร็จ!',
  'registry.form.register.failed': 'ลงทะเบียนล้มเหลว โปรดลองอีกครั้งในภายหลัง',
  'registry.form.register.step.1': 'ลงทะเบียนบัญชี',
  'registry.form.register.step.2': 'กรอกข้อมูลให้ครบถ้วน',
  'registry.form.register.step.3': 'ลงทะเบียนเสร็จสิ้น',
  'registry.form.register.step.next': 'ขั้นตอนต่อไป',
  'registry.form.perfect.info': 'กรอกข้อมูลให้ครบถ้วน',
  'registry.finish.registered': 'ลงทะเบียนสำเร็จ',
  'registry.finish.registered.subTitle': 'ลำดับต่อไปเปิดฟังก์ชัน ChatPlusAI และใช้งานกันเถอะ',
  'registry.finish.registered.subTitle2': 'กรุณาดาวน์โหลด ChatPlusAI ไคลเอนต์ก่อน',
  'registry.finish.go.login': 'ไปที่เข้าสู่ระบบ',
  'registry.finish.has.downloaded.client':
    'หากคุณได้ติดตั้ง ChatPlusAI ไคลเอนต์ คุณสามารถเปิดไคลเอนต์เพื่อใช้งานได้โดยตรง',
  'registry.finish.download.win': 'ดาวน์โหลด Windows',
  'registry.finish.download.mac': 'ดาวน์โหลด macOS ',
  'registry.finish.download.has.client': 'ฉันดาวน์โหลดไคลเอนต์แล้ว ',
  'registry.finish.download.open.client': 'เปิดไคลเอนต์',
  'registry.info.form.item.company.label': 'ชื่อบริษัท',
  'registry.info.form.item.company.placeholder': 'กรุณากรอกชื่อบริษัท เช่น "xxx บริษัทจำกัด"',
  'registry.info.form.item.company.validator.error': 'กรุณากรอกชื่อบริษัท',
  'registry.info.form.item.email.label': 'อีเมล์ติดต่อ',
  'registry.info.form.item.email.placeholder': 'กรุณากรอกอีเมล์ เช่น "xxxx@qq.com"',
  'registry.info.form.item.email.validator.error': 'กรุณากรอกอีเมล์ให้ถูกต้อง',
  'registry.info.form.item.prefix.label': 'คำนำหน้าบัญชี',
  'registry.info.form.item.prefix.placeholder': 'กรุณากรอก',
  'registry.info.form.item.prefix.validator.error': 'กรุณากรอก 4-10 ตัวอักษร',
  'registry.info.form.item.admin.label': 'เข้าสู่ระบบบัญชี',
  'registry.info.form.item.admin.placeholder':
    'กรุณากรอกชื่อบัญชีเข้าสู่ระบบ 6-16 หลัก เช่น "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'บัญชีเข้าสู่ระบบต้องมีตัวอักษรภาษาอังกฤษและไม่สามารถมีเครื่องหมาย "@"',
  'registry.info.form.item.admin.validator.error.required': 'จำเป็นต้องกรอกบัญชีเข้าสู่ระบบ',
  'registry.info.form.item.admin.validator.error.length':
    'ความยาวของบัญชีเข้าสู่ระบบควรอยู่ระหว่าง 1-20 หลัก',
  'registry.info.form.error.admin.1020':
    'ชื่อบัญชีนี้ได้ถูกใช้ไปแล้ว กรุณาแก้ไขแล้วยื่นใหม่อีกครั้ง',
  'registry.info.form.error.email.1019': 'อีเมล์นี้ได้ถูกใช้ไปแล้ว กรุณาแก้ไขแล้วยื่นใหม่อีกครั้ง',
  'registry.info.form.error.company.1017':
    'ชื่อบริษัทนี้ได้ถูกใช้ไปแล้ว กรุณาแก้ไขแล้วยื่นใหม่อีกครั้ง',
  'registry.info.form.agreement.label': 'ยอมรับ',
  'registry.info.form.invite.label': 'รหัสการเชิญ',
  'registry.info.form.invite.placeholder': 'กรุณาใส่รหัสเชิญ (ไม่บังคับ)',
  '、': ' ',
  和: 'และ',
  'Service.Agreement': 'สัญญาการบริการ',
  'Privacy.Policy': 'นโยบายความเป็นส่วนตัว',
  'Software.License': 'สัญญาสิทธิ์ใช้งานซอฟต์แวร์',
  'reset.title': 'กู้คืนรหัสผ่าน',
  'reset.back.to.login': 'ย้อนกลับ เข้าสู่ระบบ',
  'reset.back': 'ย้อนกลับ',
  'reset.prev.step': 'ก่อนหน้า',
  'reset.message.success': 'รีเซ็ตสำเร็จ',
  'reset.password.confirm.placeholder': 'กรุณากรอกรหัสผ่านอีกครั้ง',
  'reset.form.password.placeholder':
    'กรุณากรอก 8-20 หลัก ต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่พิมพ์เล็กตัวเลขและเครื่องหมายพิเศษ',
  'reset.form.password.validate.diff': 'รหัสผ่านที่กรอกทั้งสองไม่ตรงกัน',
  'reset.form.submit.btn': 'ยื่น',
  'reset.unbound.text': 'ไม่ได้เชื่อมโยงหมายเลขโทรศัพท์',
  'reset.unbound.tips':
    'รองรับการกู้คืนรหัสผ่านเฉพาะบัญชีที่เชื่อมโยงหมายเลขโทรศัพท์ไว้เท่านั้น หากบัญชีย่อยไม่ได้เชื่อมโยงหมายเลขโทรศัพท์ โปรดติดต่อผู้ดูแลระบบเพื่อเปลี่ยนรหัสผ่านหรือเชื่อมโยงหมายเลขโทรศัพท์',
  发送中: 'กำลังส่ง',
  关于ChatPlusAI: 'เกี่ยวกับChatPlusAI',
  欢迎使用ChatPlusAI: 'ยินดีต้อนรับสู่ChatPlusAI',
  'register.ChatPlusAI': 'ลงทะเบียน ChatPlusAI',
  'register.form.become.admin.tips':
    'คุณจะเป็นผู้ดูแลระบบขั้นสูง และมีสิทธิ์ดูแลระบบขั้นสูงทั้งหมด',
  密码登录: 'รหัสผ่านเข้าสู่ระบบ',
  验证码登录: 'รหัสยืนยันเข้าสู่ระบบ',
  登录中: 'กำลังเข้าสู่ระบบ',
  '服务器开小差了，请稍后再试': 'เซิร์ฟเวอร์ไม่ว่าง โปรดลองอีกครั้งในภายหลัง',
  '登录失败，验证码错误': 'การเข้าสู่ระบบล้มเหลว รหัสยืนยันไม่ถูกต้อง',
  '密码太久未更新，请联系管理员更新密码':
    'รหัสผ่านไม่ได้รับการอัพเดตเป็นเวลานาน โปรดติดต่อผู้ดูแลระบบเพื่ออัพเดตรหัสผ่าน',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `บัญชี${x}`,
    请输入x帐号: (x: string) => `กรุณากรอกบัญชี${x}`,
    '登录失败，帐号或密码不正确': 'เข้าสู่ระบบล้มเหลว บัญชีหรือรหัสผ่านไม่ถูกต้อง',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `บัญชีที่ลงทะเบียน ${x} หากคุณไม่มีบัญชี ${x} โปรดเข้าสู่ระบบด้วยบัญชี ChatPlusAI`,
  },
  验证码错误: 'รหัสยืนยันผิดพลาด',
};

export const tl: typeof cn = {
  ...StartupNewText.tl,
  'download.client.tips': 'Please download the ChatPlusAI client.',
  'reg.tips': 'Please register',
  'btn.for.reg': 'Register Account',
  'btn.for.windows': 'Windows Download',
  'btn.for.mac': 'macOS Download',
  'has.download.tips': 'I have downloaded the client, ',
  'has.download.btn': 'open the client',
  required: 'Required',
  'login.title': 'Welcome to ChatPlusAI',
  'login.title1': 'Account password login',
  'login.form': 'Login',
  'login.form.typeAccountOrEmail': 'Account/Email',
  'login.form.typeMobile': 'Phone',
  'login.form.findPassword': 'Forget password',
  'login.form.go.register': 'Register Account',
  'login.form.submit': 'Login',
  'login.form.item.account.label': 'Account',
  'login.form.item.accountEmail.placeholder': 'Please enter account/email',
  'login.form.item.mobile.placeholder': 'Please enter mobile phone number',
  'login.form.item.password.label': 'Password',
  'login.form.item.password.placeholder': 'Please enter password',
  'login.form.item.remember.label': 'Remember me',
  'login.form.platform.account.placeholder': 'Please enter the platform account',
  'login.form.platform.radio.erp': 'EMALACCA ERP account',
  'login.form.platform.radio.chat': 'ChatPlusAI account',
  'login.form.platform.account.erp': 'Please enter your EMALACCA ERP main account',
  'login.form.platform.account.chat': 'Please enter your ChatPlusAI account',
  'login.form.platform.error':
    'The account or password is incorrect. Please select the ChatPlusAI account to log in to the customer service sub-account.',
  'login.form.success': 'Login successful',
  'registry.form.item.account.label': 'Phone',
  'registry.form.item.account.placeholder': 'Please enter mobile phone number',
  'registry.form.item.account.validator.error': 'Please enter valid mobile phone number ',
  'registry.form.item.password.label': 'Password',
  'registry.form.item.find.password.label': 'Password',
  'registry.form.item.confirm.password.label': 'Confirm password',
  'registry.form.item.password.placeholder': 'Please enter password',
  'registry.form.item.password.format.tip':
    'Your password must at least 8 characters and contain at least one special、uppercase/lowercase、number letter',
  'registry.form.item.company.format.tip':
    'Kung ito ay personal account, pweding i-customize ang pag-input ng iyong identity sa ChatPlusAI',
  'registry.form.item.company.admin.tip':
    'Customize the input account name as the user name to login to ChatPlusAI',
  'registry.form.item.password.validator.error.required':
    'Your password must be 8-20 character and contain at least one special、uppercase/lowercase、number letter(!, *, _, etc.)',
  'registry.form.item.password.validator.error.length': 'Your password must be 8-20 characters',
  'registry.form.item.password.validator.error.invalid.charaters':
    'Password cannot contain characters other than numbers and letters',
  'registry.form.item.password.validator.error.no.number': 'Your password must contain a number.',
  'registry.form.item.password.validator.error.no.alphabet':
    'Your password must contain at least one uppercase letter or one lowercase letter',
  'registry.form.item.password.validator.error.no.special.characters':
    'Your password must contain at least one special character (!, *, _, etc.)',
  'registry.form.item.password.validator.error.include.username':
    'Password cannot contain username',
  'startups.form.item.captcha.label': 'Captcha',
  'startups.form.item.captcha.send': 'Get',
  'startups.form.item.captcha.send.again': 'Send again',
  'startups.form.item.captcha.validator.error': 'The captha is invalid or expried.',
  'startups.form.item.captcha.send.error':
    'Nag-fail ang pag-load ng captcha, maaaring subukan ulit mamaya',
  'startups.form.item.captcha.send.cooldown': 'Resend in seconds',
  'registry.form.item.phone.occupied.error': 'The Phone has been registed before',
  'registry.form.has.account': 'Already have an account, go to log in',
  'registry.form.has.account.download': 'Already have an account, download ChatPlusAI',
  register: 'Register',
  'registry.form.register.success': 'Registration Success',
  'registry.form.register.failed': 'Nag-fail ang pag-register, maaaring subukan ulit mamaya',
  'registry.form.register.step.1': 'Register Account',
  'registry.form.register.step.2': 'Replenish Information',
  'registry.form.register.step.3': 'Complete Registration',
  'registry.form.register.step.next': 'Next',
  'registry.form.perfect.info': 'Replenish Information',
  'registry.finish.registered': 'Complete Registration',
  'registry.finish.registered.subTitle': 'Open ChatPlusAI and use it',
  'registry.finish.registered.subTitle2': 'Please download the ChatPlusAI client.',
  'registry.finish.go.login': 'Go To Login',
  'registry.finish.has.downloaded.client':
    'If you have downloaded the ChatPlusAI application,please use the Chatt++ application to login.',
  'registry.finish.download.win': 'Windows Download',
  'registry.finish.download.mac': 'macOS Download',
  'registry.finish.download.has.client': 'I have downloaded the client, ',
  'registry.finish.download.open.client': 'open the client',
  'registry.info.form.item.company.label': 'Profile',
  'registry.info.form.item.company.placeholder': 'Please enter the company name.',
  'registry.info.form.item.company.validator.error': 'Please enter company name',
  'registry.info.form.item.email.label': 'Email',
  'registry.info.form.item.email.placeholder': 'Please enter email',
  'registry.info.form.item.email.validator.error': 'Please enter correct email',
  'registry.info.form.item.prefix.label': 'Account Prefix',
  'registry.info.form.item.prefix.placeholder': 'Please enter',
  'registry.info.form.item.prefix.validator.error': 'Please enter 4-10 letters',
  'registry.info.form.item.admin.label': 'Account',
  'registry.info.form.item.admin.placeholder': 'Please enter 6-16 characters, such as xiaoming',
  'registry.info.form.item.admin.validator.error.format':
    'English letters must be included, and you cannot use @',
  'registry.info.form.item.admin.validator.error.required': 'The Admin Name Is Required',
  'registry.info.form.item.admin.validator.error.length':
    'The Admin Name allowed from 1~20 characters',
  'registry.info.form.error.admin.1020':
    'The account name you submitted has been used, please change and submit again',
  'registry.info.form.error.email.1019':
    'The email you submitted has been used, please change and submit again',
  'registry.info.form.error.company.1017':
    'The company name you submitted has been used, please change and submit again',
  'registry.info.form.agreement.label': 'I have read and agree to accept the',
  'registry.info.form.invite.label': 'Invitation code',
  'registry.info.form.invite.placeholder': 'Please enter the invitation code(optional)',
  '、': ',',
  和: 'and',
  'Service.Agreement': 'Service Agreement',
  'Privacy.Policy': 'Privacy Policy',
  'Software.License': 'Software License',
  'reset.title': 'Find password',
  'reset.back.to.login': 'Back to login',
  'reset.back': 'Back',
  'reset.prev.step': 'Back',
  'reset.message.success': 'Reset success',
  'reset.password.confirm.placeholder': 'Please enter the password again',
  'reset.form.password.placeholder':
    'Your password must be 8-20 character and contain at least one special、uppercase/lowercase、number letter',
  'reset.form.password.validate.diff': 'The two passwords entered are inconsistent',
  'reset.form.submit.btn': 'Submit',
  'reset.unbound.text': 'Unbound mobile phone number',
  'reset.unbound.tips':
    'You can only retrieve the password of the account that has been bound to the mobile phone number; if the sub-account is not bound to the mobile phone number, please contact the administrator to change the password or bind the phone number.',
  发送中: 'Sending',
  关于ChatPlusAI: 'About ChatPlusAI',
  欢迎使用ChatPlusAI: 'Welcome to ChatPlusAI',
  'register.ChatPlusAI': 'Register ChatPlusAI',
  'register.form.become.admin.tips':
    'You will be a super administrator by default and have all super administrator privileges.',
  密码登录: 'Login with password',
  验证码登录: 'Login with captcha',
  登录中: 'logging in',
  '服务器开小差了，请稍后再试': 'Server busy, please try again later.',
  '登录失败，验证码错误': 'Login failed, captcha is wrong.',
  '密码太久未更新，请联系管理员更新密码':
    'The password has not been updated for a long time, please contact the administrator to update.',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `${x}account`,
    请输入x帐号: (x: string) => `Please enter ${x} account number`,
    '登录失败，帐号或密码不正确': 'Login failed, account or password is incorrect',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `${x} registered account, if you don't have an ${x} account, please log in with your ChatPlusAI account`,
  },
  验证码错误: 'Error sa captcha',
};

export const es: typeof cn = {
  ...StartupNewText.es,
  'download.client.tips': 'Ya tienes una cuenta, descarga el cliente ChatPlusAI',
  'reg.tips': 'Por favor, regístrese primero',
  'btn.for.reg': 'Ir a registrar la cuenta',
  'btn.for.windows': 'Descarga de la versión de Windows',
  'btn.for.mac': 'descarga de la versión de macOS',
  'has.download.tips': 'He descargado el cliente,',
  'has.download.btn': 'Abrir cliente',
  required: 'Requerido',
  'login.title': 'Bienvenido a ChatPlusAI',
  'login.title1': 'Acceder con contraseña',
  'login.form': 'Acceder',
  'login.form.typeAccountOrEmail': 'Cuenta/Correo',
  'login.form.typeMobile': 'Número de móvil',
  'login.form.findPassword': '¿Has olvidado tu contraseña?',
  'login.form.go.register': 'Registrar cuenta',
  'login.form.submit': 'Acceder',
  'login.form.item.account.label': 'Número de cuenta',
  'login.form.item.accountEmail.placeholder':
    'Por favor, introduzca el número de cuenta/correo electrónico',
  'login.form.item.mobile.placeholder': 'Por favor, introduzca su número de teléfono móvil',
  'login.form.item.password.label': 'Contraseña',
  'login.form.item.password.placeholder': 'Por favor, introduzca su contraseña',
  'login.form.item.remember.label': 'Remember password',
  'login.form.platform.account.placeholder': 'Por favor, introduzca la cuenta de la plataforma',
  'login.form.platform.radio.erp': 'Malacca ERP account',
  'login.form.platform.radio.chat': 'Cuenta de atención al cliente de Lechat',
  'login.form.platform.account.erp': 'Por favor, introduzca la cuenta maestra de Melaka ERP',
  'login.form.platform.account.chat':
    'Por favor, introduzca su cuenta de servicio al cliente de Lechat',
  'login.form.platform.error':
    'Cuenta o contraseña incorrecta, por favor seleccione la cuenta de servicio al cliente de Leroy para iniciar sesión.',
  'login.form.success': 'Login successful',
  'registry.form.item.account.label': 'Número de teléfono móvil',
  'registry.form.item.account.placeholder': 'Por favor, introduzca su número de teléfono móvil',
  'registry.form.item.account.validator.error': 'Por favor, introduzca un número de móvil válido',
  'registry.form.item.password.label': 'Set password',
  'registry.form.item.find.password.label': 'Set password',
  'registry.form.item.confirm.password.label': 'Confirmar contraseña',
  'registry.form.item.password.placeholder': 'Por favor, introduzca su contraseña',
  'registry.form.item.password.format.tip':
    'La contraseña debe tener al menos 8 caracteres y debe contener 1 letra mayúscula/minúscula, 1 dígito, 1 carácter especial (por ejemplo, *, . etc.)',
  'registry.form.item.company.format.tip':
    'Si eres un autónomo, puedes introducirlo como tu identidad en Lechat',
  'registry.form.item.company.admin.tip':
    'Introduzca un nombre de cuenta personalizado que se utilizará como su posterior inicio de sesión en Lechat',
  'registry.form.item.password.validator.error.required':
    'Por favor, introduzca una contraseña de 8 a 20 dígitos, que debe contener 1 letra mayúscula o minúscula, 1 dígito y 1 carácter especial (por ejemplo, *, . etc.)',
  'registry.form.item.password.validator.error.length':
    'Por favor, introduzca una contraseña de 8-20 dígitos',
  'registry.form.item.password.validator.error.invalid.charaters':
    'La contraseña no puede contener otros caracteres que no sean números y letras',
  'registry.form.item.password.validator.error.no.number':
    'La contraseña debe contener al menos un número',
  'registry.form.item.password.validator.error.no.alphabet':
    'La contraseña contiene al menos una letra mayúscula/minúscula',
  'registry.form.item.password.validator.error.no.special.characters':
    'La contraseña contiene al menos un carácter especial (por ejemplo, *, . etc.)',
  'registry.form.item.password.validator.error.include.username':
    'La contraseña no debe incluir el nombre de usuario',
  'startups.form.item.captcha.label': 'Código',
  'startups.form.item.captcha.send': 'Obtener código',
  'startups.form.item.captcha.send.again': 'Enviar de nuevo',
  'startups.form.item.captcha.validator.error': 'El captcha es incorrecto o ha caducado',
  'startups.form.item.captcha.send.error':
    'Fallo en la obtención del captcha, por favor, inténtelo más tarde',
  'startups.form.item.captcha.send.cooldown': 'Reenviable después de segundos',
  'registry.form.item.phone.occupied.error': 'El teléfono ya ha sido registrado.',
  'registry.form.has.account': 'Ya hay una cuenta, ir a login',
  'registry.form.has.account.download': 'Cuenta existente, descargando cliente',
  register: 'Registrar',
  'registry.form.register.success': '¡Registro exitoso!',
  'registry.form.register.failed': 'Registro fallido, por favor inténtelo más tarde',
  'registry.form.register.step.1': 'Registering account',
  'registry.form.register.step.2': 'Información completa',
  'registry.form.register.step.3': 'Completar registración',
  'registry.form.register.step.next': 'Siguiente',
  'registry.form.perfect.info': 'Información perfecta',
  'registry.finish.registered': 'Registro exitoso',
  'registry.finish.registered.subTitle': 'A continuación, enciende Lechat y úsalo',
  'registry.finish.registered.subTitle2': 'Please download ChatPlusAI client first',
  'registry.finish.go.login': 'Ir al login',
  'registry.finish.has.downloaded.client':
    'Si ya has instalado el cliente Lechat, puedes abrir el cliente directamente',
  'registry.finish.download.win': 'Windows download',
  'registry.finish.download.mac': 'macOS download',
  'registry.finish.download.has.client': 'He descargado el cliente,',
  'registry.finish.download.open.client': 'Abrir cliente',
  'registry.info.form.item.company.label': 'Nombre de la empresa',
  'registry.info.form.item.company.placeholder':
    'Por favor, introduzca un nombre de empresa, por ejemplo, "xxx Ltd"',
  'registry.info.form.item.company.validator.error': 'Introduzca el nombre de la empresa',
  'registry.info.form.item.email.label': 'Correo electrónico de contacto',
  'registry.info.form.item.email.placeholder':
    'Por favor, introduzca una dirección de correo electrónico, por ejemplo "xxxx@qq.com"',
  'registry.info.form.item.email.validator.error': 'Please enter the correct email address',
  'registry.info.form.item.prefix.label': 'Prefijo de la cuenta',
  'registry.info.form.item.prefix.placeholder': 'Profavor introduzca',
  'registry.info.form.item.prefix.validator.error': 'Por favor, introduzca de 4 a 10 dígitos',
  'registry.info.form.item.admin.label': 'Accerde cuenta',
  'registry.info.form.item.admin.placeholder':
    'Por favor, introduzca el nombre de usuario en 6-16 dígitos, por ejemplo, "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'La cuenta de acceso debe contener caracteres alfabéticos y no debe contener el símbolo "@"',
  'registry.info.form.item.admin.validator.error.required': 'Se requiere cuenta de acceso',
  'registry.info.form.item.admin.validator.error.length':
    'La longitud de la cuenta de acceso debe ser de 1~20 dígitos',
  'registry.info.form.error.admin.1020':
    'El nombre de la cuenta ha sido utilizado, por favor envíelo después de haberlo cambiado',
  'registry.info.form.error.email.1019':
    'Esta dirección de correo electrónico ha sido utilizada, por favor cámbiela y envíela',
  'registry.info.form.error.company.1017':
    'Se ha utilizado el nombre de la empresa, por favor, cámbielo y envíelo',
  'registry.info.form.agreement.label': 'Marque todo lo acordado',
  'registry.info.form.invite.label': 'Código de invitación',
  'registry.info.form.invite.placeholder': 'Por favor ingrese el código de invitación (opcional)',
  '、': ', ',
  和: 'y',
  'Service.Agreement': 'Acuerdo de servicio',
  'Privacy.Policy': 'Política de privacidad',
  'Software.License': 'Software License Agreement',
  'reset.title': 'Recuperar contraseña',
  'reset.back.to.login': 'Volver al login',
  'reset.back': 'return',
  'reset.prev.step': 'Paso anterior',
  'reset.message.success': 'Reset success',
  'reset.password.confirm.placeholder': 'Por favor, introduzca su contraseña de nuevo',
  'reset.form.password.placeholder':
    'Por favor, introduzca de 8 a 20 dígitos, debe incluir caracteres especiales alfanuméricos en mayúsculas y minúsculas',
  'reset.form.password.validate.diff': 'La contraseña introducida dos veces no coincide',
  'reset.form.submit.btn': 'Enviar',
  'reset.unbound.text': 'Número de móvil no vinculado',
  'reset.unbound.tips':
    'Si la subcuenta no tiene un número de teléfono móvil vinculado, póngase en contacto con el administrador para cambiar la contraseña o vincular el número de teléfono móvil',
  发送中: 'Enviando',
  // 2022年3月12日
  关于ChatPlusAI: 'Sobre el chat de LeChatPlusAI',
  欢迎使用ChatPlusAI: 'Bienvenido al chat de LeChatPlusAI',
  登录中: 'Accediendo',
  'register.ChatPlusAI': 'Regístrese en ChatPlusAI',
  'register.form.become.admin.tips':
    'Te convertirás en superadministrador por defecto y tendrás todos los derechos de superadministrador',
  密码登录: 'Contraseña de inicio de sesión',
  验证码登录: 'Acceder con Captcha',
  '服务器开小差了，请稍后再试': 'El servidor se está agotando, por favor inténtelo más tarde',
  '登录失败，验证码错误': 'Error de inicio de sesión, error de captcha',
  '密码太久未更新，请联系管理员更新密码':
    'Contraseña no actualizada desde hace demasiado tiempo, por favor, póngase en contacto con el administrador para actualizar su contraseña',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `cuenta ${x}`,
    请输入x帐号: (x: string) => `Ingrese su cuenta de ${x}`,
    '登录失败，帐号或密码不正确':
      'No se pudo iniciar sesión, la cuenta o la contraseña son incorrectas',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `Cuenta registrada de ${x}, si no tiene una cuenta de ${x}, inicie sesión con su cuenta de ChatPlusAI`,
  },
  验证码错误: 'Error de código de verificación',
};

export const vn: typeof cn = {
  ...StartupNewText.vn,
  'download.client.tips': 'Đã có tài khoản, vui lòng tải xuống ứng dụng khách hàng Chat ++',
  'reg.tips': 'Vui lòng đăng ký trước',
  'btn.for.reg': 'Đăng ký tài khoản',
  'btn.for.windows': 'Tải xuống cho Windows',
  'btn.for.mac': 'Tải xuống cho macOS',
  'has.download.tips': 'Tôi đã tải ứng dụng,',
  'has.download.btn': 'Mở ứng dụng,',
  required: 'Bắt buộc',
  'login.title': 'Chào mừng đến với ChatPlusAI',
  'login.title1': 'Đăng nhập bằng tài khoản và mật khẩu',
  'login.form': 'Đăng nhập',
  'login.form.typeAccountOrEmail': 'Tài khoản/Email',
  'login.form.typeMobile': 'Số điện thoại',
  'login.form.findPassword': 'Quên mật khẩu?',
  'login.form.go.register': 'Đăng ký tài khoản',
  'login.form.submit': 'Đăng nhập',
  'login.form.item.account.label': 'Tài khoản',
  'login.form.item.accountEmail.placeholder': 'Vui lòng nhập tài khoản/email',
  'login.form.item.mobile.placeholder': 'Vui lòng nhập số điện thoại',
  'login.form.item.password.label': 'Mật khẩu',
  'login.form.item.password.placeholder': 'Vui lòng nhập mật khẩu',
  'login.form.item.remember.label': 'Ghi nhớ mật khẩu',
  'login.form.platform.account.placeholder': 'Vui lòng nhập số tài khoản nền tảng',
  'login.form.platform.radio.erp': 'Tài khoản ERP Malacca',
  'login.form.platform.radio.chat': 'Tài khoản dịch vụ khách hàng ChatPlusAI',
  'login.form.platform.account.erp': 'Vui lòng nhập tài khoản Malacca ERP chính',
  'login.form.platform.account.chat': 'Vui lòng nhập tài khoản dịch vụ khách hàng ChatPlusAI',
  'login.form.platform.error':
    'Tài khoản hoặc mật khẩu bị sai, vui lòng chọn tài khoản dịch vụ khách hàng ChatPlusAI để đăng nhập tài khoản phụ dịch vụ khách hàng.',
  'login.form.success': 'Đăng nhập thành công',
  'registry.form.item.account.label': 'Số điện thoại',
  'registry.form.item.account.placeholder': 'Vui lòng nhập số điện thoại',
  'registry.form.item.account.validator.error': 'Vui lòng nhập số điện thoại hợp lệ',
  'registry.form.item.password.label': 'Cài đặt mật khẩu',
  'registry.form.item.find.password.label': 'Cài đặt mật khẩu',
  'registry.form.item.confirm.password.label': 'Xác nhận mật khẩu',
  'registry.form.item.password.placeholder': 'VUi lòng nhập mật khẩu',
  'registry.form.item.password.format.tip':
    'Mật khẩu phải có ít nhất 8 ký tự và phải chứa 1 chữ cái viết hoa / viết thường, 1 số và 1 ký tự đặc biệt (chẳng hạn như * , .)',
  'registry.form.item.company.format.tip':
    'Nếu bạn là người tự kinh doanh, bạn có thể tùy chỉnh đầu vào, làm chủ thể nhận dạng thân phận trong ChatPlusAI',
  'registry.form.item.company.admin.tip':
    'Tùy chỉnh tên tài khoản đầu vào làm tên người dùng cho lần đăng nhập tiếp theo vào ChatPlusAI',
  'registry.form.item.password.validator.error.required':
    'Vui lòng nhập mật khẩu 8-20 kí tự. Mật khẩu phải chứa 1 chữ cái viết hoa / viết thường, 1 số và 1 ký tự đặc biệt (chẳng hạn như * , .)',
  'registry.form.item.password.validator.error.length': 'Vui lòng nhập mật khẩu 8-20 kí tự',
  'registry.form.item.password.validator.error.invalid.charaters':
    'Mật khẩu không được chứa các ký tự ngoài số và chữ cái',
  'registry.form.item.password.validator.error.no.number': 'Mật khẩu chứa ít nhất một chữ số',
  'registry.form.item.password.validator.error.no.alphabet':
    'Mật khẩu chứa ít nhất một chữ cái viết hoa / viết thường',
  'registry.form.item.password.validator.error.no.special.characters':
    'Mật khẩu chứa ít nhất một ký tự đặc biệt (chẳng hạn như * , .)',
  'registry.form.item.password.validator.error.include.username':
    'Mật khẩu không được chứa tên người dùng',
  'startups.form.item.captcha.label': 'Mã xác nhận',
  'startups.form.item.captcha.send': 'Gửi mã xác nhận',
  'startups.form.item.captcha.send.again': 'Gửi lại',
  'startups.form.item.captcha.validator.error': 'Mã xác nhận không chính xác hoặc đã hết hạn',
  'startups.form.item.captcha.send.error': 'Không nhận được mã xác nhận, vui lòng thử lại sau',
  'startups.form.item.captcha.send.cooldown': 'Gửi lại sau vài giây',
  'registry.form.item.phone.occupied.error': 'Số điện thoại đã được đăng ký.',
  'registry.form.has.account': 'Đã có tài khoản, đăng nhập ngay',
  'registry.form.has.account.download': 'Đã có tài khoản, tải ứng dụng',
  register: 'Đăng kí',
  'registry.form.register.success': 'Đăng kí thành công!',
  'registry.form.register.failed': 'Đăng ký thất bại, vui lòng thử lại sau',
  'registry.form.register.step.1': 'Đăng kí tài khoản',
  'registry.form.register.step.2': 'Hoàn thiện thông tin',
  'registry.form.register.step.3': 'Hoàn thành đăng kí',
  'registry.form.register.step.next': 'Bước tiếp theo',
  'registry.form.perfect.info': 'Hoàn thiện thông tin',
  'registry.finish.registered': 'Đăng kí thành công',
  'registry.finish.registered.subTitle': 'Hãy khởi động ChatPlusAI và sử dụng nhé',
  'registry.finish.registered.subTitle2': 'Vui lòng tải xuống ứng dụng khách hàng ChatPlusAI',
  'registry.finish.go.login': 'Đi đăng nhập',
  'registry.finish.has.downloaded.client':
    'Nếu đã cài đặt ứng dụng ChatPlusAI, bạn có thể trực tiếp mở ứng dụng để sử dụng',
  'registry.finish.download.win': 'Tải xuống cho Windows',
  'registry.finish.download.mac': 'Tải xuống cho macOS',
  'registry.finish.download.has.client': 'Tôi đã tải ứng dụng,',
  'registry.finish.download.open.client': 'Mở ứng dụng',
  'registry.info.form.item.company.label': 'Tên công ty',
  'registry.info.form.item.company.placeholder':
    'Vui lòng nhập tên công ty, ví dụ "Cong ty TNHH xxx"',
  'registry.info.form.item.company.validator.error': 'Vui lòng nhập tên công ty',
  'registry.info.form.item.email.label': 'Địa chỉ email',
  'registry.info.form.item.email.placeholder': 'Vui lòng nhập địa chỉ email, ví dụ "xxxx@qq.com"',
  'registry.info.form.item.email.validator.error': 'Vui lòng nhập địa chỉ email chính xác',
  'registry.info.form.item.prefix.label': 'Tiền tố tài khoản',
  'registry.info.form.item.prefix.placeholder': 'Vui lòng nhập',
  'registry.info.form.item.prefix.validator.error': 'Vui lòng nhập 4-10 chữ cái',
  'registry.info.form.item.admin.label': 'Đăng nhập tài khoản',
  'registry.info.form.item.admin.placeholder':
    'Vui lòng nhập tên tài khoản đăng nhập có 6-16 kí tự, ví dụ "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'Tài khoản đăng nhập phải chứa các chữ cái tiếng Anh và không được chứa ký hiệu "@"',
  'registry.info.form.item.admin.validator.error.required': 'Tài khoản đăng nhập là bắt buộc',
  'registry.info.form.item.admin.validator.error.length':
    'Độ dài của tài khoản đăng nhập phải từ 1 đến 20 ký tự',
  'registry.info.form.error.admin.1020': 'Tên tài khoản đã được sử dụng, vui lòng sửa và gửi lại',
  'registry.info.form.error.email.1019': 'Email này đã được sử dụng, vui lòng sửa và gửi lại',
  'registry.info.form.error.company.1017': 'Tên công ty đã được sử dụng, vui lòng sửa và gửi lại',
  'registry.info.form.agreement.label': 'Tích chọn thể hiện đồng ý',
  'registry.info.form.invite.label': 'Mã mời',
  'registry.info.form.invite.placeholder': 'Vui lòng nhập mã mời (tùy chọn)',
  '、': '、',
  和: 'và',
  'Service.Agreement': 'Thỏa thuận dịch vụ',
  'Privacy.Policy': 'Chính sách bảo mật',
  'Software.License': 'Thoả thuận cấp phép phần mềm',
  'reset.title': 'Lấy lại mật khẩu',
  'reset.back.to.login': 'Quay lại đăng đăng nhập',
  'reset.back': 'Quay lại',
  'reset.prev.step': 'Trước',
  'reset.message.success': 'Đặt lại thành công',
  'reset.password.confirm.placeholder': 'Vui lòng nhập lại mật khẩu',
  'reset.form.password.placeholder':
    'Vui lòng nhập 8-20 kí tự, phải chứa các ký tự đặc biệt, chữ hoa, chữ thường, chữ số',
  'reset.form.password.validate.diff': 'Hai mật khẩu đã nhập không khớp',
  'reset.form.submit.btn': 'Gửi đi',
  'reset.unbound.text': 'Chưa liên kết số điện thoại',
  'reset.unbound.tips':
    'Chỉ hỗ trợ lấy lại mật khẩu của các tài khoản đã liên kết số điện thoại di động; nếu tài khoản phụ không liên kết số điện thoại di động, vui lòng liên hệ với quản trị viên để thay đổi mật khẩu hoặc liên kết số điện thoại',
  发送中: 'Đang gửi đi',
  // 2022年3月12日
  关于ChatPlusAI: 'Giới thiệu về ChatPlusAI',
  欢迎使用ChatPlusAI: 'Chào mừng đến với ChatPlusAI',
  登录中: 'Đang đăng nhập',
  'register.ChatPlusAI': 'Đăng ký ChatPlusAI',
  'register.form.become.admin.tips':
    'Bạn sẽ mặc định là quản trị viên cấp cao và có tất cả các đặc quyền của quản trị viên cấp cao',
  密码登录: 'Mật khẩu',
  验证码登录: 'Mã xác nhận',
  '服务器开小差了，请稍后再试': 'Xảy ra lỗi, vui lòng thử lại sau',
  '登录失败，验证码错误': 'Đăng nhập thất bại, lỗi mã xác nhận',
  '密码太久未更新，请联系管理员更新密码':
    'Mật khẩu đã lâu chưa cập nhật, vui lòng liên hệ quản trị viên để cập nhật mật khẩu',
  channelLogin: {
    ChatPlusAI: 'ChatPlusAI',
    x帐号: (x: string) => `Tài khoản ${x}`,
    请输入x帐号: (x: string) => `VUi lòng nhập tài khoản ${x}`,
    '登录失败，帐号或密码不正确': 'Đăng nhập thất bại, tài khoản hoặc mật khẩu không chính xác',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `Đăng ký tài khoản ${x}，nếu không có tài khoản ${x}，vui lòng dung tài khoản ChatPlusAI đăng nhập`,
  },
  验证码错误: 'Lỗi mã xác minh',
};

export const pt: typeof cn = {
  ...StartupNewText.pt,
  'download.client.tips': 'Você já tem uma conta, baixe o ChatPlusAI',
  'reg.tips': 'Por favor, registre-se primeiro',
  'btn.for.reg': 'Registrar conta',
  'btn.for.windows': 'Baixar a versão Windonws',
  'btn.for.mac': 'Baixar a versão macOS',
  'has.download.tips': 'Já instalei，',
  'has.download.btn': 'Abrir',
  required: 'Requerido',
  'login.title': 'Ben-vindo ao ChatPlusAI',
  'login.title1': 'Introduza a senha',
  'login.form': 'Iniciar',
  'login.form.typeAccountOrEmail': 'Conta/e-mail',
  'login.form.typeMobile': 'Número de telefone',
  'login.form.findPassword': 'Esqueceu-se da senha？',
  'login.form.go.register': 'Registrar a conta',
  'login.form.submit': 'Entrar',
  'login.form.item.account.label': 'Conta',
  'login.form.item.accountEmail.placeholder': 'Insira conta/e-mail',
  'login.form.item.mobile.placeholder': 'Insira número de telefone',
  'login.form.item.password.label': 'Senha',
  'login.form.item.password.placeholder': 'Insira a senha',
  'login.form.item.remember.label': 'Lembrar senha',
  'login.form.platform.account.placeholder': 'Insira a conta da plataforma',
  'login.form.platform.radio.erp': 'Conta ERP Malaca',
  'login.form.platform.radio.chat': 'Conta de ChatPlusAI',
  'login.form.platform.account.erp': 'Insira conta ERP Malaca',
  'login.form.platform.account.chat': 'Insira a conta de ChatPlusAI',
  'login.form.platform.error':
    'O número da conta ou a senha estão incorretos, selecione a subconta de atendimento ao cliente para fazer login.',
  'login.form.success': 'Login com sucesso',
  'registry.form.item.account.label': 'Número de telefone',
  'registry.form.item.account.placeholder': 'Insira número de telefone',
  'registry.form.item.account.validator.error': 'Insira número de telefone válido',
  'registry.form.item.password.label': 'Configurar senha',
  'registry.form.item.find.password.label': 'Configurar senha',
  'registry.form.item.confirm.password.label': 'Confirmar senha',
  'registry.form.item.password.placeholder': 'Inserir senha',
  'registry.form.item.password.format.tip':
    'A senha deve ter pelo menos 8 caracteres e deve conter 1 letra maiúscula/minúscula, 1 número e 1 caractere especial (como *, ., etc.)',
  'registry.form.item.company.format.tip':
    'Se você é autônomo, pode personalizar a entrada como assunto de identidade no ChatPlusAI',
  'registry.form.item.company.admin.tip':
    'Personalize o nome da conta de entrada como o nome de usuário para login subsequente ChatPlusAI',
  'registry.form.item.password.validator.error.required':
    'Digite uma senha de 8 a 20 dígitos. A senha deve conter 1 letra maiúscula/minúscula, 1 número e 1 caractere especial (como *, ., etc.)',
  'registry.form.item.password.validator.error.length': 'Digite a senha de 8 a 20 dígitos',
  'registry.form.item.password.validator.error.invalid.charaters':
    'A senha não pode conter caracteres além de números e letras',
  'registry.form.item.password.validator.error.no.number':
    'A senha contém pelo menos um número no dígito',
  'registry.form.item.password.validator.error.no.alphabet':
    'A senha contém pelo menos uma letra maiúscula/minúscula',
  'registry.form.item.password.validator.error.no.special.characters':
    'A senha contém pelo menos um caractere especial (como *, ., etc.)',
  'registry.form.item.password.validator.error.include.username':
    'A senha não pode conter nome de usuário',
  'startups.form.item.captcha.label': 'Código de verificação',
  'startups.form.item.captcha.send': 'Obter código de verificação',
  'startups.form.item.captcha.send.again': 'Reenviar',
  'startups.form.item.captcha.validator.error': 'O código de verificação está incorreto ou expirou',
  'startups.form.item.captcha.send.error':
    'Falha ao obter o código de verificação. Tente novamente mais tarde',
  'startups.form.item.captcha.send.cooldown': 'Reenviar em segundos',
  'registry.form.item.phone.occupied.error': 'O telefone já está registrado',
  'registry.form.has.account': 'Já tem uma conta, faça login',
  'registry.form.has.account.download': 'Já tem uma conta, baixe o app',
  register: 'Registro',
  'registry.form.register.success': 'Registro com sucesso!',
  'registry.form.register.failed': 'Falha no registro, tente novamente mais tarde',
  'registry.form.register.step.1': 'Registar Conta',
  'registry.form.register.step.2': 'Completar a informção',
  'registry.form.register.step.3': 'Finalizar o registro',
  'registry.form.register.step.next': 'Próximo',
  'registry.form.perfect.info': 'Completar a informção',
  'registry.finish.registered': 'Registro com sucesso',
  'registry.finish.registered.subTitle':
    'Em seguida, ative a função de ChatPlusAI de música e use-a',
  'registry.finish.registered.subTitle2': 'Faça o download do cliente ChatPlusAI primeiro',
  'registry.finish.go.login': 'Login',
  'registry.finish.has.downloaded.client':
    'Se você instalou o ChatPlusAI , você pode abrir diretamente para usar',
  'registry.finish.download.win': 'Instalar versão Windows',
  'registry.finish.download.mac': 'Instalar versão macOS',
  'registry.finish.download.has.client': 'Já instalei',
  'registry.finish.download.open.client': 'Abrir',
  'registry.info.form.item.company.label': 'Nome da empresa',
  'registry.info.form.item.company.placeholder': 'Insira o nome da empresa, como "xxx Co., Ltd.”',
  'registry.info.form.item.company.validator.error': 'Insira nome da empresa',
  'registry.info.form.item.email.label': 'E-mail',
  'registry.info.form.item.email.placeholder': 'Digite seu endereço de e-mail, como "xxxx@qq.com”',
  'registry.info.form.item.email.validator.error': 'Por favor, insira o endereço de e-mail correto',
  'registry.info.form.item.prefix.label': 'Prefixo da conta',
  'registry.info.form.item.prefix.placeholder': 'Por favor, insira',
  'registry.info.form.item.prefix.validator.error': 'Insira de 4 a 10 letras',
  'registry.info.form.item.admin.label': 'Login da conta',
  'registry.info.form.item.admin.placeholder':
    'Insira o nome da conta de login com 6 a 16 dígitos, como "xiaoming"',
  'registry.info.form.item.admin.validator.error.format':
    'A conta de login deve conter letras em inglês e não pode conter o símbolo "@"',
  'registry.info.form.item.admin.validator.error.required': 'A conta de login é necessária',
  'registry.info.form.item.admin.validator.error.length':
    'O comprimento do número da conta de login deve ter entre 1 e 20 caracteres',
  'registry.info.form.error.admin.1020': 'O nome da conta já foi usado, modifique e envie',
  'registry.info.form.error.email.1019': 'Este e-mail já foi usado, envie após a modificação',
  'registry.info.form.error.company.1017': 'O nome da empresa foi usado, modifique e envie',
  'registry.info.form.agreement.label': 'Ao selecionar ​​é considerado que aceita tudo',
  'registry.info.form.invite.label': 'Código de Convite',
  'registry.info.form.invite.placeholder': 'Insira o código de convite (opcional)',
  '、': '、',
  和: 'e',
  'Service.Agreement': 'Acordo de serviço',
  'Privacy.Policy': 'Política de Privacidade',
  'Software.License': 'Contrato de Licença de Software',
  'reset.title': 'Recuperar a senha',
  'reset.back.to.login': 'Voltar ao login',
  'reset.back': 'Voltar',
  'reset.prev.step': 'Anterior',
  'reset.message.success': 'Configuração com sucesso',
  'reset.password.confirm.placeholder': 'Por favor, digite a senha novamente',
  'reset.form.password.placeholder':
    'Insira de 8 a 20 dígitos, deve conter símbolos alfanuméricos maiúsculos e minúsculos',
  'reset.form.password.validate.diff': 'As duas senhas inseridas não correspondem',
  'reset.form.submit.btn': 'Submeter',
  'reset.unbound.text': 'Número de celular não vinculado',
  'reset.unbound.tips':
    'Suporta apenas a recuperação de senha de contas com um número de celular vinculado; se a subconta não estiver vinculada a um número de celular, entre em contato com o administrador para alterar a senha ou vincular o número de celular',
  发送中: '发送中',
  // 2022年3月12日
  关于ChatPlusAI: 'Sobre ChatPlusAI',
  欢迎使用ChatPlusAI: 'Bem-vindo ao usar ChatPlusAI',
  登录中: 'Login',
  'register.ChatPlusAI': 'Inscreva-se no ChatPlusAI',
  'register.form.become.admin.tips':
    'Você será um superadministrador por padrão e terá todos os privilégios de superadministrador',
  密码登录: 'Senha de login',
  验证码登录: 'Código de verificação de login',
  '服务器开小差了，请稍后再试': 'Erro de servidor, tente mais tarde',
  '登录失败，验证码错误': 'Falha no login, conta ou senha incorreta',
  '密码太久未更新，请联系管理员更新密码':
    'A senha não é atualizada há muito tempo, entre em contato com o administrador para atualizar a senha',
  channelLogin: {
    ChatPlusAI: 'LeChatPlusAI',
    x帐号: (x: string) => `${x} Conta`,
    请输入x帐号: (x: string) => `Insira a conta ${x}`,
    '登录失败，帐号或密码不正确': 'Falha no login, conta ou senha incorreta',
    'x 的注册帐号，如无 x 帐号，请使用ChatPlusAI帐号登录': (x: string) =>
      `conta ${x} registrada, se você não tiver uma conta ${x}, faça login com sua conta do ChatPlusAI`,
  },
  验证码错误: 'Erro no código de verificação',
};
