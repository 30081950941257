export default {
  取消: {
    'zh-CN': '取消',
    'en-US': 'Cancel',
    vi: 'Hủy',
    id: 'Batal',
    th: 'ยกเลิก',
    tl: 'Cancel',
    es: 'Cancelar',
    pt: 'Cancelar',
  },
  保存: {
    'zh-CN': '保存',
    'en-US': 'Save',
    vi: 'Lưu',
    id: 'Simpan',
    th: 'บันทึก',
    tl: 'Save',
    es: 'Guardar',
    pt: 'Guardar',
  },
  确定: {
    'zh-CN': '确定',
    'en-US': 'Confirm',
    vi: 'Xác nhận',
    id: 'OK',
    th: 'แน่นอน',
    tl: 'Oo naman',
    es: 'Confirmar',
    pt: 'Confirmar',
  },
  查看: {
    'zh-CN': '查看',
    'en-US': 'View',
    vi: 'Xem',
    id: 'Lihat',
    th: 'ตรวจสอบ',
    tl: 'View',
    es: 'Ver',
    pt: 'Ver',
  },
  编辑: {
    'zh-CN': '编辑',
    'en-US': 'Edit',
    vi: 'Chỉnh sửa',
    id: 'Edit',
    th: 'แก้ไข',
    tl: 'Edit',
    es: 'Editar',
    pt: 'Editar',
  },
  删除: {
    'zh-CN': '删除',
    'en-US': 'Delete',
    vi: 'Xóa',
    id: 'Hapus',
    th: 'ลบ',
    tl: 'Delete',
    es: 'Eliminar',
    pt: 'Excluir',
  },
};
