import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { en, cn, id, th, tl, es, vn as vi, pt } from './resources';
import { cn as ViewStartupsCn, en as ViewStartupsEn } from 'views/Startups/Startups.i18n';
import {
  cn as ViewProductContentGeneratorCn,
  en as ViewProductContentGeneratorEn,
} from 'views/ProductContentGenerator/i18n';
import { cn as assistantCn, en as assistantEn } from 'views/Assistant/i18n';
import { cn as defaultTransCn, en as defaultTransEn } from './resources/defaults';
import { cn as antdCn, en as antdEn } from './namespace/antd';
import { cn as regionCn, en as regionEN } from './resources/defines/region';
import { cn as subscribeCn, en as subscribeEn } from './resources/defines/subscribe.modal';
import { cn as phoneCountryCn, en as phoneCountryEn } from './resources/defines/phoneCountry';
import { cn as apiCn, en as apiEn } from './namespace/api';
import tolgeeCn from './tolgee/zh.json';
import tolgeeEn from './tolgee/en.json';

export const resources = {
  'en-US': {
    translation: {
      ...tolgeeEn,
      ...defaultTransEn,
      antd: antdEn,
      region: regionEN,
      subscribe: subscribeEn,
      phoneCountry: phoneCountryEn,
      api: apiEn,
      assistant: assistantEn,
    },
    ViewStartups: ViewStartupsEn,
    ViewProductContentGenerator: ViewProductContentGeneratorEn,
  },
  'zh-CN': {
    translation: {
      ...tolgeeCn,
      ...defaultTransCn,
      antd: antdCn,
      region: regionCn,
      subscribe: subscribeCn,
      phoneCountry: phoneCountryCn,
      api: apiCn,
      assistant: assistantCn,
    },
    ViewStartups: ViewStartupsCn,
    ViewProductContentGenerator: ViewProductContentGeneratorCn,
  },
};

export const ns = Object.keys(resources['zh-CN']);

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    // 语言码 统一使用 i18n 标准语言定义
    resources,
    lng: 'en-US',
    fallbackLng: 'en-US',
    ns,
    defaultNS: 'translation',
  });
};
initI18n();
export default i18n;
