const pay = {
  选择币种: {
    'zh-CN': '选择币种',
    'en-US': 'Select currency',
    es: 'Seleccionar moneda',
    id: 'Pilih mata uang',
    pt: 'Selecionar moeda',
    tl: 'Select currency',
    vi: 'Chọn loại tiền',
    th: 'เลือกสกุลเงิน',
  },
  支付: {
    'zh-CN': '支付',
    'en-US': 'Pay',
    es: 'Pagar',
    id: 'Bayar',
    pt: 'Pagar',
    tl: 'Pay',
    vi: 'Thanh toán',
    th: 'Pay',
  },
  app扫码支付: {
    'zh-CN': '{{app}}扫码支付',
    'en-US': 'Scan {{app}} code to pay',
    es: 'Escanee el código {{app}} para pagar',
    id: 'Scan {{app}} untuk bayar',
    pt: 'AliDigitalizar o código {{app}} para pagarpay',
    tl: 'Scan {{app}} code to pay',
    vi: 'Quét mã {{app}} để thanh toán',
    th: 'สแกนคิวอาร์โค้ด{{app}}ชำระเงิน',
  },
  支付成功: {
    'zh-CN': '支付成功',
    'en-US': 'Payment successful',
    es: 'Pago realizado con éxito',
    id: 'Pembayaran berhasil',
    pt: 'Pagamento com sucesso',
    tl: 'Payment successful',
    vi: 'Thanh toán thành công',
    th: 'ชำระเงินสำเร็จ',
  },
};

export default pay;
