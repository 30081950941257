export const cn = {
  howToUse: '使用方法',
  contactUs: '联系我们',
  quit: '退出',
  inputTitle: '输入',
  inputLang: '输出语言',
  productType: '商品品类',
  productBrand: '商品品牌',
  productPoint: '商品卖点',
  productListing: '现有 Listing 内容',
  productTitle: '商品标题',
  productDetail: '商品详情',
  productTitleOption1: '5～255 字符',
  productTitleOption2: '不含特殊符号、链接',
  productTitleOption3: '不能全部用大写字母',
  productTitleOption4: '单词首字母大写（符合英文书写惯例）',
  productTitleOption5: '不含标点符号',
  productTitleOption6: '5～188 字符',
  productDetailOption1: `字符 < 1000`,
  productDetailOption2: `字符 < 3000`,
  productDetailOption3: `不能使用特殊符号和链接`,
  productDetailOption4: `不能使用表情`,
  productDetailOption5: '33～999 字符',
  generateTitleBtn: '生成标题',
  generateDetailBtn: '生成商品详情',
  generateAllBtn: '生成标题和商品详情',
  generateTitleAgainBtn: '再次生成标题',
  generateDetailAgainBtn: '再次生成商品详情',
  generateAllAgainBtn: '再次生成标题和商品详情',
  clearAllBtn: '清空',
  outputTitle: '输出',
  copyBtn: '复制内容',
  copyTransBtn: '复制译文',
  translateBtn: '翻译',
  highlightInfo: '突出信息',
  weakInfo: '弱化信息',
  inputPlaceholder: '请输入',
  generateTitleErrorMsg: '生成标题失败',
  generateDetailErrorMsg: '生成商品详情失败',
  copySuccess: '已复制',
  copyFail: '复制失败',
  pageTitle: '自动生成商品标题、商品详情内容',
  inputWarning: '商品卖点 或 现有Listing内容，必须填写其中一项，两项都填写效果更佳',
  slogan: '出海电商 AI 内容智造机',
  tryExpiredTips: '当前试用已到期\n微信扫码联系我们加入VIP',
  expiredTips: '当前套餐已到期\n微信扫码联系我们续订VIP',
  langZh: '中文',
  langEn: '英语',
  langId: '印尼语',
  langPh: '菲律宾语',
  langTh: '泰语',
  langVn: '越南语',
  langPt: '葡萄牙语',
  langEs: '西班牙语',
  mobileTips: `在电脑浏览器使用本产品效果更佳`,
  mobileTips1: '点击此处复制网址',
  pcWeb: '网址：',
  titleLabel: '商品标题限制条件',
  detailLabel: '商品详情限制条件',
  loadingTips: '生成中，请稍后',
  generateAgainLabel: '再次调优',
  generateAllErrorMsg: '生成标题和商品详情失败',
  translateErrorMsg: '翻译失败',
  learnMore: '了解更多功能',
  freeQuotaTips: '今日体验额度已耗尽，免费注册ChatPlusAI进入无限额模式',
  freeQuotaBtn: '去注册',
  platform: '平台',
  defaultPlatform: '通用',
  shortDescription: '简短描述',
  longDescription: '详细描述',
  sellingPointDescription: '商品卖点',
  charLimitLabel: '字符限制',
  clickToModify: '点击修改',
  videoPlayError: '视频播放失败',
  timeoutTips: '当前使用人数较多，请稍后重试',
  sellingPointPlaceholder: '请输入商品的主要卖点，比如：防水、控油持妆、提亮肤色',
  listingPlaceholder: '您可以把当前商品已有的Listing内容复制到这里',
  okText: '知道了',
  emptyText1: '请在左侧页面输入你的内容诉求',
  emptyText2: '即刻生成专属优质内容',
  reGenerateBtn: '重新生成',
  titlePageTitle: '候选标题',
  detailPageTitle: '候选详情',
  '商品品类/核心搜索词': '商品品类/核心搜索词',
  请输入商品品类或核心搜索词: '请输入商品品类或核心搜索词',
  选择站点: '选择站点',
  请选择站点: '请选择站点',
  提交生成: '提交生成',
  请在左侧页面输入你的热词诉求: '请在左侧页面输入你的热词诉求',
  AI会根据热度排名推荐热词: 'AI会根据热度排名推荐热词',
  热词: '热词',
  翻译: '翻译',
  平台近30日热度: '平台近30日热度',
  已选x: '已选 {{x}}',
  取消: '取消',
  确定: '确定',
  自动生成热词: '自动生成热词',
  禁用词: '禁用词',
  最多可输入200个禁用词: '最多可输入200个禁用词',
  '请输入禁用词，按enter添加': '请输入禁用词，比如：Free gifts，按enter添加',
  '无热词？试试自动生成热词': '无热词？试试自动生成热词',
  最多可输入10个热词: '最多可输入10个热词',
  '请输入热词，按enter添加': '请输入热词，比如：Sunscreen，按enter添加',
  热词语言与输出语言保持一致: '热词语言与输出语言保持一致',
  创作目录: '创作目录',
  访问ChatPlusAI官网: '访问ChatPlusAI官网',
  注册后获取无限额度: '注册后获取无限额度',
  去注册: '去注册',
  登录: '登录',
  退出: '退出',
  '请输入希望出现在标题中的词语，如：Sunscreen，按enter添加':
    '请输入希望出现在标题中的词语，如：Sunscreen，按enter添加',
  '请在此输入禁止出现在标题的词语，如：Free gifts，按enter添加':
    '请在此输入禁止出现在标题的词语，如：Free gifts，按enter添加',
  '请输入希望出现在商品详情中的词语，如：Sunscreen，按enter键添加':
    '请输入希望出现在商品详情中的词语，如：Sunscreen，按enter键添加',
  '请在此输入禁止出现在商品详情中的词语，如：Free gifts，按enter键添加':
    '请在此输入禁止出现在商品详情中的词语，如：Free gifts，按enter键添加',
  limitCondition: '限制条件',
  clearData: '清空录入',
  generateNow: '立即生成',
  generateAgain: '继续生成',
  hotWordsLabel: '埋词情况',
  limitCharacter: '限制<0></0>字符以内',
  generateCount: '生成<0></0>条',
  reGenerateTips: '根据左侧输入的内容重新生成此标题',
  clearConfirmTitle: '确认要清空录入的字段吗？',
  clearConfirmContent: '清空后不可恢复',
  clearSuccessMsg: '已清空',
  一键翻译: '一键翻译',
  自动检测: '自动检测',
  字符超出限制: '字符超出限制',
  '翻译中，请稍后': '翻译中，请稍后',
  translateAlert: '大模型地道翻译+电商场景优化，更适合电商人的翻译工具！ 现在就收藏本页吧^_^',
  translateTips: '用ChatPlusAI，自动翻译更高效，无需手动复制到翻译软件，无惧小语种接待难题！',
  translateTips1: '电商领域翻译更精准',
  translateTips2: '翻译延时低',
  translateTips3: '无需科学上网',
  免费注册: '免费注册',
  下载客户端: '下载客户端',
  未查询到符合条件的热词: '未查询到符合条件的热词',
  请修改后再次提交生成: '请修改后再次提交生成',
  翻译效果对比: '翻译效果对比',
  G家翻译: 'G家翻译',
  GPT翻译: 'GPT翻译',
};

export const en = {
  howToUse: 'How To Use',
  contactUs: 'Contact Us',
  quit: 'Quit',
  inputTitle: 'Input',
  inputLang: 'Output Language',
  productType: 'Product Category',
  productBrand: 'Brand',
  productPoint: 'Selling points',
  productListing: 'Existing listing description',
  productTitle: 'Product Title',
  productDetail: 'Product Details',
  productTitleOption1: '5 ~ 255 character',
  productTitleOption2: 'Without special characters or links',
  productTitleOption3: 'Not entirely written in capital letters',
  productTitleOption4:
    'Capitalize the first letter of each word (following English writing conventions)',
  productTitleOption5: 'Without punctuation marks',
  productTitleOption6: '5 ~ 188 character',
  productDetailOption1: `Character count < 1000`,
  productDetailOption2: `Character count < 3000`,
  productDetailOption3: `Without special characters or links`,
  productDetailOption4: `Do not use emoticons`,
  productDetailOption5: '33 ~ 999 character',
  generateTitleBtn: 'Generate a title',
  generateDetailBtn: 'Generate product details',
  generateAllBtn: 'Generate title&product details',
  generateTitleAgainBtn: 'Generate a title again',
  generateDetailAgainBtn: 'Generate product details again',
  generateAllAgainBtn: 'Generate title&product details again',
  clearAllBtn: 'Clear',
  outputTitle: 'Output',
  copyBtn: 'Copy',
  copyTransBtn: 'Copy translated content',
  translateBtn: 'Translate',
  highlightInfo: 'Emphasized content',
  weakInfo: 'Faded content',
  inputPlaceholder: 'Please input',
  generateTitleErrorMsg: 'Failed to generate title',
  generateDetailErrorMsg: 'Failed to generate product details',
  copySuccess: 'Copied',
  copyFail: 'Failed to copy',
  pageTitle: 'Listing Title and Description Generator',
  inputWarning:
    'Product Selling points or Existing listing description must be filled in. It is recommended to fill in both for better performance',
  slogan: 'Global e-commerce AI content generator',
  tryExpiredTips: 'Trial has expired\nScan the QR code on WeChat to contact us and join VIP.',
  expiredTips:
    'The current package has expired. \n Scan the QR code on WeChat to contact us and renew your VIP subscription.',
  langZh: 'Chinese',
  langEn: 'English',
  langId: 'Indonesian',
  langPh: 'Filipino',
  langTh: 'ภาษาไทย',
  langVn: 'Tiếng Việt',
  langPt: 'Português',
  langEs: 'Español',
  mobileTips: `It will work better when use on a computer browser.`,
  mobileTips1: 'Click to copy link',
  pcWeb: 'Access Link: ',
  titleLabel: 'Limitations on product titles',
  detailLabel: 'Limitations on product details',
  loadingTips: 'Generating, please wait.',
  generateAgainLabel: 'Optimize again',
  generateAllErrorMsg: 'Generation of all failed',
  translateErrorMsg: 'Failed to translate',
  learnMore: 'Learn More',
  freeQuotaTips:
    'The daily experience limit has been reached. Register for free to get unlimited quota.',
  freeQuotaBtn: 'Register for free',
  platform: 'Platform',
  defaultPlatform: 'General',
  shortDescription: 'Short Description',
  longDescription: 'Long Description',
  sellingPointDescription: 'Bullet Points',
  charLimitLabel: 'Character',
  clickToModify: 'Edit',
  videoPlayError: 'Failed to play video',
  timeoutTips: 'There are too many visitors, please try again later',
  sellingPointPlaceholder:
    'Fill in the main selling points of the product, such as waterproof, oil control and long-lasting makeup, and skin brightening',
  listingPlaceholder: 'You can copy the existing listing content of the current product here',
  okText: 'OK',
  emptyText1: 'Please input your content requirement on the left page.',
  emptyText2: 'Generate high-quality content immediately.',
  reGenerateBtn: 'Regenerate',
  titlePageTitle: 'Candidate titles.',
  detailPageTitle: 'Candidate details',
  '商品品类/核心搜索词': 'Product category/keywords',
  请输入商品品类或核心搜索词: 'Please enter the product category or keywords',
  选择站点: 'Choose Country site',
  请选择站点: 'Please choose Country site',
  提交生成: 'Submit&Generate',
  请在左侧页面输入你的热词诉求: 'Please enter your hot words on the left-hand side of the page.',
  AI会根据热度排名推荐热词: 'AI will recommend hot words based on their popularity ranking.',
  热词: 'Hot words',
  翻译: 'Translation',
  平台近30日热度: '30-day popularity of the marketplace',
  已选x: 'Selected {{x}}',
  取消: 'Cancel',
  确定: 'OK',
  自动生成热词: 'Automatically generate hot words',
  禁用词: 'Banned words',
  最多可输入200个禁用词: 'Up to 200 banned words can be entered.',
  '请输入禁用词，按enter添加':
    'Please enter banned words, such as "Free gifts", and press Enter to add.',
  '无热词？试试自动生成热词': 'No hot words? Try auto-generating them.',
  最多可输入10个热词: 'Up to 10 hot words can be entered.',
  '请输入热词，按enter添加':
    'Please enter banned words, such as "Sunscreen", and press Enter to add.',
  热词语言与输出语言保持一致:
    'The hot word language should be consistent with the output language.',
  创作目录: 'Table of contents',
  访问ChatPlusAI官网: 'Visit the official website of ChatPlusAI',
  注册后获取无限额度: 'Get unlimited quota after registering.',
  去注册: 'Register for free',
  登录: 'Login',
  退出: 'LogOut',
  '请输入希望出现在标题中的词语，如：Sunscreen，按enter添加':
    'Please enter the keywords you wish to appear in the title, such as "Sunscreen," and press enter to add.',
  '请在此输入禁止出现在标题的词语，如：Free gifts，按enter添加':
    'Please enter the words that are not allowed to appear in the title, such as "Free gifts", and press enter to add.',
  '请输入希望出现在商品详情中的词语，如：Sunscreen，按enter键添加':
    'Please enter the keywords you wish to appear in Product Detail, such as "Sunscreen," and press enter to add.',
  '请在此输入禁止出现在商品详情中的词语，如：Free gifts，按enter键添加':
    'Please enter the words that are not allowed to appear in Product Detail, such as "Free gifts", and press enter to add.',
  limitCondition: 'Limitation',
  clearData: 'Clear',
  generateNow: 'Generate',
  generateAgain: 'Regenerate',
  hotWordsLabel: 'The situation of buried words',
  limitCharacter: 'Can not be greater than <0></0> characters',
  generateCount: 'Generate <0></0> entries',
  reGenerateTips: 'Regenerate Title based on the content entered on the left.',
  clearConfirmTitle: 'Are you sure you want to clear the entered?',
  clearConfirmContent: 'It will not be recoverable after clearing.',
  clearSuccessMsg: 'Cleared',
  一键翻译: 'Click to translate',
  自动检测: 'Automatic detection',
  字符超出限制: 'Character limit exceeded',
  '翻译中，请稍后': 'Translation in progress, please wait',
  translateAlert:
    'Authentic translation with a large model and optimized for e-commerce scenarios, a translation tool that is more suitable for e-commerce professionals! Bookmark this page now^^',
  translateTips: `With ChatPlusAI, automatic translation is more efficient. There's no need to manually copy text to translation software, and there's no need to worry about difficulties in serving customers who speak foreign languages!`,
  translateTips1: 'More accurate translations for the e-commerce sector',
  translateTips2: 'Low translation latency',
  translateTips3: 'No VPN required',
  免费注册: 'Free registration',
  下载客户端: 'Download the client',
  未查询到符合条件的热词: 'No eligible hot words found.',
  请修改后再次提交生成: 'Please modify the product category/keywords and resubmit for generating.',
  翻译效果对比: 'Translation effect comparison',
  G家翻译: 'Translation by G company',
  GPT翻译: 'Translation by GPT',
};

export const tl = en;
export const id = en;
export const th = en;
export const vn = en;
export const es = en;
export const pt = en;
