export const cn = {
  cn: '中国',
  tw: '中国台湾',
  hk: '中国香港',
  mo: '中国澳门',
  my: '马来西亚',
  id: '印度尼西亚',
  th: '泰国',
  ph: '菲律宾',
  sg: '新加坡',
  vn: '越南',
  br: '巴西',
  mx: '墨西哥',
  cl: '智利',
  ar: '阿根廷',
  co: '哥伦比亚',
  es: '西班牙',
  fr: '法国',
  pl: '波兰',
};

export const en = {
  cn: 'China',
  tw: 'China Taiwan',
  hk: 'China Hong Kong',
  mo: 'China Macao',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Philippine',
  sg: 'Singapore',
  vn: 'Vietnam',
  br: 'Brazil',
  mx: 'Mexico',
  cl: 'Chile',
  ar: 'Argentina',
  co: 'Colombia',
  es: 'Spain',
  fr: 'France',
  pl: 'Poland',
};

export const id = {
  cn: 'Tiongkok',
  tw: 'Taiwan, Tiongkok',
  hk: 'Hong Kong, Tiongkok',
  mo: 'Makau, Tiongkok',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Filipina',
  sg: 'Singapura',
  vn: 'Vietnam',
  br: 'Brazil',
  mx: 'Meksiko',
  cl: 'Chili',
  ar: 'Argentina',
  co: 'Kolumbia',
  es: 'Spanyol',
  fr: 'Perancis',
  pl: 'Polandia',
};

export const th = {
  cn: 'จีน',
  tw: 'ไต้หวัน, จีน',
  hk: 'ฮ่องกง, จีน',
  mo: 'มาเก๊า, จีน',
  my: 'มาเลเซีย',
  id: 'อินโดนีเซีย',
  th: 'ไทย',
  ph: 'ฟิลิปปินส์',
  sg: 'สิงคโปร์',
  vn: 'เวียดนาม',
  br: 'บราซิล',
  mx: 'เม็กซิโก',
  cl: 'ชิลี',
  ar: 'อาร์เจนตินา',
  co: 'โคลอมเบีย',
  es: 'สเปน',
  fr: 'ฝรั่งเศส',
  pl: 'โปแลนด์',
};

export const tl = {
  cn: 'China',
  tw: 'Taiwan, China',
  hk: 'Hong Kong, China',
  mo: 'Macao, China',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Philippines',
  sg: 'Singapore',
  vn: 'Vietnam',
  br: 'Brazil',
  mx: 'Mexico',
  cl: 'Chile',
  ar: 'Argentina',
  co: 'Colombia',
  es: 'Spain',
  fr: 'France',
  pl: 'Poland',
};

export const es = {
  cn: 'China',
  tw: 'Taiwán, China',
  hk: 'Hongkong, China',
  mo: 'Macao, China',
  my: 'Malasia',
  id: 'Indonesia',
  th: 'Tailandia',
  ph: 'Filipinas',
  sg: 'Singapur',
  vn: 'Vietnam',
  br: 'Brasil',
  mx: 'México',
  cl: 'Chile',
  ar: 'Argentina',
  co: 'Colombia',
  es: 'España',
  fr: 'Francia',
  pl: 'Polonia',
};

export const vn = {
  cn: 'Trung Quốc',
  tw: 'Đài Loan',
  hk: 'Hồng Kông',
  mo: 'Ma Cao',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thái Lan',
  ph: 'Philippines',
  sg: 'Singapore',
  vn: 'Việt Nam',
  br: 'Brazil',
  mx: 'Mexico',
  cl: 'Chile',
  ar: 'Argentina',
  co: 'Colombia',
  es: 'Tây Ban Nha',
  fr: 'Pháp',
  pl: 'Ba Lan',
};

export const pt = {
  cn: 'China',
  tw: 'Taiwan, China',
  hk: 'Hongkong, China',
  mo: 'Macao, China',
  my: 'Malásia',
  id: 'Indonésia',
  th: 'Tailândia',
  ph: 'Filipinas',
  sg: 'Singapura',
  vn: 'Vietnã',
  br: 'Brasil',
  mx: 'México',
  cl: 'Chile',
  ar: 'Argentina',
  co: 'Colômbia',
  es: 'Espanha',
  fr: 'França',
  pl: 'Polónia',
};
