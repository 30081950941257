export const cn = {
  操作: '操作',
  充值货币: '充值货币',
  订单金额: '订单金额',
  订单编号: '订单编号',
  订单状态: '订单状态',
};
export const en = {
  操作: 'Action',
  充值货币: 'Currency',
  订单金额: 'Total',
  订单编号: 'No.',
  订单状态: 'Order status',
};
export const id = {
  充值货币: 'Mata uang',
  操作: 'Aksi',
  订单金额: 'Nominal Pesanan',
  订单编号: 'Nomor pesanan',
  订单状态: 'Status pesanan',
};
export const th = {
  充值货币: 'สกุลเงิน',
  操作: 'ปฏิบัติการ',
  订单金额: 'ยอดสั่งซื้อ',
  订单编号: 'หมายเลขคำสั่งซื้อ',
  订单状态: 'สถานะคำสั่งซื้อ',
};
export const tl = {
  充值货币: 'Currency',
  操作: 'Action',
  订单金额: 'Total',
  订单编号: 'No.',
  订单状态: 'Order status',
};
export const es = {
  充值货币: 'Moneda',
  操作: 'Operación',
  订单金额: 'Importe del pedido',
  订单编号: 'No',
  订单状态: 'Estado del pedido',
};

export const vn = {
  充值货币: 'Loại tiền nạp',
  操作: 'Thao tác',
  订单金额: 'Thành tiền đơn hàng',
  订单编号: 'Mã hiệu đơn hàng',
  订单状态: 'Trạng thái đơn hàng',
};

export const pt = {
  充值货币: 'Currency',
  操作: 'Operação',
  订单金额: 'Valor do pedido',
  订单编号: 'Número de pedido',
  订单状态: 'Estado de pedido',
};
