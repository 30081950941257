export const cn = {
  请选择要查看的x: '请选择要查看的{{x}}',
};
export const en = {
  请选择要查看的x: '请选择要查看的{{x}}',
};
export const id = {
  请选择要查看的x: '请选择要查看的{{x}}',
};
export const th = {
  请选择要查看的x: '请选择要查看的{{x}}',
};
export const tl = {
  请选择要查看的x: '请选择要查看的{{x}}',
};
export const es = {
  请选择要查看的x: '请选择要查看的{{x}}',
};

export const vn = {
  请选择要查看的x: '请选择要查看的{{x}}',
};

export const pt = {
  请选择要查看的x: 'Selecione a {{x}} que deseja ver',
};
