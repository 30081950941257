import styled from '@emotion/styled/macro';

const Description = styled.div`
  width: 540px;
  > h3 {
    font-size: 16px;
  }
  > div {
    p {
      margin: 0;
      font-size: 14px;
      span {
        color: #336fff;
      }
    }
  }
`;

const Tips = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 20px 20px 5px 20px;
  background: #f0f4ff;
  > div {
    flex: 1 0 0;
    > p {
      margin-bottom: 15px;
    }
  }
`;

const Highlight = styled.span`
  color: #336fff;
`;

export const cn = {
  nav: {
    account: '帐号管理',
    group: '客服组管理',
    department: '部门管理',
  },
  account: {
    fetchFailed: '获取帐号列表失败，请稍后再试',
    fetchSeatFailed: '获取坐席信息失败，请稍后再试',
    createAccount: '新增客服',
    showTotal: (total: number | string) => `共${total}项`,
    updateStatusSucceed: '客服状态保存成功',
    updateStatusFailed: '客服状态保存失败，请稍后再试',
    deleteSucceed: '删除客服成功',
    deleteFailed: '删除客服失败，请稍后再试',
    createAndCopy: '创建帐号并复制',
    createSucceed: '新增帐号成功',
    updateSucceed: '编辑帐号成功',
    createFailed: '新增帐号失败，请稍后再试',
    updateFailed: '编辑帐号失败，请稍后再试',
    copyAccountSucceed: '帐号信息已复制',
    copyAccountFailed: '复制帐号信息失败 请稍后再试',
    updateNickSucceed: '修改昵称成功',
    updateNickFailed: '修改昵称失败，请稍后再试',
    updatePasswordSucceed: '修改密码成功',
    updatePasswordFailed: '修改密码失败，请稍后再试',
    validate: {
      accountEmpty: '请输入帐号',
      accountLimit: (limit: number) => `帐号不得超过${limit}个字`,
      accountReg: '帐号必须包含字母，且不可包含字符：@',
      nickEmpty: '请输入昵称',
      assistantGroups: '请选择客服组',
      nickLimit: (limit: number) => `昵称不得超过${limit}个字`,
      passwordEmpty: '请输入密码',
      passwordLimit: (minLimit: number, maxLimit: number) => `请输入${minLimit}-${maxLimit}位密码`,
      oldPasswordEmpty: '请输入旧密码',
      newPasswordEmpty: '请输入新密码',
      confirmPasswordEmpty: '请输入二次确认密码',
      confirmPassword: '二次确认与新密码不一致',
      phoneEmpty: '请输入手机号码',
      phoneInvalid: '请输入正确的手机号码',
      emailInvalid: '请输入合法邮箱',
      passwordNumber: '密码中至少包含一位数字',
      passwordLetter: '密码中至少包含一位大/小写字母',
      passwordSpecial: '密码中至少包含一位特殊字符 (如*、.等)',
      passwordAccount: '密码中不可包含用户名',
    },
    field: {
      account: '登录帐号',
      name: '昵称',
      password: '登录密码',
      oldPassword: '旧密码',
      newPassword: '新密码',
      confirmPassword: '确认登录密码',
      index: '序号',
      phone: '手机号码',
      email: '登录邮箱',
    },
    placeholder: {
      account: '6-16个字符，仅可设置一次',
      name: '请输入昵称',
      password: '请输入密码',
      oldPassword: '请输入旧密码',
      newPassword: '请输入新密码',
      confirmPassword: '再次输入新密码',
      phone: '请输入手机号码',
      email: '请输入邮箱地址',
      group: '请选择客服组',
    },
    role: {
      ASSISTANT: '客服',
      SUPER_ADMIN: '管理员',
    },
    save: '确定',
    cancelCreateConfirm: '您确定要放弃新增帐号吗？',
    edit: '编辑',
    cancelUpdateNickConfirm: '您确定要放弃修改昵称吗？',
    updatePassword: '修改密码',
    delete: '删除',
    deleteConfirm: '您确定要删除该帐号吗？',
    enable: '启用',
    disable: '停用',
    disableConfirm: '您确定要停用该帐号吗？',
    superAdminPlaceholder: '管理员不参与分流',
    operation: '操作',
    add: '新增',
    我知道了: '我知道了',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      '任何客服都必须属于至少一个客服组\n 否则他们将无法接待买家。',
    总坐席n位: '总坐席<num>{{n}}</num>位',
    启用n位: '启用<num>{{n}}</num>位',
    '订购到期，请先订购服务套餐': '订购到期，请先订购服务套餐',
    '试用到期，请先订购服务套餐': '试用到期，请先订购服务套餐',
    '当前坐席已达上限，请先订购客服坐席': '当前坐席已达上限，请先订购客服坐席',
    '当前试用已到期，请先订购服务套餐': '当前试用已到期，请先订购服务套餐',
    '当前订购已到期，请先订购服务套餐': '当前订购已到期，请先订购服务套餐',
    去订购: '去订购',
  },
  group: {
    fetchFailed: '获取客服组列表失败，请稍后再试',
    fetchPriorityFailed: '获取优先接待范围失败，请稍后再试',
    deleteSucceed: '删除客服组成功',
    deleteFailed: '删除客服组失败，请稍后再试',
    saveSucceed: '客服组保存成功',
    saveFailed: '客服组保存失败，请稍后再试',
    empty: '当前没有客服组哦～',
    emptyAccount: '暂时还没有客服帐号，不支持进行客服组管理。\n 请先完成新增客服帐号',
    create: '新增客服组',
    createAccount: '去新增客服帐号',
    priority: '优先接待范围',
    edit: '编辑',
    add: '添加',
    savePrioritySucceed: '优先接待范围保存成功',
    savePriorityFailed: '优先接待范围保存失败，请稍后再试',
    description: '查看引导',
    singleUndistributedStore: (name: string) => (
      <span>
        店铺：
        <Highlight>{name}</Highlight>
        未在任何客服组，买家消息无法分配至客服，请及时处理
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        当前有
        <Highlight>{count}</Highlight>
        个店铺未在任何客服组，买家消息无法分配至客服，请及时处理
      </span>
    ),
    客服分配说明: '客服分配说明',
    共x个分组: '共{{x}}个分组',
    descriptionRenderer: () => (
      <Description>
        <h3>买家进行咨询后，我们会如何分配？</h3>
        <div>
          <p>
            1、买家有
            <span>最近联系客服</span>
            ，且
            <span>在线</span>
            ，则直接分配给已接待的客服。
          </p>
          <p>
            2、买家近3天内没有联系过客服，则根据买家
            <span>历史行为</span>和<span>最近更新状态的订单</span>
            ，将买家分配至对应
            <span>接待范围</span>
            的客服组。
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                如买家满足多个
                <span>接待范围</span>
                的客服组时，每一个
                <span>接待范围</span>
                依次判断是否有对应的客服组进行分配，其中
                <span>优先接待范围</span>
                中的选项，会优先进行判断。
              </p>
              <p>
                <span>
                  如果买家满足的接待范围，没有任何一个客服组符合，则会分配至所属店铺的任意一个客服组
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3、我们会在
            <span>客服组内分配客服</span>
            ，根据组内客服的繁忙度规则寻找一个客服进行分配。
          </p>
        </div>
      </Description>
    ),

    '当前组内无客服，买家将无法分配至该客服组': '当前组内无客服，买家将无法分配至该客服组',
    '若存在多位启用中客服时，买家 将会平均分配': '若存在多位启用中客服时，买家 将会平均分配',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理',
    showTotal: (total: number) => `共${total}项`,
    更多x家店铺: (total: number) => `更多${total}家店铺`,
    tradeStatus: {
      NO_TRADE: '无订单',
      NO_PAY: '未付款',
      WAIT_SHIP: '待出货',
      SHIPPING: '运送中',
      RECENT_TRADED: '已完成7日内',
      REFUND: '退货退款',
    },
    buyerTag: {
      NEW: '新客',
      OLD: '老客',
    },
    receptionScopeType: {
      ALL_BUYER: '全部买家',
      TRADE_STATUS: '按照订单状态',
      BUYER_LABEL: '按照买家标签',
    },
    substituteGroupType: {
      UNALTERNATIVE: '不可替补接待',
      ALL_GROUP: '全部客服组',
      CERTAIN_GROUP: '指定客服组',
    },
    field: {
      tradeStatus: '订单状态',
      buyerTag: '买家标签',
      name: '客服组名称',
      userIds: '选择客服',
      storeIds: '适用店铺',
      receptionScope: '接待范围',
      substituteGroup: '繁忙时替补接待',
      负责平台: '负责平台',
    },
    placeholder: {
      name: '请输入名称',
      userIds: '请选择客服',
      storeIds: (platform: string) => `请选择${platform}店铺`,
      whatsapp: '请选择负责帐号',
      substituteGroup: '请选择客服组',
    },
    validate: {
      nameEmpty: '请输入客服组名称',
      nameLimit: (limit: number | string) => `客服组名称不得超过${limit}个字`,
      userEmpty: '请选择客服',
      storeEmpty: '请选择适用店铺',
      tradeStatusEmpty: '请选择订单状态',
      buyerTagEmpty: '请选择买家标签',
      groupEmpty: '请选择指定客服组',
    },
    save: '保存',
    客服组配置hint: '仅支持 Shopee 和 Lazada 平台进行客服组配置',
    priorityCancelConfirm: '您确定要放弃修改优先接待范围吗？',
    undistributedAccount: '未分配',
    distributedToAllBuyerStore: '该店铺已有接待范围为全部买家客服组',
    undistributedStore: '无客服组',
    fetchAccountListFailed: '获取客服列表失败，请稍后再试',
    fetchStoreListFailed: '获取适用店铺列表失败，请稍后再试',
    basicInfo: '基础信息',
    groupConfig: '客服组配置',
    editGroup: '编辑客服组',
    cancelEditGroupConfirm: '您确定要放弃编辑客服组吗？',
    deleteConfirm: '您确定要删除该客服组吗？',
    delete: '删除分组',
    accountCount: '组内客服',
    负责店铺: '负责店铺',
    负责WABA帐号: '负责WABA帐号',
    更多x帐号: (n: number) => `更多${n}帐号`,
    enableCount: (count: number | string) => `启用${count}位`,
    disableCount: (count: number | string) => `停用${count}位`,
    sleepCount: (count: number | string) => `休眠${count}位`,
    storePlatform: (platform: string) => `${platform}平台店铺`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      '当前客服组中仅有1位启用中的客服，买家将会全部分配给该客服处理',
    '当前存在多位启用中客服时，买家将会平均分配': '当前存在多位启用中客服时，买家将会平均分配',
    全部分配: '全部分配',
    平均分配: '平均分配',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配',
    展开剩余x家: (count: number) => `展开剩余${count}家`,
  },
  modals: {
    未设置: '未设置',
    帐号信息: '帐号信息',
    change_phone_tips: '您正在修改登录{{phone}}的手机号，',
    '修改成功后，需使用新手机号进行登录': '修改成功后，需使用新手机号进行登录',
    返回: '返回',
    修改登录手机号: '修改登录手机号',
    请输入新手机号: '请输入新手机号',
    确认修改: '确认修改',
    修改手机号: '修改手机号',
    设置手机号: '设置手机号',
    修改帐号密码: '修改帐号密码',
    设置帐号密码: '设置帐号密码',
    用户信息: '用户信息',
    当前版本: '当前版本',
    系统设置: '系统设置',
    ' 关于ChatPlusAI': '关于ChatPlusAI',
    ' 欢迎使用ChatPlusAI': '欢迎使用ChatPlusAI',
    公司信息: '公司信息',
    公司名称: '公司名称',
    请填写公司名称: '请填写公司名称',
    开票信息: '开票信息',
    'register.ChatPlusAI': '注册ChatPlusAI',
    'register.form.become.admin.tips': '你将默认成为超级管理员，并拥有全部超级管理员权限',
    '操作失败，请稍后再试': '操作失败，请稍后再试',
    正在发送验证码: '正在发送验证码',
    密码登录: '密码登录',
    验证码登录: '验证码登录',
    登录中: '登录中',
    '服务器开小差了，请稍后再试': '服务器开小差了，请稍后再试',
    '登录失败，验证码错误': '登录失败，验证码错误',
    '密码太久未更新，请联系管理员更新密码': '密码太久未更新，请联系管理员更新密码',
    设置登录帐号: '设置登录帐号',
    '正在修改登录手机号，请先完成身份认证。': '正在修改登录手机号，请先完成身份认证。',
    '验证码已发送至：': '验证码已发送至：',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服': '线上暂时不支持帐号找回，请联系ChatPlusAI客服',
    手机号已停用: '手机号已停用',
    下一步: '下一步',
    // 需要补充
    'account.rules.tips':
      '正在设置登录帐号，仅可由数字、英文字母和特殊符号组成(@除外)，长度1～20个字符。仅支持设置1次，设置后不可更改。',
    'password.rules.desc':
      '密码仅可由数字、英文字母或特殊符号组成，且需包含大/小写字母、数字、特殊符号，长度8～20个字符',
    已设置: '已设置',
    设置成功: '设置成功',
    不支持修改: '不支持修改',
    修改: '修改',
    身份验证: '身份验证',
  },
  guide: {
    新增客服组: '新增客服组',
    组内客服: '组内客服',
    适用店铺: '适用店铺',
    跳过: '跳过',
    选择客服组: '选择客服组',
    '您可以通过客服组来归类客服，提升接待买家效率': '您可以通过客服组来归类客服，提升接待买家效率',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。',
  },
};

export const en: typeof cn = {
  nav: {
    account: 'Account',
    group: 'Agent Group',
    department: 'Department',
  },
  account: {
    fetchFailed: 'Get account list failed, please try again later',
    fetchSeatFailed: 'Get seat info failed, please try again later',
    createAccount: 'Add new assistant(s)',
    showTotal: (total: number | string) => `Total ${total} items`,
    updateStatusSucceed: 'Update account status succeed',
    updateStatusFailed: 'Update account status failed, please try again later',
    deleteSucceed: 'Delete account succeed',
    deleteFailed: 'Delete account failed, please try again later',
    createAndCopy: 'Create an account and copy',
    createSucceed: 'Create account succeed',
    updateSucceed: 'Update account succeed',
    createFailed: 'Create account failed, please try again later',
    updateFailed: 'Update account failed, please try again later',
    copyAccountSucceed: 'Copy account info succeed',
    copyAccountFailed: 'Copy account info failed, please try again later',
    updateNickSucceed: 'Update account username succeed',
    updateNickFailed: 'Update account username failed, please try again later',
    updatePasswordSucceed: 'Update account password succeed',
    updatePasswordFailed: 'Update account password failed, please try again later',
    validate: {
      accountEmpty: 'Please input account',
      accountLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      accountReg: 'The account must contain letters and cannot contain characters: @',
      nickEmpty: 'Please input username',
      nickLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      passwordEmpty: 'Please input password',
      assistantGroups: 'Please choose assistant groups',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Your password must be ${maxLimit}-${minLimit} characters`,
      oldPasswordEmpty: 'Please input the old password',
      newPasswordEmpty: 'Please input new password',
      confirmPasswordEmpty: 'Please reconfirm new password',
      confirmPassword: 'The second confirmation is inconsistent with the new password',
      phoneEmpty: 'Please input the phone number',
      phoneInvalid: 'Please input valid phone number',
      emailInvalid: 'Please input valid email',
      passwordNumber: 'Your password must contain a number',
      passwordLetter:
        'Your password must contain at least one uppercase letter or one lowercase letter',
      passwordSpecial: 'Your password must contain at least one special character (!, *, _, etc.)',
      passwordAccount: 'Your password must not contain your ChatPlusAI ID',
    },
    field: {
      account: 'Account',
      name: 'Nickname',
      password: 'Password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      confirmPassword: 'Reconfirm',
      index: 'No.',
      phone: 'Phone number',
      email: 'Email',
    },
    placeholder: {
      account: 'Please input account',
      name: 'Please input username',
      password: 'Please input password',
      oldPassword: 'Please input the old password',
      newPassword: 'Please input new password',
      confirmPassword: 'Please input the new password again',
      phone: 'Please input the phone number',
      email: 'Please input the email',
      group: 'Please select assistant group',
    },
    role: {
      ASSISTANT: 'Assistant',
      SUPER_ADMIN: 'Administrator',
    },
    save: 'Save',
    cancelCreateConfirm: 'Are you sure to quit creating a new account?',
    edit: 'Edit',
    cancelUpdateNickConfirm: 'Are you sure to quit change username?',
    updatePassword: 'Change password',
    delete: 'Delete',
    deleteConfirm: 'Are you sure to delete this account?',
    enable: 'Enable',
    disable: 'Close',
    disableConfirm: 'Are you sure to close this account?',
    superAdminPlaceholder: 'Administrators are not in any groups.',
    operation: 'Action',
    add: 'Add',
    我知道了: 'I understand',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'Any agent must belong to at least one agent group or they will not be able to handle buyers.',
    总坐席n位: 'Total [<num>{{n}}</num>]',
    启用n位: 'Enable [<num>{{n}}</num>]',
    '订购到期，请先订购服务套餐':
      'The service has expired, please order the service package first.',
    '试用到期，请先订购服务套餐': 'The trial has expired, please order the service package first.',
    '当前坐席已达上限，请先订购客服坐席':
      'The current number of seats has reached the upper limit, please order customer service seats first.',
    '当前试用已到期，请先订购服务套餐':
      'The trial has expired, please order the service package first.',
    '当前订购已到期，请先订购服务套餐':
      'The service has expired, please order the service package first.',
    去订购: 'Go to order',
  },
  group: {
    fetchFailed: 'Get assistant group list failed, please try again later',
    fetchPriorityFailed: 'Get prior service scope failed, please try again later',
    deleteSucceed: 'Delete assistant group succeed',
    deleteFailed: 'Delete assistant group failed, please try again later',
    saveSucceed: 'Save assistant group succeed',
    saveFailed: 'Save assistant group failed, please try again later',
    empty: 'No assistant groups are available now.',
    emptyAccount:
      'No assistant account, assistant group manage is not supported. \n please add an account',
    create: 'Add new assistant group(s)',
    createAccount: 'Add new assistant account',
    priority: 'Prior Service Scope',
    edit: 'Edit',
    add: 'Add',
    savePrioritySucceed: 'Save prior service scope succeed',
    savePriorityFailed: 'Save prior service scope failed, please try again later',
    description: 'Guide',
    singleUndistributedStore: (name: string) => (
      <span>
        {`Buyers' conversation cannot be accessed by any assiatants as store `}
        <Highlight>{name}</Highlight>
        {` not in any of groups. Please deal with it in time.`}
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        {`Buyers' conversation cannot be accessed by any assiatants as there are `}
        <Highlight>{count}</Highlight>
        {` stores not in any of groups. Please deal with it in time.`}
      </span>
    ),
    客服分配说明: 'Assistant distribute description',
    共x个分组: 'Total {{x} group(s)',
    descriptionRenderer: () => (
      <Description>
        <h3>Customers will be allocated by following rules:</h3>
        <div>
          <p>
            1. Customers who inquried an assistant
            <span> recently </span>
            will be allocated directly to the assistant if he is
            <span> online</span>.
          </p>
          <p>
            2. Customers who did not inquired for 3 days will be allocated into an assistant group
            by his
            <span> historical behavior </span>
            or
            <span> latest order status</span>.
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                If the customer can be alloted to more than one assiatant group with different
                <span> prior service list</span>, it shall be judged that each
                <span> prior service list </span>
                has a corresponding assistant group or not, and customers in
                <span> prior service list </span>
                will be judged first.
              </p>
              <p>
                <span>
                  Or if the customer is in different prior service lists and no assiatants serve
                  him, random assistant group shall serve him.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Assiatants will be
            <span> alloted </span>
            based on how busy they are.
          </p>
        </div>
      </Description>
    ),
    showTotal: (total: number) => `Total ${total} items`,
    更多x家店铺: (total: number) => `More ${total} stores`,
    '当前组内无客服，买家将无法分配至该客服组':
      'There is currently no customer service, the buyer will not be able to be assigned to this customer service group',
    '若存在多位启用中客服时，买家 将会平均分配':
      'If there are multiple active agents, buyers will be evenly distributed',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'If there is only 1 active customer service in the customer service group, all buyers will be assigned to this customer service for processing',
    tradeStatus: {
      NO_TRADE: 'Placed no orders',
      NO_PAY: 'Unpaid',
      WAIT_SHIP: 'To Ship',
      SHIPPING: 'Shipping',
      RECENT_TRADED: 'Completed within 7 days',
      REFUND: 'Return/refund',
    },
    buyerTag: {
      NEW: 'New Customer',
      OLD: 'Regular Customer',
    },
    receptionScopeType: {
      ALL_BUYER: 'All customers',
      TRADE_STATUS: 'By Order Status',
      BUYER_LABEL: "By Customer's Label",
    },
    substituteGroupType: {
      UNALTERNATIVE: 'No alternative assistants',
      ALL_GROUP: 'All assistant groups',
      CERTAIN_GROUP: 'Choose assistant group(s)',
    },
    field: {
      tradeStatus: 'Order status',
      buyerTag: "Buyer' Label",
      name: 'Assistant group name',
      userIds: 'Choose assiatant(s)',
      storeIds: 'Choose store(s)',

      负责平台: 'Choose Platform',
      receptionScope: 'Service list',
      substituteGroup: 'Alternative agent',
    },
    placeholder: {
      name: 'Enter name here',
      userIds: 'Please choose assistants',
      storeIds: (platform: string) => `Please choose ${platform} store(s)`,
      whatsapp: 'Please choose WhatsApp account',
      substituteGroup: 'Please select assistant group',
    },
    validate: {
      nameEmpty: 'Please input assistant group name',
      nameLimit: (limit: number | string) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      userEmpty: 'Please select assistant',
      storeEmpty: 'Please select store',
      tradeStatusEmpty: 'Please select order status',
      buyerTagEmpty: "Please select buyer's label",
      groupEmpty: 'Please select assistant group',
    },
    save: 'Save',
    客服组配置hint:
      'Only supports Shopee and Lazada platforms for customer service group configuration',
    priorityCancelConfirm: 'Are you sure to quit edit prior service scope?',
    undistributedAccount: 'Not in any assistant groups',
    distributedToAllBuyerStore:
      'The service list of your store has been set as All assistant groups. ',
    undistributedStore: 'No assistant groups available',
    fetchAccountListFailed: 'Get assistant list failed, please try again later',
    fetchStoreListFailed: 'Get store list failed, please try again later',
    basicInfo: 'Basic Information',
    groupConfig: 'Assiatants group settings',
    editGroup: 'Edit assistant group',
    cancelEditGroupConfirm: 'Are you sure to quit creating a new assistant group?',
    deleteConfirm: 'Are you sure to delete the assistant group?',
    delete: 'Delete this group',
    accountCount: 'Assistants in the group',
    负责店铺: 'Stores',
    负责WABA帐号: 'WhatsApp accounts',
    更多x帐号: (x: number) => `More ${x} WhatsApp account`,
    enableCount: (count: number | string) => `Open ${count} assistant(s)`,
    disableCount: (count: number | string) => `Close ${count} assistant(s)`,
    sleepCount: (count: number | string) => `Dormancy ${count} assistant(s)`,
    storePlatform: (platform: string) => `${platform} platform store`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'There is only one open customer service in the customer service group, all buyers will be assigned to this customer service for processing.',
    '当前存在多位启用中客服时，买家将会平均分配':
      'There are many open customer service in the customer service group, the buyers will be average distributed.',
    全部分配: 'All distribution',
    平均分配: 'Average distribution',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'The customer service who has not logged in to the system for 7 consecutive days will enter the dormant state and will no longer allocate the buyer. If you log in again, the buyer allocation will be restored.',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'There is no open customer service in the current group. The buyer of the selected applicable store cannot be assigned to this customer service group.',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'There is only one open customer service in the customer service group, all buyers will be assigned to this customer service for processing.',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'There are many open customer service in the customer service group, the buyers will be average distributed.',
    展开剩余x家: (count: number) => `View other ${count} stores`,
  },
  modals: {
    未设置: 'Unsetting',
    帐号信息: 'Account Information',
    change_phone_tips: 'You are modifying the phone number for logging in to {{phone}}，',
    '修改成功后，需使用新手机号进行登录':
      'After the modification, you need to use the new mobile phone number to log in',
    返回: 'Back',
    修改登录手机号: 'Change phone number',
    请输入新手机号: 'Please enter new mobile number',
    确认修改: 'Confirm',
    修改手机号: 'Change phone number',
    设置手机号: 'Set phone number',
    修改帐号密码: 'Change account password',
    设置帐号密码: 'Set account password',
    用户信息: 'User Information',
    当前版本: 'Current Version',
    系统设置: 'System Settings',
    ' 关于ChatPlusAI': 'About ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Welcome to ChatPlusAI',
    公司信息: 'Company Information',
    公司名称: 'Company Name',
    请填写公司名称: 'Please enter company name',
    开票信息: 'Invoicing Information',
    'register.ChatPlusAI': 'Register for ChatPlusAI',
    'register.form.become.admin.tips':
      'You will be a super administrator by default and have all super administrator privileges.',
    '操作失败，请稍后再试': 'Failed, try again later',
    正在发送验证码: 'Sending captcha',
    密码登录: 'Login with password',
    验证码登录: 'Login with captcha',
    登录中: 'logging in',
    '服务器开小差了，请稍后再试': 'Server busy, please try again later.',
    '登录失败，验证码错误': 'Login failed, captcha is wrong.',
    '密码太久未更新，请联系管理员更新密码':
      'The password has not been updated for a long time, please contact the administrator to update.',
    设置登录帐号: 'Set up login account',
    '正在修改登录手机号，请先完成身份认证。':
      'The login phone number is being modified, please complete the identity verification first.',
    '验证码已发送至：': 'Captcha sent to:',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Finding account online is not supported temporarily, please contact ChatPlusAI assistant.',
    手机号已停用: 'The phone number is disabled',
    下一步: 'Next',

    'account.rules.tips':
      'Setting the login account, which can only consist of numbers, English letters and special symbols(Except @), 1 to 20 characters in length. Only supports setting once, and cannot be changed after setting.',
    'password.rules.desc':
      'The password can only consist of numbers, English letters or special symbols, and must contain upper/lowercase letters, numbers, and special symbols, with a length of 8 to 20 characters',
    已设置: 'Has been set',
    设置成功: 'Set successfully',
    不支持修改: 'Modification is not supported',
    修改: 'Modify',
    身份验证: 'identity verification',
  },
  guide: {
    新增客服组: 'Create',
    组内客服: 'Assistants in the group',
    适用店铺: 'Choose store(s)',
    选择客服组: 'Choose store(s)',
    跳过: 'Skip',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'You can classify customer service through customer service groups to improve the efficiency of receiving buyers',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Any agent must belong to at least one agent group or they will not be able to receive buyers.',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      "The store must belong to at least one customer service group, otherwise the store's buyer consultation will not have customer service reception.",
  },
};

export const id: typeof cn = {
  nav: {
    account: 'Akun',
    group: 'Grup Asisten',
    department: 'Divisi',
  },
  account: {
    fetchFailed: 'Gagal memperoleh daftar akun, silakan coba lagi',
    fetchSeatFailed: 'Gagal memperoleh informasi seat, silakan coba lagi',
    createAccount: 'Tambah asisten baru',
    showTotal: (total: number | string) => `Total ${total} item`,
    updateStatusSucceed: 'Berhasil menyimpan status asisten',
    updateStatusFailed: 'Gagal menyimpan status asisten, silakan coba lagi',
    deleteSucceed: 'Berhasil hapus asisten',
    deleteFailed: 'Gagal hapus asisten, silakan coba lagi',
    createAndCopy: 'Buat akun dan salin',
    createSucceed: 'Berhasil buat akun',
    updateSucceed: 'Berhasil edit akun',
    createFailed: 'Gagal buat akun, silakan coba lagi',
    updateFailed: 'Gagal edit akun, silakan coba lagi',
    copyAccountSucceed: 'Telah menyalin informasi akun',
    copyAccountFailed: 'Gagal menyalin informasi akun, silakan coba lagi',
    updateNickSucceed: 'Berhasil ubah username',
    updateNickFailed: 'Gagal ubah username, silakan coba lagi',
    updatePasswordSucceed: 'Berhasil ubah password',
    updatePasswordFailed: 'Gagal ubah password, silakan coba lagi',
    validate: {
      accountEmpty: 'Masukkan akun',
      accountLimit: (limit: number) => `Akun tidak boleh melebihi ${limit} kata`,
      accountReg: 'Akun harus mengandung huruf dan tidak boleh mengandung karakter: @',
      nickEmpty: 'Masukkan username',
      assistantGroups: 'Silakan pilih grup asisten',
      nickLimit: (limit: number) => `Nickname tidak boleh melebihi ${limit} kata`,
      passwordEmpty: 'Masukkan password',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Masukkan password ${minLimit}-${maxLimit} karakter`,
      oldPasswordEmpty: 'Masukkan password lama',
      newPasswordEmpty: 'Masukkan password baru',
      confirmPasswordEmpty: 'Masukkan password untuk konfirmasi kedua kali',
      confirmPassword: 'Konfirmasi kedua tidak sesuai dengan password baru',
      phoneEmpty: 'Masukkan nomor HP',
      phoneInvalid: 'Mohon masukkan nomor HP yang benar',
      emailInvalid: 'Mohon masukkan email yang valid',
      passwordNumber: 'Password harus mengandung paling sedikit satu angka',
      passwordLetter: 'Password harus mengandung paling sedikit satu huruf besar/kecil',
      passwordSpecial:
        'Password harus mengandung paling sedikit satu karakter khusus (seperti *, ., dll.)',
      passwordAccount: 'Password tidak boleh mengandung nama akun',
    },
    field: {
      account: 'Akun',
      name: 'Nickname',
      password: 'Password',
      oldPassword: 'Password lama',
      newPassword: 'Password baru',
      confirmPassword: 'Konfirmasi ulang',
      index: 'No',
      phone: 'Nomor HP',
      email: 'Email',
    },
    placeholder: {
      account: '6-16 karakter, hanya dapat diatur sekali',
      name: 'Masukkan username',
      password: 'Masukkan password',
      oldPassword: 'Masukkan password lama',
      newPassword: 'Masukkan password baru',
      confirmPassword: 'Masukkan lagi password baru',
      phone: 'Masukkan nomor HP',
      email: 'Masukkan email',
      group: 'Silakan pilih grup asisten',
    },
    role: {
      ASSISTANT: 'Asisten',
      SUPER_ADMIN: 'Administrator',
    },
    save: 'OK',
    cancelCreateConfirm: 'Apakah Anda yakin ingin berhenti tambah akun?',
    edit: 'Edit',
    cancelUpdateNickConfirm: 'Apakah Anda yakin ingin berhenti mengedit username?',
    updatePassword: 'Ubah password',
    delete: 'Hapus',
    deleteConfirm: 'Apakah Anda yakin ingin menghapus akun ini?',
    enable: 'Aktifkan',
    disable: 'Nonaktifkan',
    disableConfirm: 'Apakah Anda yakin ingin menonaktifkan akun ini?',
    superAdminPlaceholder: 'Administrator tidak ikut dalam pembagian chat',
    operation: 'Operasi',
    add: 'Tambah',
    我知道了: 'Saya sudah tahu',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'Setiap asisten harus menjadi bagian dari setidaknya satu grup asisten\n jika tidak mereka tidak akan dapat melayani pembeli.',
    总坐席n位: 'Total seat <num>{{n}}</num>',
    启用n位: 'Aktif <num>{{n}}</num> seat',
    '订购到期，请先订购服务套餐':
      'Periode langganan berakhir, silakan order paket layanan terlebih dahulu',
    '试用到期，请先订购服务套餐':
      'Periode trial berakhir, silakan order paket layanan terlebih dahulu',
    '当前坐席已达上限，请先订购客服坐席':
      'Saat ini seat telah mencapai batas, silakan order seat asisten terlebih dahulu',
    '当前试用已到期，请先订购服务套餐':
      'Periode trial saat ini telah berakhir, silakan order paket layanan terlebih dahulu',
    '当前订购已到期，请先订购服务套餐':
      'Periode langganan saat ini telah berakhir, silakan order paket layanan terlebih dahulu',
    去订购: 'Order sekarang',
  },
  group: {
    fetchFailed: 'Gagal memperoleh daftar grup asisten, silakan coba lagi',
    fetchPriorityFailed: 'Gagal memperoleh scope layanan prioritas, silakan coba lagi',
    deleteSucceed: 'Berhasil hapus grup asisten',
    deleteFailed: 'Gagal hapus grup asisten, silakan coba lagi',
    saveSucceed: 'Berhasil menyimpan grup asisten',
    saveFailed: 'Gagal menyimpan grup asisten, silakan coba lagi',
    empty: 'Saat ini belum ada grup asisten nih～',
    emptyAccount:
      'Saat ini belum ada akun asisten, tidak dapat mengelola grup asisten.\n Harap selesaikan penambahan akun asisten terlebih dahulu',
    create: 'Tambah grup asisten',
    createAccount: 'Tambah akun asisten baru',
    priority: 'Scope layanan prioritas',
    edit: 'Edit',
    add: 'Tambah',
    savePrioritySucceed: 'Berhasil menyimpan scope layanan prioritas',
    savePriorityFailed: 'Gagal menyimpan scope layanan prioritas, silakan coba lagi',
    description: 'Lihat petunjuk',
    客服组配置hint:
      'Hanya mendukung platform Shopee dan Lazada untuk konfigurasi grup layanan pelanggan',
    负责店铺: 'Toko',
    负责WABA帐号: 'akun whatsapp',
    更多x帐号: (x: number) => `Lebih banyak ${x} akun WhatsApp`,
    singleUndistributedStore: (name: string) => (
      <span>
        Toko:
        <Highlight>{name}</Highlight>
        tidak berada dalam grup asisten manapun, chat pembeli tidak dapat dibagikan ke asisten,
        harap segera tangani
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        Saat ini ada
        <Highlight>{count}</Highlight>
        toko yang tidak berada dalam grup asisten manapun, chat pembeli tidak dapat dibagikan ke
        asisten, harap segera tangani
      </span>
    ),
    客服分配说明: 'Keterangan pembagian asisten',
    共x个分组: 'Total {{x}} grup',
    descriptionRenderer: () => (
      <Description>
        <h3>Bagaimana kami membagikan chat setelah pembeli berkonsultasi?</h3>
        <div>
          <p>
            1. Jika akhir-akhir ini pembeli
            <span>pernah menghubungi asisten</span>, dan asisten tersebut
            <span>sedang online</span>, maka akan langsung dibagikan ke asisten yang telah
            melayaninya.
          </p>
          <p>
            2. Jika pembeli belum pernah menghubungi asisten dalam 3 hari terakhir, maka pembeli
            akan dibagikan ke grup asisten sesuai dengan
            <span>scope layanan</span>, berdasarkan
            <span>perilaku historis pembeli</span>dan<span>status pesanan terbaru.</span>
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                Jika pembeli memenuhi beberapa
                <span>scope layanan</span>
                dari grup asisten, maka setiap
                <span>scope layanan</span>
                akan ditentukan secara bergilir apakah ada grup asisten yang sesuai untuk dibagikan,
                dan opsi yang ada di
                <span>scope layanan prioritas</span>, akan diprioritaskan terlebih dahulu.
              </p>
              <p>
                <span>
                  Jika tidak ada grup asisten yang memenuhi scope layanan pembeli, maka akan
                  dibagikan ke grup asisten manapun sesuai dengan toko tempat pembeli itu
                  berkonsultasi.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Kami akan
            <span>membagi asisten di dalam grup asisten</span>, berdasarkan aturan tingkat kesibukan
            di dalam grup asisten, dan membagikan chat kepada asisten.
          </p>
        </div>
      </Description>
    ),

    '当前组内无客服，买家将无法分配至该客服组':
      'Tidak ada asisten di dalam grup ini, pembeli tidak dapat dibagikan ke grup asisten ini',
    '若存在多位启用中客服时，买家 将会平均分配':
      'Jika ada beberapa asisten yang aktif, pembeli akan dibagikan secara merata',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'Jika hanya ada 1 asisten yang aktif di grup asisten, maka semua pembeli akan dibagikan ke asisten tersebut untuk ditangani',
    showTotal: (total: number) => `Total ${total} item`,
    更多x家店铺: (total: number) => `${total} toko lagi`,
    tradeStatus: {
      NO_TRADE: 'Tidak ada pesanan',
      NO_PAY: 'Belum bayar',
      WAIT_SHIP: 'Dikemas',
      SHIPPING: 'Dikirim',
      RECENT_TRADED: 'Selesai dalam 7 hari terakhir',
      REFUND: 'Pengembalian barang/dana',
    },
    buyerTag: {
      NEW: 'Customer baru',
      OLD: 'Customer lama',
    },
    receptionScopeType: {
      ALL_BUYER: 'Semua pembeli',
      TRADE_STATUS: 'Berdasarkan status pesanan',
      BUYER_LABEL: 'Berdasarkan label pembeli',
    },
    substituteGroupType: {
      UNALTERNATIVE: 'Tidak bisa diganti',
      ALL_GROUP: 'Semua grup asisten',
      CERTAIN_GROUP: 'Hanya grup asisten tertentu',
    },
    field: {
      tradeStatus: 'Status pesanan',
      buyerTag: 'Label pembeli',
      name: 'Nama grup asisten',
      userIds: 'Akun asisten',
      storeIds: 'Toko',
      receptionScope: 'Scope layanan',
      substituteGroup: 'Pengganti saat sibuk',
      负责平台: 'Platform',
    },
    placeholder: {
      name: 'Masukkan nama',
      userIds: 'Silakan pilih asisten',
      storeIds: (platform: string) => `Silakan pilih toko ${platform}`,
      substituteGroup: 'Silakan pilih grup asisten',
      whatsapp: 'silahkan pilih akun WhatsApp',
    },
    validate: {
      nameEmpty: 'Masukkan nama grup asisten',
      nameLimit: (limit: number | string) => `Nama grup asisten tidak boleh melebihi ${limit} kata`,
      userEmpty: 'Silakan pilih asisten',
      storeEmpty: 'Silakan pilih toko',
      tradeStatusEmpty: 'Silakan pilih status pesanan',
      buyerTagEmpty: 'Silakan pilih label pembeli',
      groupEmpty: 'Silakan pilih grup asisten',
    },
    save: 'Simpan',
    priorityCancelConfirm: 'Apakah Anda yakin ingin berhenti mengubah scope layanan prioritas?',
    undistributedAccount: 'Belum dibagikan chat',
    distributedToAllBuyerStore: 'Scope layanan toko ini adalah semua pembeli di grup asisten',
    undistributedStore: 'Tidak ada grup asisten',
    fetchAccountListFailed: 'Gagal memperoleh daftar asisten, silakan coba lagi',
    fetchStoreListFailed: 'Gagal memperoleh daftar toko, silakan coba lagi',
    basicInfo: 'Informasi umum',
    groupConfig: 'Pengaturan grup asisten',
    editGroup: 'Edit grup asisten',
    cancelEditGroupConfirm: 'Apakah Anda yakin ingin berhenti edit grup asisten?',
    deleteConfirm: 'Apakah Anda yakin ingin menghapus grup asisten ini?',
    delete: 'Hapus grup',
    accountCount: 'Asisten di dalam grup',
    enableCount: (count: number | string) => `Aktif ${count}`,
    disableCount: (count: number | string) => `Nonaktif ${count}`,
    sleepCount: (count: number | string) => `Hibernasi ${count}`,
    storePlatform: (platform: string) => `Toko platform ${platform}`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'Jika hanya ada 1 asisten yang aktif di grup asisten, maka semua pembeli akan dibagikan ke asisten tersebut untuk ditangani',
    '当前存在多位启用中客服时，买家将会平均分配':
      'Jika ada beberapa asisten yang aktif, pembeli akan dibagikan secara merata',
    全部分配: 'Dibagi semua',
    平均分配: 'Dibagi rata',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'Asisten yang tidak login selama 7 hari berturut-turut akan memasuki status hibernasi, tidak akan dibagikan chat. Saat login kembali, baru akan dibagikan chat kembali.',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'Tidak ada asisten yang aktif di grup ini, pembeli di toko tidak dapat dibagikan ke grup asisten ini',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'Hanya ada 1 asisten yang aktif di grup asisten ini, semua pembeli di toko akan dibagikan ke asisten tersebut untuk ditangani',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'Ada beberapa asisten yang aktif di grup asisten ini, pembeli di toko akan dibagikan secara merata',
    展开剩余x家: (count: number) => `Lihat ${count} toko lagi`,
  },
  modals: {
    未设置: 'Belum diatur',
    帐号信息: 'Informasi Akun',
    change_phone_tips: 'Anda sedang mengubah nomor HP untuk log in {{phone}}，',
    '修改成功后，需使用新手机号进行登录':
      'Setelah berhasil ubah, Anda perlu memakai nomor HP baru untuk log in',
    返回: 'Kembali',
    修改登录手机号: 'Ubah nomor HP log in',
    请输入新手机号: 'Masukkan nomor HP baru',
    确认修改: 'Konfirmasi',
    修改手机号: 'Ubah nomor HP',
    设置手机号: 'Atur nomor HP',
    修改帐号密码: 'Ubah password',
    设置帐号密码: 'Atur akun password',
    用户信息: 'Informasi pengguna',
    当前版本: 'Versi saat ini',
    系统设置: 'Pengaturan sistem',
    ' 关于ChatPlusAI': 'Tentang ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Selamat menggunakan ChatPlusAI',
    公司信息: 'Informasi perusahaan',
    公司名称: 'Nama perusahaan',
    请填写公司名称: 'Silakan isi nama perusahaan',
    开票信息: 'Informasi faktur',
    'register.ChatPlusAI': 'Register ChatPlusAI',
    'register.form.become.admin.tips':
      'Anda akan menjadi admin super secara default dan memiliki semua hak admin super',
    '操作失败，请稍后再试': 'Operasi gagal, silakan coba beberapa saat lagi',
    正在发送验证码: 'Sedang mengirim kode OTP',
    密码登录: 'Log in melalui password',
    验证码登录: 'Log in melalui kode OTP',
    登录中: 'Sedang log in',
    '服务器开小差了，请稍后再试': 'Server sedang sibuk, silakan coba beberapa saat lagi',
    '登录失败，验证码错误': 'Gagal log in, kode OTP salah',
    '密码太久未更新，请联系管理员更新密码':
      'Password terlalu lama tidak diperbarui, silakan hubungi admin untuk perbarui password',
    设置登录帐号: 'Atur akun log in',
    '正在修改登录手机号，请先完成身份认证。':
      'Sedang mengubah nomor HP log in, silakan lengkapi identitas terlebih dahulu.',
    '验证码已发送至：': 'Kode OTP telah dikirim ke:',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Sementara belum mendukung pencarian kembali akun secara online, silakan hubungi layanan pelanggan ChatPlusAI',
    手机号已停用: 'Nomor HP telah berhenti digunakan',
    下一步: 'Langkah selanjutnya',

    'account.rules.tips':
      'Sedang mengatur akun login, hanya dapat terdiri dari angka, huruf, dan simbol khusus (kecuali @), jumlah 1-20 karakter. Hanya dapat diatur 1 kali, dan tidak dapat diubah setelah pengaturan.',
    'password.rules.desc':
      'Password hanya dapat terdiri dari angka, huruf, atau simbol khusus, serta harus termasuk huruf besar/kecil, angka, dan simbol khusus, jumlah 8-20 karakter',
    已设置: 'Telah diatur',
    设置成功: 'Berhasil diatur',
    不支持修改: 'Tidak dapat diubah',
    修改: 'Ubah',
    身份验证: 'Autentikasi identitas',
  },
  guide: {
    新增客服组: 'Tambah grup asisten',
    组内客服: 'Asisten di dalam grup',
    适用店铺: 'Toko',
    跳过: 'Skip',
    选择客服组: 'Pilih grup asisten',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'Anda dapat mengelompokkan asisten melalui grup asisten untuk meningkatkan efisiensi layanan kepada pembeli',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Setiap asisten harus dimasukkan setidaknya ke dalam satu grup asisten, jika tidak, mereka tidak akan dapat melayani pembeli.',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      'Toko harus dimasukkan setidaknya ke dalam satu grup asisten, jika tidak, pembeli toko tidak dapat dilayani oleh asisten.',
  },
};

export const th: typeof cn = {
  nav: {
    account: 'บัญชี',
    group: 'ทีมฝ่ายบริการลูกค้า',
    department: 'แผนก',
  },
  account: {
    fetchFailed: 'ดึงข้อมูลรายการบัญชีล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    fetchSeatFailed: 'ดึงข้อมูลที่นั่งล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    createAccount: 'เพิ่มฝ่ายบริการลูกค้าใหม่',
    showTotal: (total: number | string) => `ทั้งหมด${total}รายการ`,
    updateStatusSucceed: 'อัพเดตสถานะฝ่ายบริการลูกค้าสำเร็จ',
    updateStatusFailed: 'อัพเดตสถานะฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    deleteSucceed: 'ลบฝ่ายบริการลูกค้าสำเร็จ',
    deleteFailed: 'ลบฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    createAndCopy: 'สร้างบัญชีและคัดลอก',
    createSucceed: 'เพิ่มบัญชีใหม่สำเร็จ',
    updateSucceed: 'อัพเดตบัญชีสำเร็จ',
    createFailed: 'เพิ่มบัญชีใหม่ล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    updateFailed: 'อัพเดตบัญชีล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    copyAccountSucceed: 'คัดลอกข้อมูลบัญชีสำเร็จ',
    copyAccountFailed: 'คัดลอกข้อมูลบัญชีล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    updateNickSucceed: 'อัพเดทชื่อผู้ใช้สำเร็จ',
    updateNickFailed: 'อัพเดทชื่อผู้ใช้ล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    updatePasswordSucceed: 'แก้ไขรหัสผ่านสำเร็จ',
    updatePasswordFailed: 'แก้ไขรหัสผ่านล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    validate: {
      accountEmpty: 'กรุณากรอกบัญชีของคุณ',
      accountLimit: (limit: number) => `เลขที่บัญชีต้องไม่เกิน${limit}ตัวอักษร`,
      accountReg: 'เลขที่บัญชีต้องประกอบด้วยตัวอักษรแต่ต้องไม่ประกอบด้วยเครื่องหมายพิเศษ：@',
      nickEmpty: 'กรุณากรอกชื่อผู้ใช้',
      assistantGroups: 'กรุณาเลือกทีมฝ่ายบริการลูกค้า',
      nickLimit: (limit: number) => `ชื่อผู้ใช้ต้องไม่เกิน${limit}ตัวอักษร`,
      passwordEmpty: 'กรุณากรอกรหัสผ่าน',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `กรุณากรอกรหัสผ่าน${minLimit}-${maxLimit}หลัก`,
      oldPasswordEmpty: 'กรุณากรอกรหัสผ่านเดิม',
      newPasswordEmpty: 'กรุณากรอกรหัสผ่านใหม่',
      confirmPasswordEmpty: 'กรุณายืนยันรหัสผ่านใหม่อีกครั้ง',
      confirmPassword: 'การยืนยันรหัสผ่านใหม่ไม่ถูกต้อง',
      phoneEmpty: 'กรุณากรอกหมายเลขโทรศัพท์',
      phoneInvalid: 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
      emailInvalid: 'กรุณากรอกอีเมล์ที่ถูกต้อง',
      passwordNumber: 'รหัสผ่านต้องประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว',
      passwordLetter: 'รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่/พิมพ์เล็ก',
      passwordSpecial: 'รหัสผ่านต้องประกอบด้วยเครื่องหมายพิเศษอย่างน้อยหนึ่งตัว (เช่น *, .เป็นต้น)',
      passwordAccount: 'รหัสผ่านห้ามประกอบด้วยชื่อผู้ใช้',
    },
    field: {
      account: 'บัญชี',
      name: 'ชื่อผู้ใช้',
      password: 'รหัสผ่าน',
      oldPassword: 'รหัสผ่านเดิม',
      newPassword: 'รหัสผ่านใหม่',
      confirmPassword: 'ยืนยันรหัสผ่าน',
      index: 'ลำดับ',
      phone: 'หมายเลขโทรศัพท์',
      email: 'อีเมล์',
    },
    placeholder: {
      account: '6-16 ตัวอักษร ตั้งค่าได้เพียงครั้งเดียวเท่านั้น',
      name: 'กรุณากรอกชื่อผู้ใช้',
      password: 'กรุณากรอกรหัสผ่าน',
      oldPassword: 'กรุณากรอกรหัสผ่านเดิม',
      newPassword: 'กรุณากรอกรหัสผ่านใหม่',
      confirmPassword: 'ยืนยันรหัสผ่านใหม่อีกครั้ง',
      phone: 'กรุณากรอกหมายเลขโทรศัพท์',
      email: 'กรุณากรอกอีเมล์',
      group: 'กรุณาเลือกทีมฝ่ายบริการลูกค้า',
    },
    role: {
      ASSISTANT: 'ฝ่ายบริการลูกค้า',
      SUPER_ADMIN: 'ผู้ดูแลระบบ',
    },
    save: 'ยืนยัน',
    cancelCreateConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการสร้างบัญชีใหม่？',
    edit: 'แก้ไข',
    cancelUpdateNickConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกแก้ไขชื่อผู้ใช้？',
    updatePassword: 'แก้ไขรหัสผ่าน',
    delete: 'ลบ',
    deleteConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีนี้？',
    enable: 'เปิดการใช้งาน',
    disable: 'ปิดการใช้งาน',
    disableConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการปิดการใช้งานบัญชีนี้？',
    superAdminPlaceholder: 'ผู้ดูแลระบบไม่ต้องทำการแบ่งทีม',
    operation: 'ปฏิบัติการ',
    add: 'เพิ่ม',
    我知道了: 'ฉันเข้าใจ',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'ฝ่ายบริการลูกค้าทุกคนต้องเข้าทีมอย่างน้อยหนึ่งทีม\n ไม่เช่นนั้นจะไม่สามารถให้บริการลูกค้าได้',
    总坐席n位: 'จำนวนที่นั่งทั้งหมด<num>{{n}}</num>ที่นั่ง',
    启用n位: 'เปิดการใช้งาน<num>{{n}}</num>ที่นั่ง',
    '订购到期，请先订购服务套餐': 'แพ็คเกจหมดอายุแล้ว กรุณาสั่งซื้อแพ็คเกจบริการ',
    '试用到期，请先订购服务套餐': 'การทดลองใช้หมดอายุแล้ว กรุณาสั่งซื้อแพ็คเกจบริการ',
    '当前坐席已达上限，请先订购客服坐席':
      'จำนวนที่นั่งปัจจุบันถึงขีดจำกัดสูงสุดแล้ว กรุณาสั่งซื้อที่นั่งสำหรับบริการลูกค้า',
    '当前试用已到期，请先订购服务套餐': 'การทดลองใช้ปัจจุบันหมดอายุแล้ว กรุณาสั่งซื้อแพ็คเกจบริการ',
    '当前订购已到期，请先订购服务套餐': 'แพ็คเกจปัจจุบันหมดอายุแล้ว กรุณาสั่งซื้อแพ็คเกจบริการ',
    去订购: 'สั่งซื้อ',
  },
  group: {
    fetchFailed: 'ดึงข้อมูลรายการทีมฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    fetchPriorityFailed: 'ดึงข้อมูลขอบเขตการต้อนรับที่สำคัญล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    deleteSucceed: 'ลบทีมฝ่ายบริการลูกค้าสำเร็จ',
    deleteFailed: 'ลบทีมฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    saveSucceed: 'บันทึกทีมฝ่ายบริการลูกค้าสำเร็จ',
    saveFailed: 'บันทึกทีมฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    empty: 'ขณะนี้ยังไม่มีทีมฝ่ายบริการลูกค้า',
    emptyAccount:
      'ยังไม่มีบัญชีฝ่ายบริการลูกค้า ไม่รองรับการจัดการทีมฝ่ายบริการลูกค้า\n กรุณาสร้างบัญชีฝ่ายบริการลูกค้า',
    create: 'สร้างทีมฝ่ายบริการลูกค้าใหม่',
    createAccount: 'สร้างบัญชีฝ่ายบริการลูกค้า',
    priority: 'ขอบเขตการต้อนรับที่สำคัญ',
    edit: 'แก้ไข',
    add: 'เพิ่ม',
    savePrioritySucceed: 'บันทึกขอบเขตการต้อนรับที่สำคัญสำเร็จ',
    savePriorityFailed: 'บันทึกขอบเขตการต้อนรับที่สำคัญล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    description: 'ดูคำแนะนำ',
    singleUndistributedStore: (name: string) => (
      <span>
        ร้านค้า：
        <Highlight>{name}</Highlight>
        ไม่ได้อยู่ในทีมฝ่ายบริการลูกค้าใดๆ ข้อความของลูกค้าไม่สามารถจัดสรรไปยังฝ่ายบริการลูกค้าได้
        กรุณารีบจัดการ
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        ปัจจุบันมี
        <Highlight>{count}</Highlight>
        ร้านค้าที่ไม่ได้อยู่ในทีมฝ่ายบริการลูกค้าใดๆ
        ข้อความของลูกค้าไม่สามารถจัดสรรไปยังฝ่ายบริการลูกค้าได้ กรุณารีบจัดการ
      </span>
    ),
    客服分配说明: 'รายละเอียดการจัดสรรงานฝ่ายบริการลูกค้า',
    共x个分组: 'ทั้งหมด{{x}}ทีม',
    客服组配置hint: 'รองรับเฉพาะแพลตฟอร์ม Shopee และ Lazada สำหรับการกำหนดค่ากลุ่มบริการลูกค้า',
    负责店铺: 'แพลตฟอร์ม',
    负责WABA帐号: 'บัญชี WhatsApp',
    更多x帐号: (x: number) => `มากกว่า ${x} บัญชี WhatsApp`,
    descriptionRenderer: () => (
      <Description>
        <h3>หลังจากที่ลูกค้าเข้ามาสอบถาม ทางเรามีการจัดสรรอย่างไร？</h3>
        <div>
          <p>
            1、ลูกค้ามีการ
            <span>ติดต่อฝ่ายบริการลูกค้าในเร็วๆ นี้</span>
            และ
            <span>ออนไลน์</span>
            จะถูกจัดสรรไปยังฝ่ายบริการลูกค้าที่ต้อนรับโดยตรง
          </p>
          <p>
            2、ลูกค้าไม่ได้มีการติดต่อฝ่ายบริการลูกค้าในช่วง 3 วันที่ผ่านมา
            จะถูกจัดสรรไปยังฝ่ายบริการลูกค้าที่มี
            <span>ขอบเขตการต้อนรับ</span>
            ที่สอดคล้อง โดยอ้างอิงจาก
            <span>ประวัติ</span>และ<span>สถานะล่าสุดของคำสั่งซื้อ</span>
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                หากลูกค้าตรงกับ
                <span>ขอบเขตการต้อนรับ</span>
                ของหลายทีม
                <span>ขอบเขตการต้อนรับ</span>
                จะตัดสินว่ามีทีมฝ่ายบริการลูกค้าที่สอดคล้องต่อการจัดสรรหรือไม่ โดยที่ตัวเลือก
                <span>ขอบเขตการต้อนรับที่สำคัญ</span>
                จะถูกตัดสินก่อน
              </p>
              <p>
                <span>
                  หากไม่มีทีมฝ่ายบริการลูกค้าที่สอดคล้องกับขอบเขตการต้อนรับลูกค้า
                  จะถูกจัดสรรไปยังทีมฝ่ายบริการลูกค้าของร้านทีมใดทีมหนึ่ง
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3、ทางเราจะจัดสรร
            <span>ฝ่ายบริการลูกค้าในทีม</span>
            โดยอ้างอิงการจัดสรรจากความยุ่งของฝ่ายบริการลูกค้า
          </p>
        </div>
      </Description>
    ),

    '当前组内无客服，买家将无法分配至该客服组':
      'ทีมปัจุบันไม่มีฝ่ายบริการลูกค้า จะไม่สามารถจัดสรรลูกค้ามายังทีมฝ่ายบริการลูกค้านี้ได้',
    '若存在多位启用中客服时，买家 将会平均分配':
      'หากมีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่หลายราย จะได้รับการจัดสรรลูกค้าอย่างเท่าเทียมกัน',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'หากในทีมมีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่เพียง 1 ราย ลูกค้าจะถูกจัดสรรให้ฝ่ายบริการลูกค้านี้จัดการทั้งหมด',
    showTotal: (total: number) => `ทั้งหมด${total}รายการ`,
    更多x家店铺: (total: number) => `อีก${total}ร้านค้า`,
    tradeStatus: {
      NO_TRADE: 'ไม่มีคำสั่งซื้อ',
      NO_PAY: 'ที่ต้องชำระ',
      WAIT_SHIP: 'ที่ต้องจัดส่ง',
      SHIPPING: 'ระหว่างขนส่ง',
      RECENT_TRADED: 'ที่สำเร็จภายใน7วัน',
      REFUND: 'คืนสินค้าคืนเงิน',
    },
    buyerTag: {
      NEW: 'ลูกค้าใหม่',
      OLD: 'ลูกค้าประจำ',
    },
    receptionScopeType: {
      ALL_BUYER: 'ลูกค้าทั้งหมด',
      TRADE_STATUS: 'สถานะคำสั่งซื้อ',
      BUYER_LABEL: 'แท็กของลูกค้า',
    },
    substituteGroupType: {
      UNALTERNATIVE: 'ไม่สามารถสำรองต้อนรับ',
      ALL_GROUP: 'ทีมฝ่ายบริการลูกค้าทั้งหมด',
      CERTAIN_GROUP: 'ทีมฝ่ายบริการลูกค้าที่กำหนด',
    },
    field: {
      tradeStatus: 'สถานะคำสั่งซื้อ',
      buyerTag: 'แท็กของลูกค้า',
      name: 'ชื่อทีมฝ่ายบริการลูกค้า',
      userIds: 'เลือกฝ่ายบริการลูกค้า',
      storeIds: 'ร้านค้าที่เกี่ยวข้อง',
      receptionScope: 'ขอบเขตการต้อนรับ',
      substituteGroup: 'สำรองต้อนรับขณะไม่ว่าง',
      负责平台: 'แพลตฟอร์ม',
    },
    placeholder: {
      name: 'กรุณากรอกชื่อ',
      userIds: 'กรุณาเลือกฝ่ายบริการลูกค้า',
      storeIds: (platform: string) => `โปรดเลือกร้านค้า ${platform} แห่ง`,
      substituteGroup: 'กรุณาเลือกทีมฝ่ายบริการลูกค้า',
      whatsapp: 'โปรดเลือกบัญชี WhatsApp',
    },
    validate: {
      nameEmpty: 'กรุณากรอกชื่อทีมฝ่ายบริการลูกค้า',
      nameLimit: (limit: number | string) => `ชื่อทีมฝ่ายบริการลูกค้าต้องไม่เกิน${limit}ตัวอักษร`,
      userEmpty: 'กรุณาเลือกฝ่ายบริการลูกค้า',
      storeEmpty: 'กรุณาเลือกร้านค้าที่เกี่ยวข้อง',
      tradeStatusEmpty: 'กรุณาเลือกสถานะคำสั่งซื้อ',
      buyerTagEmpty: 'กรุณาเลือกแท็กของลูกค้า',
      groupEmpty: 'กรุณาเลือกทีมฝ่ายบริการลูกค้าที่กำหนด',
    },
    save: 'บันทึก',
    priorityCancelConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการแก้ไขขอบเขตการต้อนรับที่สำคัญ？',
    undistributedAccount: 'ไม่ได้จัดสรร',
    distributedToAllBuyerStore:
      'ร้านค้านี้มีทีมฝ่ายบริการลูกค้าที่มีขอบเขตการต้อนรับเป็นลูกค้าทั้งหมด',
    undistributedStore: 'ไม่มีทีมฝ่ายบริการลูกค้า',
    fetchAccountListFailed: 'ดึงข้อมูลรายการฝ่ายบริการลูกค้าล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    fetchStoreListFailed: 'ดึงข้อมูลรายการร้านค้าที่เกี่ยวข้องล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    basicInfo: 'ข้อมูลพื้นฐาน',
    groupConfig: 'การกำหนดค่าทีมฝ่ายบริการลูกค้า',
    editGroup: 'แก้ไขทีมฝ่ายบริการลูกค้า',
    cancelEditGroupConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการแก้ไขทีมฝ่ายบริการลูกค้า？',
    deleteConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการลบทีมฝ่ายบริการลูกค้านี้？',
    delete: 'ลบทีมนี้',
    accountCount: 'ฝ่ายบริการลูกค้าในทีม',
    enableCount: (count: number | string) => `เปิดใช้งาน${count}ราย`,
    disableCount: (count: number | string) => `ปิดใช้งาน${count}ราย`,
    sleepCount: (count: number | string) => `พัก${count}ราย`,
    storePlatform: (platform: string) => `ร้านค้าแพลตฟอร์ม${platform}`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'ขณะนี้ในทีมมีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่เพียง 1 ราย ลูกค้าจะถูกจัดสรรให้ฝ่ายบริการลูกค้านี้จัดการทั้งหมด',
    '当前存在多位启用中客服时，买家将会平均分配':
      'ขณะนี้มีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่หลายราย จะได้รับการจัดสรรลูกค้าอย่างเท่าเทียมกัน',
    全部分配: 'จัดสรรทั้งหมด',
    平均分配: 'จัดสรรเท่าๆกัน',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'ฝ่ายบริการลูกค้าที่ไม่ได้เข้าสู่ระบบเป็นเวลา 7 วันติดต่อกันจะเข้าสู่สถานะพัก จะไม่มีการจัดสรรลูกค้า เข้าสู่ระบบอีกครั้งเพื่อกู้คืนการจัดสรรลูกค้า',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'ในทีมปัจจุบันไม่มีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่ ลูกค้าที่เลือกร้านค้าที่เกี่ยวข้องไม่สามารถทำการจัดสรรไปยังทีมฝ่ายบริการลูกค้านี้ได้',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'ขณะนี้ในทีมมีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่เพียง 1 ราย ลูกค้าที่เลือกร้านค้าที่เกี่ยวข้องจะถูกจัดสรรให้ฝ่ายบริการลูกค้านี้จัดการทั้งหมด',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'ขณะนี้มีฝ่ายบริการลูกค้าที่เปิดใช้งานอยู่หลายราย ลูกค้าที่เลือกร้านค้าที่เกี่ยวข้องจะได้รับการจัดสรรลูกค้าอย่างเท่าเทียมกัน',
    展开剩余x家: (count: number) => `ขยาย${count}ร้านค้าที่เหลือ`,
  },
  modals: {
    未设置: 'ไม่ได้ตั้งค่า',
    帐号信息: 'ข้อมูลบัญชี',
    change_phone_tips: 'กำลังแก้ไขหมายเลขโทรศัพท์สำหรับการเข้าสู่ระบบ{{phone}} ',
    '修改成功后，需使用新手机号进行登录':
      'หลังจากแก้ไขแล้ว คุณต้องใช้หมายเลขโทรศัพท์มือถือใหม่ในการเข้าสู่ระบบ',
    返回: 'ย้อนกลับ',
    修改登录手机号: 'แก้ไขหมายเลขโทรศัพท์',
    请输入新手机号: 'กรุณากรอกหมายเลขโทรศัพท์ใหม่',
    确认修改: 'ยืนยันการแก้ไข',
    修改手机号: 'แก้ไขหมายเลขโทรศัพท์',
    设置手机号: 'ตั้งค่าหมายเลขโทรศัพท์',
    修改帐号密码: 'เปลี่ยนรหัสผ่าน',
    设置帐号密码: 'ตั้งค่ารหัสผ่าน',
    用户信息: 'ข้อมูลผู้ใช้',
    当前版本: 'เวอร์ชั่นปัจจุบัน',
    系统设置: 'การตั้งค่าระบบ',
    ' 关于ChatPlusAI': 'เกี่ยวกับ ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'ยินดีต้อนรับสู่ ChatPlusAI',
    公司信息: 'ข้อมูลบริษัท',
    公司名称: 'ชื่อบริษัท',
    请填写公司名称: 'กรุณากรอกชื่อบริษัท',
    开票信息: 'ข้อมูลใบกำกับภาษี',
    'register.ChatPlusAI': 'การลงทะเบียน ChatPlusAI',
    'register.form.become.admin.tips':
      'คุณจะเป็นผู้ดูแลระบบขั้นสูง และมีสิทธิ์ดูแลระบบขั้นสูงทั้งหมด',
    '操作失败，请稍后再试': 'การดำเนินการล้มเหลว โปรดลองอีกครั้งในภายหลัง',
    正在发送验证码: 'กำลังส่งรหัสยืนยัน',
    密码登录: 'รหัสผ่านเข้าสู่ระบบ',
    验证码登录: 'รหัสยืนยันการเข้าสู่ระบบ',
    登录中: 'กำลังเข้าสู่ระบบ',
    '服务器开小差了，请稍后再试': 'เซิร์ฟเวอร์ไม่ว่าง โปรดลองอีกครั้งในภายหลัง',
    '登录失败，验证码错误': 'การเข้าสู่ระบบล้มเหลว รหัสยืนยันไม่ถูกต้อง',
    '密码太久未更新，请联系管理员更新密码':
      'รหัสผ่านไม่ได้รับการอัพเดตเป็นเวลานาน โปรดติดต่อผู้ดูแลระบบเพื่ออัพเดตรหัสผ่าน',
    设置登录帐号: 'ตั้งค่าบัญชีเข้าสู่ระบบ',
    '正在修改登录手机号，请先完成身份认证。':
      'กำลังแก้ไขหมายเลขโทรศัพท์สำหรับเข้าสู่ระบบ โปรดดำเนินการยืนยันตัวตนให้เรียบร้อย。',
    '验证码已发送至：': 'ส่งรหัสยืนยันไปยัง：แล้ว',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'ระบบออนไลน์ไม่รองรับการกู้คืนบัญชีชั่วคราว โปรดติดต่อChatPlusAI',
    手机号已停用: 'หมายเลขโทรศัพท์ถูกปิดการใช้งานแล้ว',
    下一步: 'ขั้นตอนถัดไป',
    // 需要补充
    'account.rules.tips':
      'กำลังตั้งค่าบัญชีสำหรับเข้าสู่ระบบ ใช้ได้เฉพาะตัวเลข ตัวอักษรภาษาอังกฤษและเครื่องหมายพิเศษเท่านั้น(ยกเว้น@) ความยาวความยาว 1-20 ตัวอักษร รองรับการตั้งค่าเพียง 1 ครั้งเท่านั้น เมื่อตั้งค่าแล้วจะไม่สามารถแก้ไขได้',
    'password.rules.desc':
      'รหัสผ่านต้องเป็นตัวเลข ตัวอักษรภาษาอังกฤษหรือเครื่องหมายพิเศษเท่านั้น และต้องประกอบด้วยอักษรตัวพิมพ์ใหญ่/พิมพ์เล็ก ตัวเลข เครื่องหมายพิเศษ ความยาว 8-20 ตัวอักษร',
    已设置: 'ตั้งค่าแล้ว',
    设置成功: 'ตั้งค่าสำเร็จ',
    不支持修改: 'ไม่รองรับการแก้ไข',
    修改: 'แก้ไข',
    身份验证: 'ยืนยันตัวตน',
  },
  guide: {
    新增客服组: 'เพิ่มทีมฝ่ายบริการลูกค้าใหม่',
    组内客服: 'ฝ่ายบริการลูกค้าในทีม',
    适用店铺: 'ร้านค้าที่เกี่ยวข้อง',
    跳过: 'ข้าม',
    选择客服组: 'เลือกทีมฝ่ายบริการลูกค้า',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'คุณสามารถจำแนกประเภทฝ่ายบริการลูกค้าผ่านทีมฝ่ายบริการลูกค้า เพิ่มประสิทธิภาพในการต้อนรับลูกค้า',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'ฝ่ายบริการลูกค้าทุกคนต้องเข้าทีมฝ่ายบริการลูกค้าอย่างน้อยหนึ่งทีม ไม่เช่นนั้นจะไม่สามารถให้บริการลูกค้าได้',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      'ร้านค้าต้องมีทีมฝ่ายบริการลูกค้าอย่างน้อยหนึ่งทีม ไม่เช่นนั้นลูกค้าของร้านค้าจะไม่ได้รับการบริการจากฝ่ายบริการลูกค้า',
  },
};

export const tl: typeof cn = {
  nav: {
    account: 'Account',
    group: 'Agent Group',
    department: 'Department',
  },
  account: {
    fetchFailed: 'Nag-fail ang pag-load ng account list, maaaring subukan ulit mamaya',
    fetchSeatFailed: 'Nag-fail ang pag-load ng seat info, maaaring subukan ulit mamaya',
    createAccount: 'Add new assistant(s)',
    showTotal: (total: number | string) => `Total ${total} items`,
    updateStatusSucceed: 'Update account status succeed',
    updateStatusFailed: 'Nag-fail ang pag-update ng account status, maaaring subukan ulit mamaya',
    deleteSucceed: 'Delete account succeed',
    deleteFailed: 'Delete account failed, please try again later',
    createAndCopy: 'Create an account and copy',
    createSucceed: 'Create account succeed',
    updateSucceed: 'Update account succeed',
    createFailed: 'Nag-fail ang pag-create ng account, maaaring subukan ulit mamaya',
    updateFailed: 'Nag-fail ang pag-update ng account, maaaring subukan ulit mamaya',
    copyAccountSucceed: 'Copy account info succeed',
    copyAccountFailed: 'Nag-fail ang pag-copy ng account, maaaring subukan ulit mamaya',
    updateNickSucceed: 'Update account username succeed',
    updateNickFailed: 'Nag-fail ang pag-update ng account username, maaaring subukan ulit mamaya',
    updatePasswordSucceed: 'Update account password succeed',
    updatePasswordFailed:
      'Nag-fail ang pag-update ng account password, maaaring subukan ulit mamaya',
    validate: {
      accountEmpty: 'Please input account',
      accountLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      accountReg: 'The account must contain letters and cannot contain characters: @',
      nickEmpty: 'Please input username',
      nickLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      passwordEmpty: 'Please input password',
      assistantGroups: 'Please choose assistant groups',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Your password must be ${maxLimit}-${minLimit} characters`,
      oldPasswordEmpty: 'Please input the old password',
      newPasswordEmpty: 'Please input new password',
      confirmPasswordEmpty: 'Please reconfirm new password',
      confirmPassword: 'The second confirmation is inconsistent with the new password',
      phoneEmpty: 'Please input the phone number',
      phoneInvalid: 'Please input valid phone number',
      emailInvalid: 'Please input valid email',
      passwordNumber: 'Your password must contain a number',
      passwordLetter:
        'Your password must contain at least one uppercase letter or one lowercase letter',
      passwordSpecial: 'Your password must contain at least one special character (!, *, _, etc.)',
      passwordAccount: 'Your password must not contain your ChatPlusAI ID',
    },
    field: {
      account: 'Account',
      name: 'Nickname',
      password: 'Password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      confirmPassword: 'Reconfirm',
      index: 'No.',
      phone: 'Phone number',
      email: 'Email',
    },
    placeholder: {
      account: 'Please input account',
      name: 'Please input username',
      password: 'Please input password',
      oldPassword: 'Please input the old password',
      newPassword: 'Please input new password',
      confirmPassword: 'Please input the new password again',
      phone: 'Please input the phone number',
      email: 'Please input the email',
      group: 'Please select assistant group',
    },
    role: {
      ASSISTANT: 'Assistant',
      SUPER_ADMIN: 'Administrator',
    },
    save: 'Save',
    cancelCreateConfirm: 'Are you sure to quit creating a new account?',
    edit: 'Edit',
    cancelUpdateNickConfirm: 'Are you sure to quit change username?',
    updatePassword: 'Change password',
    delete: 'Delete',
    deleteConfirm: 'Are you sure to delete this account?',
    enable: 'Enable',
    disable: 'Close',
    disableConfirm: 'Are you sure to close this account?',
    superAdminPlaceholder: 'Administrators are not in any groups.',
    operation: 'Action',
    add: 'Add',
    我知道了: 'I understand',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'Ang agent at dapat kabilang sa isa man sa mga agent group kung hindi, hindi rin sila magkakaroon ng pagkakataon para mag-handle ng mga buyers.',
    总坐席n位: 'Total [<num>{{n}}</num>]',
    启用n位: 'Enable [<num>{{n}}</num>]',
    '订购到期，请先订购服务套餐':
      'Nag-expire na ang service, maaaring unahin i-order ang service package muna.',
    '试用到期，请先订购服务套餐':
      'Nag-expire na ang trial, maaaring unahin i-order ang service package muna.',
    '当前坐席已达上限，请先订购客服坐席':
      'Na-reach na ang upper limit ng current number ng seats, maaaring unahin i-order ang customer service seats muna.',
    '当前试用已到期，请先订购服务套餐':
      'Nag-expire na ang trial, maaaring unahin i-order ang service package muna.',
    '当前订购已到期，请先订购服务套餐':
      'Nag-expire na ang service, please order the service package first.',
    去订购: 'Go to order',
  },
  group: {
    fetchFailed: 'Nag-fail ang pag-load ng assistant group list, maaaring subukan ulit mamaya',
    fetchPriorityFailed:
      'Nag-fail ang pag-load ng prior service scope, maaaring subukan ulit mamaya',
    deleteSucceed: 'Delete assistant group succeed',
    deleteFailed: 'Nag-fail ang pag-delete ng assistant group, maaaring subukan ulit mamaya',
    saveSucceed: 'Save assistant group succeed',
    saveFailed: 'Nag-fail ang pag-save ng assistant group, maaaring subukan ulit mamaya',
    empty: 'Walang assistant group na available ngayon.',
    emptyAccount:
      'No assistant account, assistant group manage is not supported. \n please add an account',
    create: 'Add new assistant group(s)',
    createAccount: 'Add new assistant account',
    priority: 'Prior Service Scope',
    edit: 'Edit',
    add: 'Add',
    savePrioritySucceed: 'Save prior service scope succeed',
    savePriorityFailed: 'Save prior service scope failed, please try again later',
    description: 'Guide',
    singleUndistributedStore: (name: string) => (
      <span>
        {`Buyers' conversation cannot be accessed by any assiatants as store `}
        <Highlight>{name}</Highlight>
        {` not in any of groups. Please deal with it in time.`}
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        {`Buyers' conversation cannot be accessed by any assiatants as there are `}
        <Highlight>{count}</Highlight>
        {` stores not in any of groups. Please deal with it in time.`}
      </span>
    ),
    客服分配说明: 'Assistant distribute description',
    共x个分组: 'Total {{x} group(s)',
    客服组配置hint:
      'Sinusuportahan lamang ang mga platform ng Shopee at Lazada para sa configuration ng pangkat ng serbisyo sa customer',
    负责店铺: 'platform',
    负责WABA帐号: 'WhatsApp account',
    更多x帐号: (x: number) => `More ${x} WhatsApp account`,
    descriptionRenderer: () => (
      <Description>
        <h3>Customers will be allocated by following rules:</h3>
        <div>
          <p>
            1. Customers who inquried an assistant
            <span> recently </span>
            will be allocated directly to the assistant if he is
            <span> online</span>.
          </p>
          <p>
            2. Customers who did not inquired for 3 days will be allocated into an assistant group
            by his
            <span> historical behavior </span>
            or
            <span> latest order status</span>.
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                If the customer can be alloted to more than one assiatant group with different
                <span> prior service list</span>, it shall be judged that each
                <span> prior service list </span>
                has a corresponding assistant group or not, and customers in
                <span> prior service list </span>
                will be judged first.
              </p>
              <p>
                <span>
                  Or if the customer is in different prior service lists and no assiatants serve
                  him, random assistant group shall serve him.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Assiatants will be
            <span> alloted </span>
            based on how busy they are.
          </p>
        </div>
      </Description>
    ),
    showTotal: (total: number) => `Total ${total} items`,
    更多x家店铺: (total: number) => `More ${total} stores`,
    '当前组内无客服，买家将无法分配至该客服组':
      'There is currently no customer service, the buyer will not be able to be assigned to this customer service group',
    '若存在多位启用中客服时，买家 将会平均分配':
      'If there are multiple active agents, buyers will be evenly distributed',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'If there is only 1 active customer service in the customer service group, all buyers will be assigned to this customer service for processing',
    tradeStatus: {
      NO_TRADE: 'Placed no orders',
      NO_PAY: 'Unpaid',
      WAIT_SHIP: 'To Ship',
      SHIPPING: 'Shipping',
      RECENT_TRADED: 'Completed within 7 days',
      REFUND: 'Return/refund',
    },
    buyerTag: {
      NEW: 'New Customer',
      OLD: 'Regular Customer',
    },
    receptionScopeType: {
      ALL_BUYER: 'All customers',
      TRADE_STATUS: 'By Order Status',
      BUYER_LABEL: "By Customer's Label",
    },
    substituteGroupType: {
      UNALTERNATIVE: 'No alternative assistants',
      ALL_GROUP: 'All assistant groups',
      CERTAIN_GROUP: 'Choose assistant group(s)',
    },
    field: {
      tradeStatus: 'Order status',
      buyerTag: "Buyer' Label",
      name: 'Assistant group name',
      userIds: 'Choose assiatant(s)',
      storeIds: 'Choose store(s)',
      receptionScope: 'Service list',
      substituteGroup: 'Alternative agent',
      负责平台: 'Platform',
    },
    placeholder: {
      name: 'Enter name here',
      userIds: 'Please choose assistants',
      storeIds: (platform: string) => `Mangyaring pumili ng (mga) tindahan ng ${platform}`,
      substituteGroup: 'Please select assistant group',
      whatsapp: 'Please choose WhatsApp account',
    },
    validate: {
      nameEmpty: 'Please input assistant group name',
      nameLimit: (limit: number | string) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      userEmpty: 'Please select assistant',
      storeEmpty: 'Please select store',
      tradeStatusEmpty: 'Please select order status',
      buyerTagEmpty: "Please select buyer's label",
      groupEmpty: 'Please select assistant group',
    },
    save: 'Save',
    priorityCancelConfirm: 'Are you sure to quit edit prior service scope?',
    undistributedAccount: 'Not in any assistant groups',
    distributedToAllBuyerStore:
      'The service list of your store has been set as All assistant groups. ',
    undistributedStore: 'Walang available na assistant groups',
    fetchAccountListFailed: 'Nag-fail ang pag-load ng assistant list, maaaring subukan ulit mamaya',
    fetchStoreListFailed: 'Nag-fail ang pag-load ng store list, maaaring subukan ulit mamaya',
    basicInfo: 'Basic Information',
    groupConfig: 'Assiatants group settings',
    editGroup: 'Edit assistant group',
    cancelEditGroupConfirm: 'Are you sure to quit creating a new assistant group?',
    deleteConfirm: 'Are you sure to delete the assistant group?',
    delete: 'Delete this group',
    accountCount: 'Assistants in the group',
    enableCount: (count: number | string) => `Open ${count} assistant(s)`,
    disableCount: (count: number | string) => `Close ${count} assistant(s)`,
    sleepCount: (count: number | string) => `Dormancy ${count} assistant(s)`,
    storePlatform: (platform: string) => `${platform} platform store`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'There is only one open customer service in the customer service group, all buyers will be assigned to this customer service for processing.',
    '当前存在多位启用中客服时，买家将会平均分配':
      'There are many open customer service in the customer service group, the buyers will be average distributed.',
    全部分配: 'All distribution',
    平均分配: 'Average distribution',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'The customer service who has not logged in to the system for 7 consecutive days will enter the dormant state and will no longer allocate the buyer. If you log in again, the buyer allocation will be restored.',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'There is no open customer service in the current group. The buyer of the selected applicable store cannot be assigned to this customer service group.',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'There is only one open customer service in the customer service group, all buyers will be assigned to this customer service for processing.',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'There are many open customer service in the customer service group, the buyers will be average distributed.',
    展开剩余x家: (count: number) => `View other ${count} stores`,
  },
  modals: {
    未设置: 'Unsetting',
    帐号信息: 'Account Information',
    change_phone_tips: 'You are modifying the phone number for logging in to {{phone}}，',
    '修改成功后，需使用新手机号进行登录':
      'After the modification, you need to use the new mobile phone number to log in',
    返回: 'Back',
    修改登录手机号: 'Modify phone number',
    请输入新手机号: 'Please enter new mobile number',
    确认修改: 'Confirm',
    修改手机号: 'Change phone number',
    设置手机号: 'Set phone number',
    修改帐号密码: 'Change account password',
    设置帐号密码: 'Set account password',
    用户信息: 'User Information',
    当前版本: 'Current Version',
    系统设置: 'System Settings',
    ' 关于ChatPlusAI': 'About ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Welcome to ChatPlusAI',
    公司信息: 'Company Information',
    公司名称: 'Company Name',
    请填写公司名称: 'Please enter company name',
    开票信息: 'Invoicing Information',
    'register.ChatPlusAI': 'Register for ChatPlusAI',
    'register.form.become.admin.tips':
      'You will be a super administrator by default and have all super administrator privileges.',
    '操作失败，请稍后再试': 'Nag-fail ang operation, maaaring subukan ulit mamaya',
    正在发送验证码: 'Sending captcha',
    密码登录: 'Login with password',
    验证码登录: 'Login with captcha',
    登录中: 'logging in',
    '服务器开小差了，请稍后再试': 'Server busy, please try again later.',
    '登录失败，验证码错误': 'Nag-fail ang pag-login, mali ang captcha.',
    '密码太久未更新，请联系管理员更新密码':
      'Matagal ng hindi pa na-update ang password, maaaring i-contact ang administrator para i-update ito.',
    设置登录帐号: 'Set up login account',
    '正在修改登录手机号，请先完成身份认证。':
      'The login phone number is being modified, please complete the identity verification first.',
    '验证码已发送至：': 'Captcha sent to:',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Finding account online is not supported temporarily, please contact ChatPlusAI assistant.',
    手机号已停用: 'The phone number is disabled',
    下一步: 'Next',

    'account.rules.tips':
      'Setting the login account, which can only consist of numbers, English letters and special symbols(Except @), 1 to 20 characters in length. Only supports setting once, and cannot be changed after setting.',
    'password.rules.desc':
      'The password can only consist of numbers, English letters or special symbols, and must contain upper/lowercase letters, numbers, and special symbols, with a length of 8 to 20 characters',
    已设置: 'Has been set',
    设置成功: 'Set successfully',
    不支持修改: 'Modification is not supported',
    修改: 'Modify',
    身份验证: 'identity verification',
  },
  guide: {
    新增客服组: 'Create',
    组内客服: 'Assistants in the group',
    适用店铺: 'Choose store(s)',
    选择客服组: 'Choose store(s)',
    跳过: 'Skip',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'Pwedeng ma-classify ang customer service sa customer service groups para ma-improve ang efficiency ng pag-receive ng mga buyers',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Any agent must belong to at least one agent group or they will not be able to receive buyers.',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      "The store must belong to at least one customer service group, otherwise the store's buyer consultation will not have customer service reception.",
  },
};

export const es: typeof cn = {
  nav: {
    account: 'Account',
    group: 'Agent Group',
    department: 'Department',
  },
  account: {
    ...en.account,
    fetchFailed: 'Error en la obtención de la lista de cuentas, por favor, inténtelo más tarde',
    fetchSeatFailed:
      'Error en la obtención de la información del asiento, por favor, inténtelo más tarde',
    createAccount: 'Añadir nuevo(s) asistente(s)',
    showTotal: (total: number | string) => `Total ${total} artículos`,
    updateStatusSucceed: 'Actualizar el estado de la cuenta con éxito',
    updateStatusFailed: `Actualizar estado de cuenta falló, por favor inténtelo de nuevo más tarde`,
    deleteSucceed: 'Eliminar cuenta con éxito',
    deleteFailed: 'Error en la eliminación de la cuenta, inténtelo de nuevo más tarde',
    createAndCopy: 'Crear una cuenta y copiar',
    createSucceed: 'Crear cuenta con éxito',
    updateSucceed: 'Actualizar cuenta con éxito',
    createFailed: 'Falló la creación de la cuenta, por favor inténtelo de nuevo más tarde',
    updateFailed: 'Actualización de cuenta fallida, por favor, inténtelo de nuevo más tarde',
    copyAccountSucceed: 'Copiar información de la cuenta con éxito',
    copyAccountFailed:
      'Copiar información de la cuenta falló, por favor inténtelo de nuevo más tarde',
    updateNickSucceed: 'Actualizar el nombre de usuario de la cuenta con éxito',
    updateNickFailed:
      'Actualizar el nombre de usuario de la cuenta ha fallado, por favor inténtelo más tarde',
    updatePasswordSucceed: 'Actualizar la contraseña de la cuenta con éxito',
    updatePasswordFailed:
      'Error en la actualización de la contraseña de la cuenta, inténtelo de nuevo más tarde',
    validate: {
      ...en.account.validate,
      accountEmpty: 'Por favor, introduzca la cuenta',
      assistantGroups: en.account.validate.assistantGroups,
      accountLimit: (limit: number) =>
        `${limit} caracteres en máximo, se ha alcanzado el límite superior`,
      accountReg: 'La cuenta debe contener letras y no puede contener caracteres @',
      nickEmpty: 'Por favor, introduzca el nombre de usuario',
      nickLimit: (limit: number) =>
        `${limit} caracteres en máximo, se ha alcanzado el límite superior`,
      passwordEmpty: 'Por favor, introduzca la contraseña',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Su contraseña debe tener ${maxLimit}-${minLimit} caracteres`,
      oldPasswordEmpty: `Por favor, introduzca la antigua contraseña`,
      newPasswordEmpty: 'Por favor, introduzca la nueva contraseña',
      confirmPasswordEmpty: 'Por favor, vuelva a confirmar la nueva contraseña',
      confirmPassword: 'La segunda confirmación es inconsistente con la nueva contraseña',
      phoneEmpty: 'Por favor, introduzca el número de teléfono',
      phoneInvalid: 'Por favor, introduzca un número de teléfono válido',
      emailInvalid: 'Por favor, introduzca un correo electrónico válido',
      passwordNumber: 'Su contraseña debe contener un número',
      passwordLetter:
        'Su contraseña debe contener al menos una letra mayúscula o una letra minúscula',
      passwordSpecial: 'Su contraseña debe contener al menos un carácter especial (!, *, _, etc.)',
      passwordAccount: 'Tu contraseña no debe contener tu ID de ChatPlusAI',
    },
    field: {
      ...en.account.field,
      account: 'Cuenta',
      name: 'Nombre de usuario',
      password: 'Contraseña',
      oldPassword: 'Contraseña antigua',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Reconfirmar',
      index: 'No.',
      phone: 'Número de teléfono',
      email: 'Correo electrónico',
    },
    placeholder: {
      account: 'Por favor, introduzca la cuenta',
      name: 'Por favor, introduzca el nombre de usuario',
      password: 'Por favor, introduzca la contraseña',
      oldPassword: 'Por favor, introduzca la antigua contraseña',
      newPassword: 'Por favor, introduzca la nueva contraseña',
      confirmPassword: 'Por favor, introduzca la nueva contraseña de nuevo',
      phone: 'Por favor, introduzca el número de teléfono',
      email: 'Por favor, introduzca el correo electrónico',
      group: 'Please select assistant group',
    },
    role: {
      ASSISTANT: 'Asistente',
      SUPER_ADMIN: 'Administrador',
    },
    save: 'Guardar',
    cancelCreateConfirm: '¿Estás seguro de que quieres dejar de crear una nueva cuenta?',
    edit: 'Editar',
    cancelUpdateNickConfirm: '¿Estás seguro de dejar de cambiar el nombre de usuario?',
    updatePassword: 'Cambiar contraseña',
    delete: 'Borrar',
    deleteConfirm: '¿Está seguro de eliminar esta cuenta?',
    enable: 'Activar',
    disable: ' Desactivar',
    disableConfirm: '¿Está seguro de cerrar esta cuenta?',
    superAdminPlaceholder: 'Los administradores no están en ningún grupo.',
    operation: 'Operación',
    总坐席n位: 'Total [<num>{{n}}</num>]',
    启用n位: 'Enable [<num>{{n}}</num>]',
    '订购到期，请先订购服务套餐':
      'El servicio ha caducado, por favor, pida primero el paquete de servicios.',
    '试用到期，请先订购服务套餐':
      'La prueba ha caducado, solicite primero el paquete de servicios.',
    '当前坐席已达上限，请先订购客服坐席':
      'El número actual de asientos ha alcanzado el límite superior, por favor, pida primero los asientos del servicio de atención al cliente.',
    '当前试用已到期，请先订购服务套餐':
      'La prueba ha caducado, por favor, pide primero el paquete de servicios.',
    '当前订购已到期，请先订购服务套餐':
      'El servicio ha caducado, por favor pida primero el paquete de servicios.',
    去订购: 'Go to order',
  },
  group: {
    ...en.group,
    '当前组内无客服，买家将无法分配至该客服组':
      en.group['当前组内无客服，买家将无法分配至该客服组'],
    emptyAccount: en.group.emptyAccount,
    客服分配说明: en.group['客服分配说明'],
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      en.group['若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理'],
    '若存在多位启用中客服时，买家 将会平均分配':
      en.group['若存在多位启用中客服时，买家 将会平均分配'],
    fetchFailed: 'Error al obtener la lista de grupos de asistentes, inténtelo de nuevo más tarde',
    fetchPriorityFailed:
      'Error al obtener el alcance del servicio anterior, inténtelo de nuevo más tarde',
    deleteSucceed: 'Eliminar el grupo de asistentes con éxito',
    createAccount: 'Añadir nuevo(s) asistente(s)',
    deleteFailed: 'Falló la eliminación del grupo de asistentes, inténtalo de nuevo más tarde',
    saveSucceed: 'Guardar el grupo de asistentes con éxito',
    saveFailed: 'Error al guardar el grupo de asistentes, inténtalo de nuevo más tarde',
    empty: 'No hay grupos de asistentes disponibles ahora.',
    create: 'Agregar nuevos grupos de asistentes',
    priority: 'Alcance del servicio anterior',
    edit: 'Editar',
    add: 'Agregar',
    savePrioritySucceed: 'Guardar el alcance del servicio anterior con éxito',
    savePriorityFailed:
      'Error al guardar el alcance del servicio anterior, inténtelo de nuevo más tarde',
    description: 'Instrucciones',
    singleUndistributedStore: (name: string) => (
      <span>
        {`Ningún asistente no puede acceder a la conversación de los compradores como tienda `}
        <Highlight>{name}</Highlight>
        {` no en ninguno de los grupos. Por favor, trátelo a tiempo.`}
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        {`Ningún asistente no puede acceder a la conversación de los compradores, ya que hay `}
        <Highlight>{count}</Highlight>
        {` no se almacena en ninguno de los grupos. Por favor, trátelo a tiempo.`}
      </span>
    ),
    showTotal: (total: number) => `Total ${total} elementos`,
    tradeStatus: {
      NO_TRADE: 'No se realizaron pedidos',
      NO_PAY: 'No pagado',
      WAIT_SHIP: 'Para enviar',
      SHIPPING: 'Envío',
      RECENT_TRADED: 'Completado en 7 días',
      REFUND: 'Devolución/reembolso',
    },
    buyerTag: {
      NEW: 'Nuevo Cliente',
      OLD: 'Cliente habitual',
    },
    receptionScopeType: {
      ALL_BUYER: 'Todos los compradores',
      TRADE_STATUS: 'Por estado del pedido',
      BUYER_LABEL: 'Por etiqueta del comprador',
    },
    substituteGroupType: {
      UNALTERNATIVE: 'No hay asistentes alternativos',
      ALL_GROUP: 'Todos los grupos de asistentes',
      CERTAIN_GROUP: 'Elegir grupo(s) de asistentes',
    },
    field: {
      tradeStatus: 'Estado del pedido',
      buyerTag: 'Etiqueta del comprador',
      name: 'Nombre del grupo de asistentes',
      userIds: 'Elija asiatante(s)',
      storeIds: 'Elegir tienda(s)',
      负责平台: 'Elegir plataforma',
      receptionScope: 'Lista de servicios',
      substituteGroup: 'Asistentes alternativos',
    },
    placeholder: {
      name: 'Introduzca el nombre aquí',
      userIds: 'Por favor, elija asistentes',
      storeIds: (platform: string) => `Elija tiendas ${platform}`,
      whatsapp: 'por favor seleccione la cuenta de WhatsApp',
      substituteGroup: 'Por favor, seleccione el grupo de asistentes',
    },
    validate: {
      nameEmpty: 'Por favor, introduzca el nombre del grupo de asistentes',
      nameLimit: (limit: number | string) =>
        `${limit} caracteres en máximo, se ha alcanzado el límite superior`,
      userEmpty: 'Por favor, seleccione el asistente',
      storeEmpty: 'Por favor, seleccione tienda',
      tradeStatusEmpty: 'Por favor, seleccione el estado del pedido',
      buyerTagEmpty: 'Por favor, seleccione la etiqueta del comprador',
      groupEmpty: 'Por favor, seleccione el grupo del asistente',
    },
    save: 'Guardar',
    客服组配置hint:
      'Solo es compatible con las plataformas Shopee y Lazada para la configuración del grupo de atención al cliente',
    priorityCancelConfirm: '¿Está seguro de dejar de editar el ámbito de servicio anterior?',
    undistributedAccount: 'No está en ningún grupo de asistentes',
    distributedToAllBuyerStore:
      'La lista de servicios de su tienda se ha establecido como Todos los grupos de asistentes. ',
    undistributedStore: 'No hay grupos de asistentes disponibles',
    fetchAccountListFailed:
      'La lista de asistentes ha fallado, por favor inténtelo de nuevo más tarde',
    fetchStoreListFailed:
      'Falló la obtención de la lista de tiendas, por favor inténtelo de nuevo más tarde',
    basicInfo: 'Información básica',
    groupConfig: 'Configuración del grupo de asistentes',
    editGroup: 'Editar grupo de asistentes',
    cancelEditGroupConfirm: '¿Está seguro de dejar de crear un nuevo grupo de asistentes?',
    deleteConfirm: '¿Está seguro de eliminar el grupo de asistentes?',
    delete: 'Eliminar este grupo',
    accountCount: 'Asistentes en el grupo',
    负责店铺: 'Tienda',
    负责WABA帐号: 'conta WhatsApp',
    更多x帐号: (x: number) => `Más ${x} cuenta de WhatsApp`,
    // enableCount: (count: number | string) => `Habilitar ${count} asistente(s)`,
    // disableCount: (count: number | string) => `Cerrar ${count} asistente(s)`,
    // sleepCount: (count: number | string) => `Dormir ${count} asistente(s)`,
    // storePlatform: (platform: string) => `${platform} platform store`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'Sólo hay un servicio de atención al cliente abierto en el grupo de servicios de atención al cliente, todos los compradores serán asignados a este servicio de atención al cliente para su procesamiento.',
    '当前存在多位启用中客服时，买家将会平均分配':
      'Hay muchos servicios de atención al cliente abiertos en el grupo de atención al cliente, los compradores se distribuirán de forma media.',
    全部分配: 'Toda la distribución',
    平均分配: 'Distribución media',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'El servicio de atención al cliente que no haya iniciado sesión en el sistema durante 7 días consecutivos entrará en estado inactivo y dejará de asignar al comprador. Si vuelve a iniciar sesión, se restablecerá la asignación del comprador.',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'No hay ningún servicio de atención al cliente abierto en el grupo actual. El comprador de la tienda aplicable seleccionada no puede ser asignado a este grupo de servicio al cliente.',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'Sólo hay un servicio al cliente abierto en el grupo de servicio al cliente, todos los compradores serán asignados a este servicio al cliente para su procesamiento.',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'Hay muchos servicios de atención al cliente abiertos en el grupo de servicios de atención al cliente, los compradores se distribuirán de forma media.',
    展开剩余x家: (count: number) => `Ver otras tiendas ${count}`,
    descriptionRenderer: () => (
      <Description>
        <h3>Los clientes se asignarán según las siguientes reglas:</h3>
        <div>
          <p>
            1. Clientes que consultaron a un asistente
            <span> recientemente </span>
            se asignará directamente al asistente si es
            <span> en línea</span>.
          </p>
          <p>
            2. Los clientes que no consultaron durante 3 días serán asignados a un grupo asistente
            por su
            <span> comportamiento histórico </span>o<span> estado del último pedido</span>.
          </p>
          <Tips>
            <span>Consejos：</span>
            <div>
              <p>
                Si el cliente puede ser asignado a más de un grupo de asistentes con diferentes
                <span> lista de servicios anteriores</span>, se considerará que cada
                <span> lista de servicios anteriores </span>
                tiene un grupo asistente correspondiente o no, y los clientes en
                <span> lista de servicios anteriores </span>
                será juzgado primero.
              </p>
              <p>
                <span>
                  O si el cliente está en diferentes listas de servicios anteriores y no atienden
                  asistentes él, un grupo asistente al azar le servirá.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Los asistentes serán
            <span> asignado </span>
            en función de lo ocupados que estén.
          </p>
        </div>
      </Description>
    ),
  },
  modals: {
    未设置: 'Desactivado',
    帐号信息: 'Información de la cuenta',
    change_phone_tips: 'Estás modificando el número de teléfono para iniciar sesión en {{phone}}，',
    '修改成功后，需使用新手机号进行登录':
      'Después de la modificación, es necesario utilizar el nuevo número de teléfono móvil para iniciar la sesión',
    返回: 'Volver',
    修改登录手机号: 'Modificar número de teléfono',
    请输入新手机号: 'Introduzca un nuevo número de móvil',
    确认修改: 'Confirme la modificación',
    修改手机号: 'Modificar número de teléfono',
    设置手机号: 'Establecer número de teléfono',
    修改帐号密码: 'Cambiar la contraseña de la cuenta',
    设置帐号密码: 'Establecer contraseña de la cuenta',
    用户信息: 'Información del usuario',
    当前版本: 'Versión actual',
    系统设置: 'Configuración del sistema',
    ' 关于ChatPlusAI': 'Acerca del ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Bienvenido al ChatPlusAI',
    公司信息: 'Información de la empresa',
    公司名称: 'Nombre de la empresa',
    请填写公司名称: 'Introduzca el nombre de la empresa',
    开票信息: 'Información de facturación',
    'register.ChatPlusAI': 'Registro en el ChatPlusAI',
    'register.form.become.admin.tips':
      'Serás un súper administrador por defecto y tendrás todos los privilegios de súper administrador.',
    '操作失败，请稍后再试': 'Operación fallida, por favor, inténtalo más tarde.',
    正在发送验证码: 'Enviando código de verificación',
    密码登录: 'Iniciar sesión con contraseña',
    验证码登录: 'Acceder con código',
    登录中: 'iniciar sesión',
    '服务器开小差了，请稍后再试': 'Servidor ocupado, inténtelo de nuevo más tarde.',
    '登录失败，验证码错误': 'Login failed, captcha is wrong.',
    '密码太久未更新，请联系管理员更新密码':
      'La contraseña no se ha actualizado durante mucho tiempo, por favor, póngase en contacto con el administrador para actualizarla.',
    设置登录帐号: 'Configurar cuenta de acceso',
    '正在修改登录手机号，请先完成身份认证。':
      'El número de teléfono de inicio de sesión se está modificando, por favor complete la verificación de identidad primero.',
    '验证码已发送至：': 'Código de verificación enviado a:',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Encontrar la cuenta en línea no es compatible temporalmente, por favor, póngase en contacto con el asistente de ChatPlusAI.',
    手机号已停用: 'El número de teléfono está desactivado',
    下一步: 'Siguiente',

    'account.rules.tips':
      'Establecer la cuenta de acceso, que sólo puede consistir en números, letras inglesas y símbolos especiales(Excepto @), de 1 a 20 caracteres de longitud. Sólo se puede establecer una vez, y no se puede cambiar después de establecerla',
    'password.rules.desc':
      'La contraseña sólo puede estar formada por números, letras inglesas o símbolos especiales, y debe contener letras mayúsculas/minúsculas, números y símbolos especiales, con una longitud de 8 a 20 caracteres',
    已设置: 'Se ha establecido',
    设置成功: 'Establecido con éxito',
    不支持修改: 'No se admite la modificación',
    修改: 'Modificación',
    身份验证: 'verificación de identidad',
  },
  guide: {
    新增客服组: 'Create',
    组内客服: 'Assistants in the group',
    适用店铺: 'Choose store(s)',
    选择客服组: 'Choose store(s)',
    跳过: 'Skip',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'You can classify customer service through customer service groups to improve the efficiency of receiving buyers',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Any agent must belong to at least one agent group or they will not be able to receive buyers.',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      "The store must belong to at least one customer service group, otherwise the store's buyer consultation will not have customer service reception.",
  },
};

export const vn = {
  nav: {
    account: 'Tài khoản CSKH',
    group: 'Nhóm CSKH',
    department: 'Quản lý bộ phận',
  },
  account: {
    fetchFailed: 'Nhận danh sách tài khoản thất bại, hãy thử lại',
    fetchSeatFailed: 'Nhận thông tin chỗ ngồi thất bại, hãy thử lại',
    createAccount: 'Dịch vụ chăm sóc khách hàng mới',
    showTotal: (total: number | string) => `gồm ${total} mục`,
    updateStatusSucceed: 'Lưu trạng thái chăm sóc khách hàng thành công',
    updateStatusFailed: 'Lưu trạng thái chăm sóc khách hàng thất bại, hãy thử lại',
    deleteSucceed: 'Xóa chăm sóc khách hàng thành công',
    deleteFailed: 'Xóa chăm sóc khách hàng thất bại, hãy thử lại',
    createAndCopy: 'Lập tài khoản và sao chép',
    createSucceed: 'Lập tài khoản mới thành công',
    updateSucceed: 'Chỉnh sửa tài khoản thành công',
    createFailed: 'Lập tài khoản mới thất bại, hãy thử lại',
    updateFailed: 'Chỉnh sửa tài khoản thất bại, hãy thử lại',
    copyAccountSucceed: 'Đã sao chép thông tin tài khoản',
    copyAccountFailed: 'Sao chép thông tin tài khoản thất bại',
    updateNickSucceed: 'Sửa tên thành công',
    updateNickFailed: 'Sửa tên thất bại, hãy thử lại',
    updatePasswordSucceed: 'Sửa mật khẩu thành công',
    updatePasswordFailed: 'Sửa mật khẩu thất bại, hãy thử lại',
    validate: {
      accountEmpty: 'Hãy nhập tài khoản',
      accountLimit: (limit: number) => `Tài khoản không được vượt quá ${limit} kí tự`,
      accountReg: 'Tài khoản bắt buộc phải có chữ cái và không được có kí tự：@',
      nickEmpty: 'Hãy nhập tên',
      assistantGroups: 'Hãy chọn nhóm chăm sóc khách hàng',
      nickLimit: (limit: number) => `Tên không được vượt quá ${limit} kí tự`,
      passwordEmpty: 'Hãy nhập mật khẩu',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Hãy nhập ${minLimit}-${maxLimit} kí tự mật khẩu`,
      oldPasswordEmpty: 'Hãy nhập mật khẩu cũ',
      newPasswordEmpty: 'Hãy nhập mật khẩu mới',
      confirmPasswordEmpty: 'Hãy nhập mật khẩu xác nhận lần 2',
      confirmPassword: 'Mật khẩu xác nhận lần 2 không khớp với mật khẩu mới',
      phoneEmpty: 'Hãy nhập số điện thoại',
      phoneInvalid: 'Hãy nhập số điện thoại chính xác',
      emailInvalid: 'Hãy nhập email hợp lệ',
      passwordNumber: 'Mật khẩu bắt buộc bao gồm một chữ cái',
      passwordLetter: 'Mật khẩu bắt buộc bao gồm một chữ cái thường/in hoa',
      passwordSpecial: 'Mật khẩu bắt buộc bao gồm 1 kí hiệu đặc biệt (ví dụ như*,.)',
      passwordAccount: 'Mật khẩu không được chứa tên tài khoản',
    },
    field: {
      account: 'Tài khoản',
      name: 'Tên',
      password: 'Mật khẩu đăng nhập',
      oldPassword: 'Mật khẩu cũ',
      newPassword: 'Mật khẩu mới',
      confirmPassword: 'Xác nhận mật khẩu đăng nhập',
      index: 'STT',
      phone: 'Số điện thoại',
      email: 'Email đăng nhập',
    },
    placeholder: {
      account: '6-16 kí tự，chỉ được đặt 1 lần',
      name: 'Nhập tên',
      password: 'Nhập mật khẩu',
      oldPassword: 'Nhập mật khẩu cũ',
      newPassword: 'Nhập mật khẩu mới',
      confirmPassword: 'Nhập lại mật khẩu mới',
      phone: 'Nhập số điện thoại',
      email: 'Nhập email',
      group: 'Chọn nhóm chăm sóc khách hàng',
    },
    role: {
      ASSISTANT: 'Chăm sóc khách hàng',
      SUPER_ADMIN: 'Nhân viên quản lý',
    },
    save: 'Xác nhận',
    cancelCreateConfirm: 'Bạn xác nhận từ bỏ tài khoản mới?',
    edit: 'Chỉnh sửa',
    cancelUpdateNickConfirm: 'Bạn xác nhận từ bỏ thay đổi tên?',
    updatePassword: 'Thay đổi mật khẩu',
    delete: 'Xóa',
    deleteConfirm: 'Bạn xác nhận xóa tài khoản này?',
    enable: 'Sử dụng',
    disable: 'Ngừng sử dụng',
    disableConfirm: 'Bạn xác nhận ngừng sử dụng tài khoản này?',
    superAdminPlaceholder: 'Nhân viên quản lý không tham gia',
    operation: 'Thao tác',
    add: 'Tạo mới',
    我知道了: 'Tôi đã hiểu',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'Bất cứ chăm sóc khách hàng đều phải thuộc một nhóm chăm sóc khách hàng\n nếu không họ sẽ không thể tiếp đón người mua.',
    总坐席n位: 'Tổng có [<num>{{n}}</num>]',
    启用n位: 'Kích hoạt [<num>{{n}}</num>]',
    '订购到期，请先订购服务套餐': 'Dịch vụ hết hạn, hãy đặt mua combo dịch vụ trước đã',
    '试用到期，请先订购服务套餐': 'Hết hạn dùng thử, hãy đặt mua combo dịch vụ trước đã',
    '当前坐席已达上限，请先订购客服坐席':
      'Chỗ ngồi hiện tại đã đạt giới hạn, hãy đặt mua chỗ ngồi dịch vụ trước đã',
    '当前试用已到期，请先订购服务套餐':
      'Hiện tại đã hết hạn dùng thử, hãy đặt mua combo dịch vụ trước đã',
    '当前订购已到期，请先订购服务套餐':
      'Hiện tại đã hết hạn dịch vụ, hãy đặt mua combo dịch vụ trước đã',
    去订购: 'Đến mua',
  },
  group: {
    fetchFailed: 'Tiếp nhận danh sách chăm sóc khách hàng thất bại, hãy thử lại sau',
    fetchPriorityFailed: 'Tiếp nhận phạm vi phục vụ ưu tiên thất bại, hãy thử lại sau',
    deleteSucceed: 'Xóa nhóm chăm sóc khách hàng thành công',
    deleteFailed: 'Xóa nhóm chăm sóc khách hàng thất bại, hãy thử lại sau',
    saveSucceed: 'Lưu nhóm chăm sóc khách hàng thành công',
    saveFailed: 'Lưu nhóm chăm sóc khách hàng thất bại, hãy thử lại sau',
    empty: 'Hiện tại chưa có nhóm chăm sóc khách hàng',
    emptyAccount:
      'Tạm thời vẫn chưa có tài khoản chăm sóc khách hàng, không cho phép tiến hành quản lý nhóm chăm sóc khách hàng\n Hãy hoàn thành tạo tài khoản chăm sóc khách hàng mới trước đã',
    create: 'Nhóm CSKH mới',
    createAccount: 'Đến tài khoản chăm sóc khách hàng mới tạo',
    priority: 'Phạm vi phục vụ ưu tiên',
    edit: 'Chỉnh sửa',
    add: 'Thêm',
    savePrioritySucceed: 'Lưu phạm vi phục vụ ưu tiên thành công',
    savePriorityFailed: 'Lưu phạm vi phục vụ ưu tiên thất bại, hãy thử lại sau',
    description: 'Chỉ dẫn kiểm tra',
    singleUndistributedStore: (name: string) => (
      <span>
        店铺：
        <Highlight>{name}</Highlight>
        未在任何客服组，买家消息无法分配至客服，请及时处理
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        当前有
        <Highlight>{count}</Highlight>
        个店铺未在任何客服组，买家消息无法分配至客服，请及时处理
      </span>
    ),
    客服分配说明: 'Giới thiệu phân chia chăm sóc khách hàng',
    共x个分组: `Có {{x}} nhóm`,
    descriptionRenderer: () => (
      <Description>
        <h3> Sắp xếp thế nào khi người mua cần tư vấn？</h3>
        <div>
          <p>
            1. Người mua
            <span> gần đây có liên hệ CSKH</span>, và
            <span> online </span>, trực tiếp phân cho CSKH đã tư vấn trước đó
          </p>
          <p>
            2. Người mua 3 ngày gần đây không liên hệ CSKH, dựa theo <span> lịch sử thao tác </span>{' '}
            và <span> đơn hàng mới cập nhật trạng thái </span> của người mua, phân cho nhóm CSKH có{' '}
            <span> phạm vi tiếp đón </span> tương ứng.
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                Nếu người mua thỏa mãn nhiều <span> phạm vi tiếp đón </span> của nhóm CSKH, mỗi{' '}
                <span> phạm vi tiếp đón </span> phán đoán có nhóm CSKH tương ứng không, trong đó{' '}
                <span> phạm vi tiếp đón ưu tiên </span> sẽ được ưu tiên.
              </p>
              <p>
                <span>
                  Nếu phạm vi tiếp đón mà người mua đủ điều kiện không có nhóm CSKH phù hợp, thì
                  chuyển tới một nhóm CSKH ngẫu nhiên của cửa hàng trực thuộc.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Chúng tôi sẽ dựa theo lượng công việc để <span> phân CSKH trong nhóm CSKH </span>
          </p>
        </div>
      </Description>
    ),

    '当前组内无客服，买家将无法分配至该客服组':
      'Hiện tại trong nhóm không có chăm sóc khách hàng, người mua không thể chuyển tới nhóm chăm sóc khách hàng này',
    '若存在多位启用中客服时，买家 将会平均分配':
      'Nếu đồng thời tồn tại nhiều chăm sóc khách hàng đang hoạt động, người mua sẽ được phân chia bình quân',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'Nếu trong nhóm chăm sóc khách hàng chỉ có một chăm sóc khách hàng đang hoạt động,  tất cả người mua sẽ được giao hết cho chăm sóc khách hàng này xử lý',
    showTotal: (total: number) => `Gồm ${total} mục`,
    更多x家店铺: (total: number) => `Thêm ${total} cửa hàng`,
    tradeStatus: {
      NO_TRADE: 'Không có đơn hàng',
      NO_PAY: 'Chưa thanh toán',
      WAIT_SHIP: 'Đợi xuất hàng',
      SHIPPING: 'Đang vận chuyển',
      RECENT_TRADED: 'Đã hoàn thành trong 7 ngày',
      REFUND: 'Trả hàng hoàn tiền',
    },
    buyerTag: {
      NEW: 'Khách mới',
      OLD: 'Khách cũ',
    },
    receptionScopeType: {
      ALL_BUYER: 'Toàn bộ người mua',
      TRADE_STATUS: 'Dựa theo trạng thái đơn hàng',
      BUYER_LABEL: 'Dựa theo thẻ người mua',
    },
    substituteGroupType: {
      UNALTERNATIVE: 'Không được tiếp nhận thay thế',
      ALL_GROUP: 'Toàn bộ nhóm chăm sóc khách hàng',
      CERTAIN_GROUP: 'Nhóm chăm sóc khách hàng chỉ định',
    },
    field: {
      tradeStatus: 'Trạng thái đơn hàng',
      buyerTag: 'Thẻ người mua',
      name: 'Tên nhóm chăm sóc khách hàng',
      userIds: 'Lựa chọn chăm sóc khách hàng',
      storeIds: 'Cửa hàng thích hợp',
      负责平台: 'Chọn nền tảng',
      receptionScope: 'Phạm vi phục vụ',
      substituteGroup: 'Tiếp nhận thay thế khi bận',
    },
    placeholder: {
      name: 'Nhập tên',
      userIds: 'Lựa chọn chăm sóc khách hàng',
      storeIds: (platform: string) => `Vui lòng chọn cửa hàng ${platform}`,
      whatsapp: 'vui lòng chọn tài khoản WhatsApp',
      substituteGroup: 'Lựa chọn nhóm chăm sóc khách hàng',
    },
    validate: {
      nameEmpty: 'Nhập tên nhóm chăm sóc khách hàng',
      nameLimit: (limit: number | string) =>
        `Tên nhóm chăm sóc khách hàng không được vượt quá ${limit} chữ`,
      userEmpty: 'Lựa chọn chăm sóc khách hàng',
      storeEmpty: 'Lựa chọn cửa hàng thích hợp',
      tradeStatusEmpty: 'Lựa chọn trạng thái đơn hàng',
      buyerTagEmpty: 'Lựa chọn thẻ người mua',
      groupEmpty: 'Lựa chọn nhóm chăm sóc khách hàng chỉ định',
    },
    save: 'Lưu',
    客服组配置hint: 'Chỉ hỗ trợ nền tảng Shopee và Lazada cho cấu hình nhóm dịch vụ khách hàng',
    priorityCancelConfirm: 'Bạn chắc chắn từ bỏ thay đổi phạm vi phục vụ ưu tiên?',
    undistributedAccount: 'Chưa phân chia',
    distributedToAllBuyerStore:
      'Cửa hàng này đã có phạm vi phục vụ là nhóm chăm sóc khách hàng tất cả người mua',
    undistributedStore: 'Không có nhóm chăm sóc khách hàng',
    fetchAccountListFailed: 'Tiếp nhận danh sách chăm sóc khách hàng thất bại, hãy thử lại sau',
    fetchStoreListFailed: 'Tiếp nhận danh sách cửa hàng thích hợp thất bại, hãy thử lại sau',
    basicInfo: 'Thông tin cơ bản',
    groupConfig: 'Bố trí nhóm chăm sóc khách hàng',
    editGroup: 'Chỉnh sửa nhóm chăm sóc khách hàng',
    cancelEditGroupConfirm: 'Bạn chắc chắn từ bỏ chỉnh sửa nhóm chăm sóc khách hàng?',
    deleteConfirm: 'Bạn chắc chắn muốn xóa nhóm chăm sóc khách hàng này?',
    delete: 'Xóa nhóm',
    accountCount: 'CSKH trong nhóm',
    负责店铺: 'Cửa hàng',
    负责WABA帐号: 'Tài khoản WhatsApp',
    更多x帐号: (x: number) => `Thêm tài khoản WhatsApp ${x}`,
    enableCount: (count: number | string) => `Sử dụng ${count} người`,
    disableCount: (count: number | string) => `Ngừng sử dụng ${count} người`,
    sleepCount: (count: number | string) => `Nghỉ ngơi ${count} người`,
    storePlatform: (platform: string) => `${platform} cửa hàng nền tảng`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'Hiện tại trong nhóm chỉ có 1 chăm sóc khách hàng đang hoạt động, tất cả người mua sẽ được giao cho chăm sóc khách hàng này xử lý',
    '当前存在多位启用中客服时，买家将会平均分配':
      'Hiện tại có nhiều chăm sóc khách hàng đang hoạt động, người mua sẽ được phân chia bình quân',
    全部分配: 'Phân chia toàn bộ',
    平均分配: 'Phân chia bình quân',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'chăm sóc khách hàng không đăng nhập vào hệ thống liên tục trong 7 ngày, sẽ không được phân chia người mua, lần đăng nhập tiếp theo sẽ phục hồi phân chia người mua',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'Hiện tại trong nhóm không có chăm sóc khách hàng đang hoạt động, người mua đã lựa chọn cửa hàng thích hợp không thể được phân chia tới nhóm',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'Hiện tại trong nhóm chỉ có 1 chăm sóc khách hàng đang hoạt động, tất cả người mua đã lựa chọn cửa hàng thích hợp sẽ được phân chia cho chăm sóc khách hàng này xử lý',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'Hiện tại trong nhóm có nhiều chăm sóc khách hàng đang hoạt động, người mua đã lựa chọn cửa hàng thích hợp sẽ được phân chia bình quân',
    展开剩余x家: (count: number) => `Triển khai còn thừa ${count} cửa hàng`,
  },
  modals: {
    未设置: 'Chưa cài đặt',
    帐号信息: 'Thông tin tài khoản',
    change_phone_tips: 'Bạn đang thay đổi số điện thoại đăng nhập {{phone}}',
    '修改成功后，需使用新手机号进行登录':
      'Sau khi thay đổi thành công, cần dùng số điện thoại mới đăng nhập',
    返回: 'Quay lại',
    修改登录手机号: 'Thay đổi số điện thoại đăng nhập',
    请输入新手机号: 'Nhập số điện thoại mới',
    确认修改: 'Xác nhận thay đổi',
    修改手机号: 'Thay đổi số điện thoại',
    设置手机号: 'Cài đặt số điện thoại',
    修改帐号密码: 'Thay đổi mật khẩu tài khoản',
    设置帐号密码: 'Cài đặt mật khẩu tài khoản',
    用户信息: 'Thông tin người dùng',
    当前版本: 'Phiên bản hiện tại',
    系统设置: 'Cài đặt hệ thống',
    ' 关于ChatPlusAI': 'Về ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Chào mừng đến với ChatPlusAI',
    公司信息: 'Thông tin công ty',
    公司名称: 'Tên công ty',
    请填写公司名称: 'Điền tên công ty',
    开票信息: 'Thông tin hóa đơn',
    'register.ChatPlusAI': 'Đăng ký ChatPlusAI',
    'register.form.become.admin.tips':
      'Bạn sẽ được mặc định trở thành nhân viên quản lý cao cấp, đồng thời có được tất cả quyền hạn của nhân viên quản lý cao cấp',
    '操作失败，请稍后再试': 'Thao tác thất bại, hãy thử lại sau',
    正在发送验证码: 'Đang gửi mã xác nhận',
    密码登录: 'Đăng nhập mật khẩu',
    验证码登录: 'Đăng nhập mã xác nhận',
    登录中: 'Đang đăng nhập',
    '服务器开小差了，请稍后再试': 'Máy chủ xảy ra lỗi, vui lòng thử lại',
    '登录失败，验证码错误': 'Đăng nhập thất bại, mã xác nhận không đúng',
    '密码太久未更新，请联系管理员更新密码':
      'Mật khẩu đã lâu không thay đổi, liên lạc nhân viên quản lý để đổi mật khẩu mới',
    设置登录帐号: 'Cài đặt tài khoản đăng nhập',
    '正在修改登录手机号，请先完成身份认证。':
      'Đang thay đổi số điện thoại đăng nhập, hãy xác minh thân phận trước.',
    '验证码已发送至：': 'Mã xác nhận đã được gửi tới：',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Tạm thời không cho phép tìm lại tài khoản trực tuyến, hãy liên hệ chăm sóc khách hàng của ChatPlusAI',
    手机号已停用: 'Số điện thoại đã ngừng sử dụng',
    下一步: 'Bước tiếp theo',
    // 需要补充
    'account.rules.tips':
      'Đang cài đặt tài khoản đăng nhập, chỉ được bao gồm số, chữ cái latinh và kí hiệu đặc biệt(ngoại trừ @), độ dài 1～20 kí tự. Chỉ được cài đặt 1 lần, sau khi cài đặt không thể thay đổi.',
    'password.rules.desc':
      'Mật khẩu chỉ được bao gồm số, chữ cái latinh và kí hiệu đặc biệt, đồng thời phải có chữ cái thường/in hoa, số và kí hiệu đặc biệt, độ dài 8~20 kí tự',
    已设置: 'Đã thiết lập',
    设置成功: 'Thiết lập thành công',
    不支持修改: 'Không được thay đổi',
    修改: 'Thay đổi',
    身份验证: 'Xác minh thân phận',
  },
  guide: {
    新增客服组: 'Nhóm chăm sóc khách hàng mới',
    组内客服: 'CSKH trong nhóm',
    适用店铺: 'Cửa hàng thích hợp',
    跳过: 'Bỏ qua',
    选择客服组: 'Lựa chọn nhóm chăm sóc khách hàng',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'Nhóm CSKH mới, bạn có thể phân loại nhóm CSKH để nâng cao hiệu quả dịch vụ',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Mỗi CSKH đều phải thuộc ít nhất 1 nhóm CSKH, nếu không sẽ không thể tiếp đón người mua',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      'Cửa hàng bắt buộc phải thuộc 1 nhóm CSKH, nếu không sẽ không có CSKH đón tiếp người mua',
  },
};

export const pt = {
  nav: {
    account: 'Contas Assistentes',
    group: 'Grupos de Assistentes',
    department: 'Gestão de departamentos',
  },
  account: {
    fetchFailed: 'Falha na obtenção da lista de contas, tente novamente mais tarde',
    fetchSeatFailed: 'Falha ao obter informações do assento. Tente novamente mais tarde',
    createAccount: 'Adicionar novo(s) assistente(s)',
    showTotal: (total: number | string) => `Total${total}itens`,
    updateStatusSucceed: 'Atualizar o status da conta com sucesso',
    updateStatusFailed: 'Falha na atualização do status da conta. Tente novamente mais tarde',
    deleteSucceed: 'Excluir conta com sucesso',
    deleteFailed: 'Falha na exclusão da conta, tente novamente mais tarde',
    createAndCopy: 'Criar uma conta e copiar',
    createSucceed: 'Criar conta com sucesso',
    updateSucceed: 'Editar a conta com sucesso',
    createFailed: 'Falha na criação da conta, tente novamente mais tarde',
    updateFailed: 'Falha na alteração da conta, tente novamente mais tarde试',
    copyAccountSucceed: 'A cópia das informações da conta foi bem-sucedida',
    copyAccountFailed: 'Falha na cópia das informações da conta. Tente novamente mais tarde',
    updateNickSucceed: 'Alteração do nome de usuário bem-sucedido',
    updateNickFailed: 'Falha ao alterar o nome de usuário',
    updatePasswordSucceed: 'Alteração de senha bem-sucedido',
    updatePasswordFailed: 'Falha ao alterar a senha, por favor tente mais tarde',
    validate: {
      accountEmpty: 'Insira a conta',
      accountLimit: (limit: number) =>
        `os carateres da conta não podem ultrassar ${limit} caracteres`,
      accountReg: 'A conta deve conter letras e não pode conter caracteres: @',
      nickEmpty: 'Insira o nome',
      assistantGroups: 'Selecione o grupo de assistente',
      nickLimit: (limit: number) => `o nome não pode ultrapassar ${limit} caracteres`,
      passwordEmpty: 'Insira a senha',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `Sua senha deve ter ${maxLimit}-${minLimit} caracteres`,
      oldPasswordEmpty: 'Insira a senha antiga',
      newPasswordEmpty: 'Insira a nova senha',
      confirmPasswordEmpty: 'Por favor, reconfirme a nova senha',
      confirmPassword: 'A segunda confirmação é inconsistente com a nova senha',
      phoneEmpty: 'Insira o número de telefone',
      phoneInvalid: 'Por favor, insira um número de telefone válido',
      emailInvalid: 'Por favor, insira o e-mail válido',
      passwordNumber: 'Sua senha deve conter pelo menos um número',
      passwordLetter: 'Sua senha deve conter pelo menos uma letra maiúscula ou uma letra minúscula',
      passwordSpecial: 'Sua senha deve conter pelo menos um caractere especial (!, *, _, etc.)',
      passwordAccount: 'Sua senha não deve conter seu ID do ChatPlusAI',
    },
    field: {
      account: 'Conta',
      name: 'Nome do usuário',
      password: 'Senha',
      oldPassword: 'Senha antiga',
      newPassword: 'Nova senha',
      confirmPassword: 'Confirmar a senha',
      index: 'No.',
      phone: 'Número de telefone',
      email: 'E-mail',
    },
    placeholder: {
      account: '6-16 caracteres, só pode ser definido uma vez',
      name: 'Insira o nome do usuário',
      password: 'Insira a senha',
      oldPassword: 'Insira a senha antiga',
      newPassword: 'Insira a nova senha antiga',
      confirmPassword: 'Insira a nova senha novamente',
      phone: 'Insira o número de telefone',
      email: 'Insira o e-mail',
      group: 'Selecione o grupo de assistente',
    },
    role: {
      ASSISTANT: 'Assistente',
      SUPER_ADMIN: 'Administrador',
    },
    save: 'Confirmar',
    cancelCreateConfirm: 'Tem certeza que quer parar de criar uma nova conta?',
    edit: 'Editar',
    cancelUpdateNickConfirm: 'Tem certeza de que deseja sair da mudança de nome de usuário?',
    updatePassword: 'Alterar a senha',
    delete: 'Excluir',
    deleteConfirm: 'Tem certeza de excluir esta conta？',
    enable: 'Ativado',
    disable: 'Fechar',
    disableConfirm: 'Tem certeza que quer para de usar essa conta?',
    superAdminPlaceholder: 'Os administradores não estão em nenhum grupo',
    operation: 'Operar',
    add: 'Adicionar',
    我知道了: 'Já sei',
    '任何客服都必须属于至少一个客服组否则他们将无法接待买家。':
      'Qualquer agente deve pertencer a pelo menos em \n grupo, ou não poderá lidar com compradores.',
    总坐席n位: 'Total de assentos [<num>{{n}}</num>] asstentos',
    启用n位: 'Ativar [<num>{{n}}</num>] assentos',
    '订购到期，请先订购服务套餐':
      'O serviço expirou, por favor, solicite o pacote de serviço primeiro',
    '试用到期，请先订购服务套餐':
      'A experiência de uso expirou, por favor, solicite o pacote de serviço primeiro',
    '当前坐席已达上限，请先订购客服坐席':
      '当O número atual de assentos atingiu o limite superior, por favor, solicite assentos de atendimento ao cliente primeiro',
    '当前试用已到期，请先订购服务套餐':
      'A experiência de uso expirou, por favor, solicite o pacote de serviço primeiro',
    '当前订购已到期，请先订购服务套餐':
      'O serviço expirou, por favor, solicite o pacote de serviço primeiro',
    去订购: 'Fazer o pedido',
  },
  group: {
    fetchFailed: 'Falha ao obter a lista do grupo de suporte. Tente novamente mais tarde',
    fetchPriorityFailed: 'Obter escopo de serviço anterior falhou, tente novamente mais tarde',
    deleteSucceed: 'Excluir grupo assistente com sucesso',
    deleteFailed: 'Falha na exclusão do grupo assistente, tente novamente mais tarde',
    saveSucceed: 'Salvar grupo assistente foi bem-sucedido',
    saveFailed: 'Falha ao salvar o grupo assistente, tente novamente mais tarde',
    empty: 'Nenhum grupo assistente está disponível agora',
    emptyAccount:
      'Nenhuma conta de assistente, o gerenciamento de grupo de assistente não é suportado. \n adicione uma conta',
    create: 'Adicionar novo(s) grupo(s) de assistentes',
    createAccount: 'Adicionar nova conta de assistente',
    priority: 'Escopo do serviço prioritário',
    edit: 'Editar',
    add: 'Adicionar',
    savePrioritySucceed: 'Área de recepção prioritária salva com sucesso',
    savePriorityFailed:
      'Falha ao salvar a área de recepção prioritária, tente novamente mais tarde',
    description: 'Guia',
    singleUndistributedStore: (name: string) => (
      <span>
        Loja：
        <Highlight>{name}</Highlight>
        Se você não estiver em nenhum grupo de atendimento ao cliente, a mensagem do comprador não
        pode ser atribuída ao atendimento ao cliente, por favor, lide com isso a tempo
      </span>
    ),
    undistributedStoreList: (count: number | string) => (
      <span>
        Atualmente tem
        <Highlight>{count}</Highlight>
        loja que não está em nenhum grupo de atendimento ao cliente, a mensagem do comprador não
        pode ser atribuída ao atendimento ao cliente, por favor, lide com isso a tempo
      </span>
    ),
    客服分配说明: 'Assistente distribuir descrição',
    共x个分组: `Total {{x}} grupos`,
    descriptionRenderer: () => (
      <Description>
        <h3>Como vamos alocar após as consultas do comprador?</h3>
        <div>
          <p>
            1、Comprador tem
            <span>Entre em contato com o atendimento ao cliente recentemente</span>
            ，e
            <span>online</span>
            ，é atribuído diretamente ao serviço de atendimento ao cliente que recebeu。
          </p>
          <p>
            2、Se o comprador não entrou em contato com o atendimento ao cliente nos últimos 3 dias,
            de acordo com o comprador
            <span>Comportamento histórico</span> e{' '}
            <span>Ordens de status recentemente atualizadas</span>
            ，atribua o comprador ao correspondente
            <span>área de recepção</span>
            equipe de atendimento ao cliente.
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                Se o comprador satisfizer vários
                <span>área de recepção</span>
                de grupos de atendimento ao cliente, cada
                <span>área de recepção</span>
                determina, por sua vez, se existe um grupo de atendimento ao cliente correspondente
                para atribuir, entre os quais
                <span>área de recepção prioritária</span>
                as opções em , serão julgadas primeiro.
              </p>
              <p>
                <span>
                  Se nenhum grupo de atendimento ao cliente atender ao intervalo de recepção que o
                  comprador atende, ele será atribuído a qualquer grupo de atendimento ao cliente da
                  loja a que pertence.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3、Estaremos em
            <span>atribuir atendimento ao cliente dentro do grupo de atendimento ao cliente</span>
            ，de acordo com a regra de ocupação do atendimento ao cliente no grupo, encontre um
            atendimento ao cliente e atribua-o.
          </p>
        </div>
      </Description>
    ),

    '当前组内无客服，买家将无法分配至该客服组':
      'Atualmente não há atendimento ao cliente, o comprador não poderá ser atribuído a este grupo de atendimento ao cliente',
    '若存在多位启用中客服时，买家 将会平均分配':
      'Se houver vários agentes ativos, os compradores serão distribuídos uniformemente',
    '若客服组中仅有1位启用中的客服 买家将会全部分配给该客服处理':
      'Se houver apenas 1 atendimento ao cliente ativo no grupo de atendimento ao cliente, todos os compradores serão atribuídos a este atendimento ao cliente para processamento',
    showTotal: (total: number) => `Total ${total} itens`,
    更多x家店铺: (total: number) => `masi ${total} lojas`,
    tradeStatus: {
      NO_TRADE: 'Sem pedido',
      NO_PAY: 'Não pago',
      WAIT_SHIP: 'Para ser enviado',
      SHIPPING: 'Enviando',
      RECENT_TRADED: 'Concluído em 7 dias',
      REFUND: 'Devolução/reembolso',
    },
    buyerTag: {
      NEW: 'Novo cliente',
      OLD: 'Cliente Regular',
    },
    receptionScopeType: {
      ALL_BUYER: 'Todos compradores',
      TRADE_STATUS: 'Por estado do Pedido',
      BUYER_LABEL: 'Pela etiqueta do comprador',
    },
    substituteGroupType: {
      UNALTERNATIVE: 'Sem assistentes alternativos',
      ALL_GROUP: 'Todos os grupos de assistentes',
      CERTAIN_GROUP: 'Escolher grupo(s) assistente(s)',
    },
    field: {
      tradeStatus: 'Estado de pedido',
      buyerTag: 'Etiqueta de comprador',
      name: 'Nome de assistente',
      userIds: 'Escolher assistente',
      storeIds: 'Loja aplicável',
      负责平台: 'Escolha a plataforma',
      receptionScope: 'Área de recepção',
      substituteGroup: 'Substituir quando ocupado',
    },
    placeholder: {
      name: 'Inserir nome',
      userIds: 'Escolher Assitente',
      storeIds: (platform: string) => `Por favor, escolha ${platform} lojas`,
      whatsapp: 'por favor selecione a conta WhatsApp',
      substituteGroup: 'Selecione grupo de assistente',
    },
    validate: {
      nameEmpty: 'Insira no grupo de assistente',
      nameLimit: (limit: number | string) =>
        `${limit}caracteres no máximo, o limite superior foi atingido`,
      userEmpty: 'Escolher Assitente',
      storeEmpty: 'Por favor, selecione a loja',
      tradeStatusEmpty: 'Selecione o estado de pedido',
      buyerTagEmpty: 'Selecione a etiqueta do comprador',
      groupEmpty: 'Por favor, selecione o grupo assistente',
    },
    save: 'Guardar',
    客服组配置hint:
      'Suporta apenas as plataformas Shopee e Lazada para configuração do grupo de atendimento ao cliente',
    priorityCancelConfirm: 'Are you sure to quit edit prior service scope?',
    undistributedAccount: 'Não em nenhum grupo de assistentes',
    distributedToAllBuyerStore: '该店铺已有接待范围为全部买家客服组',
    undistributedStore: 'Nenhum grupo assistente disponível',
    fetchAccountListFailed: 'Falha na obtenção da lista de assistentes, tente novamente mais tarde',
    fetchStoreListFailed: 'Falha na obtenção da lista de lojas, tente novamente mais tarde',
    basicInfo: 'Informação Básica',
    groupConfig: 'Configurações do grupo de assistentes',
    editGroup: 'Editar grupo de assistente ',
    cancelEditGroupConfirm: 'Tem certeza de parar de criar um novo grupo de assistentes?',
    deleteConfirm: 'Tem certeza de excluir o grupo assistente?',
    delete: 'Excluir grupo',
    accountCount: 'Assistentes do grupo',
    负责店铺: 'Armazenar',
    负责WABA帐号: 'conta WhatsApp',
    更多x帐号: (x: number) => `Mais ${x} conta WhatsApp`,
    enableCount: (count: number | string) => `Abrir ${count} assistente(s)`,
    disableCount: (count: number | string) => `Fechar ${count} assistente(s)`,
    sleepCount: (count: number | string) => `Dormência ${count} assistente(s)`,
    storePlatform: (platform: string) => `${platform} loja da plataforma`,
    当前客服组中仅有1位启用中的客服买家将会全部分配给该客服处理:
      'Existe apenas um atendimento ao cliente aberto no grupo de atendimento ao cliente, todos os compradores serão atribuídos a este atendimento ao cliente para processamento.',
    '当前存在多位启用中客服时，买家将会平均分配':
      'xistem muitos atendimento ao cliente aberto no grupo de atendimento ao cliente, os compradores serão distribuídos em média',
    全部分配: 'Toda a distribuição',
    平均分配: 'Distribuição média',
    '连续7天未登录系统的客服将进入休眠状态，不再进行买家分配，再次登录则恢复买家分配':
      'O atendimento ao cliente que não fizer login no sistema por 7 dias consecutivos entrará no estado inativo e não alocará mais o comprador. Se você fizer login novamente, a alocação do comprador será restaurada',
    '当前组无启用中的客服，已选适用店铺的买家无法分配至该客服组':
      'Não há atendimento ao cliente aberto no grupo atual. O comprador da loja aplicável selecionada não pode ser atribuído a este grupo de atendimento ao cliente',
    '当前客服组中仅有1位启用中的客服，已选适用店铺的买家将会全部分配给该客服处理':
      'Há apenas 1 atendimento ao cliente ativo no grupo de atendimento ao cliente atual, e todos os compradores que selecionaram a loja aplicável serão atribuídos a este atendimento ao cliente para processamento',
    '当前客服组中有多位启用中的客服，已选适用店铺的买家将会平均分配':
      'Existem muitos atendimento ao cliente aberto no grupo de atendimento ao cliente, os compradores serão distribuídos em média',
    展开剩余x家: (count: number) => `Veja outras ${count} lojas`,
  },
  modals: {
    未设置: 'Não configurado',
    帐号信息: 'Informação da conta',
    change_phone_tips: 'Você está modificando o número de telefone para fazer login em {{phone}}，',
    '修改成功后，需使用新手机号进行登录':
      'Após a modificação, você precisa usar o novo número de celular para fazer logi',
    返回: 'Retornar',
    修改登录手机号: 'Confirmar modificar número de telefone',
    请输入新手机号: 'Por favor, insira um novo número de telefone',
    确认修改: 'Confirmar alteração',
    修改手机号: 'Modificar número de telefone',
    设置手机号: 'Configurar número de telefone',
    修改帐号密码: 'Modificar senha',
    设置帐号密码: 'Configurar senha',
    用户信息: 'Informação do usuário',
    当前版本: 'Versão atual',
    系统设置: 'Configuração de sistema',
    ' 关于ChatPlusAI': 'Sobre ChatPlusAI',
    ' 欢迎使用ChatPlusAI': 'Bem-vindo ao ChatPlusAI',
    公司信息: 'Imformação da empresa',
    公司名称: 'Nome da empresa',
    请填写公司名称: 'Por favor insira o nome da empresa',
    开票信息: 'Informação da fatura',
    'register.ChatPlusAI': 'Registrar ChatPlusAI',
    'register.form.become.admin.tips':
      'Você será um superadministrador por padrão e terá todos os privilégios de superadministrador',
    '操作失败，请稍后再试': 'Falha na operação, tente novamente mais tarde',
    正在发送验证码: 'Enviando código de verficação',
    密码登录: 'Senha de login',
    验证码登录: 'Login com código de verificação',
    登录中: 'Carregando',
    '服务器开小差了，请稍后再试': 'Servidor ocupado, por favor tente novamente mais tarde',
    '登录失败，验证码错误': 'Falha no login, o código de verificação está errado',
    '密码太久未更新，请联系管理员更新密码':
      'A senha não é atualizada há muito tempo, entre em contato com o administrador para atualizar a senha',
    设置登录帐号: 'Configurar conta de login',
    '正在修改登录手机号，请先完成身份认证。':
      'O número de telefone de login está sendo modificado, complete a verificação de identidade primeiro.',
    '验证码已发送至：': 'O código de verificação foi enviado para：',
    '线上暂时不支持帐号找回，请联系ChatPlusAI客服':
      'Não é possível encontrar senha online, entre em contato com o assistente de ChatPlusAI.',
    手机号已停用: 'Número de celular desativado',
    下一步: 'Próximo',
    // 需要补充
    'account.rules.tips':
      'Configurando a conta de login, que pode consistir apenas em números, letras inglesas e símbolos especiais (exceto @), 1 a 20 caracteres. Suporta configuração apenas uma vez e não pode ser alterada após a configuração.',
    'password.rules.desc':
      'A senha pode consistir apenas em números, letras inglesas ou símbolos especiais, e deve conter letras maiúsculas/minúsculas, números e símbolos especiais, com um comprimento de 8 a 20 caracteres',
    已设置: 'Configurado',
    设置成功: 'Configurado com sucesso',
    不支持修改: 'Não é possível fazer alteração',
    修改: 'Alterar',
    身份验证: 'Verificação de Identidade',
  },
  guide: {
    新增客服组: 'Criar',
    组内客服: 'Assistente no grupo',
    适用店铺: 'Loja aplicável',
    跳过: 'Passar',
    选择客服组: 'Selecione grupo assistente',
    '您可以通过客服组来归类客服，提升接待买家效率':
      'Você pode classificar o atendimento ao cliente por meio de grupos de atendimento ao cliente para melhorar a eficiência do recebimento de compradores.',
    '任何客服都必须属于至少一个客服组，否则他们将无法接待买家。':
      'Qualquer agente deve pertencer a pelo menos um grupo de agentes ou não poderá receber compradores',
    '店铺必须属于至少一个客服组，否则店铺的买家咨询将无客服接待。':
      'A loja deve pertencer a pelo menos um grupo de atendimento ao cliente, caso contrário a consulta ao comprador da loja não terá recepção de atendimento ao cliente.',
  },
};
