export const cn = {
  '操作失败，请稍后再试': '操作失败，请稍后再试',
  '网络无法连接，请使用健康诊断检查下你的网络': '网络无法连接，请使用健康诊断检查下你的网络',
};
export const en = {
  '操作失败，请稍后再试': 'Failed, try again later',
  '网络无法连接，请使用健康诊断检查下你的网络':
    'The network cannot be connected, please check your network',
};
export const id = {
  '操作失败，请稍后再试': 'Operasi gagal, silakan coba beberapa saat lagi',
  '网络无法连接，请使用健康诊断检查下你的网络':
    'Jaringan tidak dapat terhubung, harap periksa jaringan Anda',
};
export const th = {
  '操作失败，请稍后再试': 'การดำเนินการล้มเหลว โปรดลองอีกครั้งในภายหลัง',
  '网络无法连接，请使用健康诊断检查下你的网络':
    'ไม่สามารถเชื่อมต่อเครือข่ายได้ โปรดตรวจสอบเครือข่ายของคุณ',
};
export const tl = {
  '操作失败，请稍后再试': 'Failed, try again later',
  '网络无法连接，请使用健康诊断检查下你的网络':
    'The network cannot be connected, please check your network',
};
export const es = {
  '操作失败，请稍后再试': 'Operación falló, por favor inténtelo más tarde.',
  '网络无法连接，请使用健康诊断检查下你的网络': 'La red no se puede conectar, verifique su red',
};

export const vn = {
  '操作失败，请稍后再试': 'Thao tác thất bại, vui lòng thử lại',
  '网络无法连接，请使用健康诊断检查下你的网络':
    'Không thể kết nối mạng, vui lòng kiểm tra mạng của bạn',
};

export const pt = {
  '操作失败，请稍后再试': 'Operação falhou, por favor tente mais tarde',
  '网络无法连接，请使用健康诊断检查下你的网络': 'A rede não pode ser conectada, verifique sua rede',
};
