import { merge } from 'lodash-es';

type I18nLangs = 'zh-CN' | 'en-US' | 'id' | 'pt' | 'th' | 'vi' | 'tl' | 'es';
type I18nLngRecord = Record<I18nLangs, string | Record<string, string>>;
export type I18nKeyFirstRecord = Record<string, I18nLngRecord>;
type Ii18nLngFirstRecord<R extends I18nKeyFirstRecord> = Record<
  I18nLangs,
  { [K in keyof R]: R[K][I18nLangs] }
>;

const i18nlangs: I18nLangs[] = ['zh-CN', 'en-US', 'id', 'pt', 'th', 'vi', 'tl', 'es'];
// eslint-disable-next-line import/prefer-default-export
export const i18nKeyFirstToLngFirst = <R extends I18nKeyFirstRecord>(
  raw: R,
): Ii18nLngFirstRecord<R> => {
  let records = {} as Ii18nLngFirstRecord<R>;
  records = Object.keys(raw).reduce((records, i18nKey) => {
    const lngRecords = i18nlangs.reduce<Ii18nLngFirstRecord<R>>((map, lng) => {
      const rawLngs = raw[i18nKey];
      // eslint-disable-next-line no-param-reassign
      map[lng] = merge(map[lng], { [i18nKey]: rawLngs[lng] });
      return map;
    }, {} as Ii18nLngFirstRecord<R>);
    merge(records, lngRecords);
    return records;
  }, records);
  return records;
};

export const i18nLngFirstToKeyFirst = <R extends Record<string, any>, K extends keyof R>(
  raw: Ii18nLngFirstRecord<R>,
): Record<K, Record<I18nLangs, R[K]>> => {
  return Object.keys(raw).reduce((res, lng) => {
    const resources = raw[lng];
    Object.keys(resources).forEach((i18nKey) => {
      const tran = resources[i18nKey];
      if (!res[i18nKey]) {
        res[i18nKey] = {};
      }
      res[i18nKey][lng] = tran;
    });
    return res;
  }, {} as any);
};
