export function cnCommonApiGetErr(tip: string) {
  return `获取 ${tip} 失败，请稍后再试`;
}
export function enCommonApiGetErr(tip: string) {
  return `Get ${tip} fail, please try again later.`;
}
export function idCommonApiGetErr(tip: string) {
  return `Gagal mengambil ${tip}, silakan mencoba beberapa saat lagi`;
}
export function thCommonApiGetErr(tip: string) {
  return `รับ ${tip}ล้มเหลว โปรดลองอีกครั้งในภายหลัง`;
}
export function tlCommonApiGetErr(tip: string) {
  return `Get ${tip} fail, maaaring subukan ulit mamaya.`;
}
export function esCommonApiGetErr(tip: string) {
  return `obtener ${tip} falló, por favor inténtelo más tarde`;
}

export function cnCommonApiPostErr(tip: string) {
  return `${tip} 操作失败，请稍后再试`;
}
export function enCommonApiPostErr(tip: string) {
  return `${tip} operation fail, please try again later.`;
}
export function idCommonApiPostErr(tip: string) {
  return `${tip} Operasi gagal, silakan mencoba beberapa saat lagi`;
}
export function thCommonApiPostErr(tip: string) {
  return `การดำเนินการล้มเหลว โปรดลองอีกครั้งในภายหลัง`;
}
export function tlCommonApiPostErr(tip: string) {
  return `${tip} operation fail, maaaring subukan ulit mamaya.`;
}
export function esCommonApiPostErr(tip: string) {
  return `${tip} Operación falló, por favor inténtelo más tarde`;
}

export function vnCommonApiPostErr(tip: string) {
  return `${tip} Thao tác thất bại, vui lòng thử lại sau`;
}

export function ptCommonApiGetErr(tip: string) {
  return `Falha ao obter ${tip}, tente novamente mais tarde`;}