export const cn = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};

export const en = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};
export const id = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};
export const th = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};
export const tl = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};
export const es = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};

export const vn = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};

export const pt = {
  CNY: '人民币',
  USD: '美元',
  THB: '泰铢',
  MXN: '墨西哥元',
  IDR: '印尼卢比',
};
