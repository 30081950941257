export const cn = {
  天: '天',
  小时: '小时',
  分钟: '分钟',
  秒: '秒',
};
export const en = {
  天: 'Day',
  小时: 'Hours',
  分钟: 'Minutes',
  秒: 'Seconds',
};
export const id = {
  天: 'hari',
  小时: 'jam',
  分钟: 'menit',
  秒: 'detik',
};
export const th = {
  天: 'วัน',
  小时: 'ชั่วโมง',
  分钟: 'นาที',
  秒: 'วินาที',
};
export const tl = {
  天: 'Day',
  小时: 'Hours',
  分钟: 'Minutes',
  秒: 'Seconds',
};
export const es = {
  天: 'Day',
  小时: 'Hora',
  分钟: 'Minutos',
  秒: 'Segundos',
};

export const vn = {
  天: 'Ngày',
  小时: 'Giờ',
  分钟: 'Phút',
  秒: 'Giây',
};

export const pt = {
  天: 'Dia',
  小时: 'Horas',
  分钟: 'Minutos',
  秒: 'Segundos',
};
