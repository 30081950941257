export const cn = {
  'switch.on.text': '开',
  'switch.off.text': '关',
  'modal.cancel': '取消',
};

export const en = {
  'switch.on.text': 'On',
  'switch.off.text': 'Off',
  'modal.cancel': 'Cancel',
};

export const id = {
  'switch.on.text': 'ON',
  'switch.off.text': 'OFF',
  'modal.cancel': '取消',
};

export const th = {
  'switch.on.text': 'เปิด',
  'switch.off.text': 'ปิด',
  'modal.cancel': '取消',
};

export const tl = en;

export const es = {
  'switch.on.text': 'On',
  'switch.off.text': 'Off',
  'modal.cancel': 'Cancelar',
};
export const vn = {
  'switch.on.text': 'Mở',
  'switch.off.text': 'Đóng',
  'modal.cancel': 'Hủy',
};

export const pt = {
  'switch.on.text': 'On',
  'switch.off.text': 'Off',
  'modal.cancel': 'Cancelar',
};
