import { i18nKeyFirstToLngFirst } from 'services/i18n/helpers';
import * as time from './time';
import * as errors from './errors';
import * as success from './success';
import buttonsRaw from './buttons';
import * as select from './select';
import * as common from './common';
import * as currency from './currency';
import appsRaw from './other.apps';
import anyRaw from './any';
import payRaw from './pay';

const apps = i18nKeyFirstToLngFirst(appsRaw);
const pay = i18nKeyFirstToLngFirst(payRaw);
const any = i18nKeyFirstToLngFirst(anyRaw);
const buttons = i18nKeyFirstToLngFirst(buttonsRaw);
export const cn = {
  ...time.cn,
  ...buttons['zh-CN'],
  ...errors.cn,
  ...success.cn,
  ...select.cn,
  ...common.cn,
  currency: currency.cn,
  ...apps['zh-CN'],
  ...pay['zh-CN'],
  ...any['zh-CN'],
};
export const en = {
  ...time.en,
  ...buttons['en-US'],
  ...errors.en,
  ...success.en,
  ...select.en,
  ...common.en,
  currency: currency.en,
  ...apps['en-US'],
  ...pay['en-US'],
  ...any['en-US'],
};
export const id = {
  ...time.id,
  ...buttons.id,
  ...errors.id,
  ...success.id,
  ...select.id,
  ...common.id,
  currency: currency.id,
  ...apps.id,
  ...pay.id,
  ...any.id,
};
export const th = {
  ...time.th,
  ...buttons.th,
  ...errors.th,
  ...success.th,
  ...select.th,
  ...common.th,
  currency: currency.th,
  ...apps.th,
  ...pay.th,
  ...any.th,
};
export const tl = {
  ...time.tl,
  ...buttons.tl,
  ...errors.tl,
  ...success.tl,
  ...select.tl,
  ...common.tl,
  currency: currency.tl,
  ...apps.tl,
  ...pay.tl,
  ...any.tl,
};
export const es = {
  ...time.es,
  ...buttons.es,
  ...errors.es,
  ...success.es,
  ...select.es,
  ...common.es,
  currency: currency.es,
  ...apps.es,
  ...pay.es,
  ...any.es,
};
export const vn = {
  ...time.vn,
  ...buttons.vi,
  ...errors.vn,
  ...success.vn,
  ...select.vn,
  ...common.vn,
  currency: currency.vn,
  ...apps.vi,
  ...pay.vi,
  ...any.vi,
};
export const pt = {
  ...time.pt,
  ...buttons.pt,
  ...errors.pt,
  ...success.pt,
  ...select.pt,
  ...common.pt,
  currency: currency.pt,
  ...apps.pt,
  ...pay.pt,
  ...any.pt,
};
